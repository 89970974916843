import React from "react";
import { Flex, Result, theme, Typography } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { Course, ExternalCourse, Position, TreeNode } from "@/models.ts";
import { AxiosRequestConfig } from "axios";
import PositionLearningRuleModalForm from "@/entities/position-learning-rule/ui/modal-form.tsx";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { useTranslation } from "react-i18next";
import { getTreeNodeNames, createPositionFilter } from "@/entities/member";
import { CourseSelectWithExternal } from "@/features/course";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";
import { ActionType, ProFormSwitch } from "@ant-design/pro-components";
import usePositionLearningRuleModalForm from "@/entities/position-learning-rule/lib/use-form-modal";
import { ExternalCourseIcon } from "@/entities/course";
import ImportButton from "@/entities/import/ui/button";

type Record = Position & {
  tree_node: TreeNode;
  enabled_position_learning_rules_count: number;
  position_learning_rules_count: number;
  learning_rule_courses: Course[];
  learning_rule_external_courses: ExternalCourse[];
};

const Page: React.FC = () => {
  const { t } = useTranslation();
  const member = useMe();
  const { token } = theme.useToken();

  const [total, setTotal] = React.useState<number>();
  const { parentHeight, ref } = useParentHeight("table");
  const [openPositionLearningRuleModal, positionLearningRuleModalHolder] =
    usePositionLearningRuleModalForm();

  const actionRef = React.useRef<ActionType>();

  const [error, setError] = React.useState<Error | null>(null);

  if (error) {
    return <Result status="error" title="Ошибка" subTitle={error.message} />;
  }

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      {positionLearningRuleModalHolder}
      <div ref={ref} style={{ height: "calc(100% - 30px" }}>
        <ProTable<Record>
          rowKey="id"
          request={async (params, sort, filter) => {
            const filterCourse = filter["courses"];
            const whereAllLearningRulesAutoAssigned =
              filter["whereAllLearningRulesAutoAssigned"];

            const positionIds = filter["position_id"];

            delete filter["position_id"];
            delete filter["courses"];
            delete filter["whereAllLearningRulesAutoAssigned"];

            const axiosConfig: AxiosRequestConfig = {
              method: "POST",
              url: "/api/positions/search",
              ...axiosConfigAdapter(params, sort, filter),
            };

            if (positionIds) {
              axiosConfig.data.filters.push(
                createPositionFilter(positionIds, "id"),
              );
            }

            axiosConfig.data.includes.push(
              { relation: "tree_node.reverse_tree.is_an" },
              { relation: "learning_rule_courses" },
              { relation: "learning_rule_external_courses" },
            );

            axiosConfig.data.scopes.push({
              name: "whereHasLearningRules",
            });

            axiosConfig.data.aggregates.push(
              {
                relation: "position_learning_rules",
                type: "count",
              },
              {
                relation: "enabled_position_learning_rules",
                type: "count",
              },
            );

            if (whereAllLearningRulesAutoAssigned) {
              axiosConfig.data.scopes.push({
                name: "whereNotAllLearningRulesEnabled",
              });
            }

            if (filterCourse) {
              const courseIds: number[] = [];
              const externalCourseIds: number[] = [];

              for (const item of filterCourse) {
                const [type, id] = item.toString().split("#");
                const parsedId = Number(id);
                if (type === "course") {
                  courseIds.push(parsedId);
                } else if (type === "external_course") {
                  externalCourseIds.push(parsedId);
                }
              }

              axiosConfig.data.scopes.push({
                name: "whereInCourse",
                parameters: [courseIds, externalCourseIds],
              });
            }

            return await axios
              .request<OrionRestIndexResponse<Position>>(axiosConfig)
              .then(({ data }) => {
                setTotal(data.meta.total);

                return {
                  data: data.data as Record[],
                  success: true,
                  total: data.meta.total,
                };
              })
              .catch((error) => {
                setError(error);
                throw error;
              });
          }}
          toolBarRender={(action) => {
            if (!action) throw new Error("action is undefined");

            return [
              member.permissions.includes("import:position_learning_rules") && (
                <ImportButton
                  key="import"
                  type="position_learning_rules"
                  onAfterFinish={() => action.reload()}
                />
              ),
              member.permissions.includes("position_learning_rule:create") && (
                <PositionLearningRuleModalForm
                  key={"create-batch"}
                  rest={{
                    type: "create",
                    onAfterFinish: () => {
                      action.reload();
                    },
                  }}
                />
              ),
            ];
          }}
          hasQueryParams
          options={false}
          pagination={{
            defaultPageSize: 100,
          }}
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
          }}
          scroll={{
            x: 900,
            y: parentHeight - (total ? 227 : 132),
          }}
          actionRef={actionRef}
          bordered
          onRow={(record) => ({
            onClick: () => {
              if (
                member.permissions.includes("position_learning_rule:update")
              ) {
                openPositionLearningRuleModal.open({
                  rest: {
                    type: "update",
                    recordKey: record.id,
                    onAfterFinish: () => {
                      actionRef.current?.reload();
                    },
                  },
                });
              }
            },
          })}
          columns={[
            {
              title: () => t("Подразделение"),
              tooltip: true,
              fixed: "left",
              width: 300,
              align: "center",
              renderText: (_, { tree_node }) => {
                const treeNodeName = getTreeNodeNames(tree_node);

                if (!treeNodeName) {
                  return null;
                }

                return (
                  <Typography.Text ellipsis={{ tooltip: true }}>
                    {<OrgStructureIcon type={"department"} />} {treeNodeName}
                  </Typography.Text>
                );
              },
              filters: false,
              hideInSearch: true,
            },
            {
              dataIndex: "position_id",
              title: () => t("Должность"),
              tooltip: true,
              fixed: "left",
              align: "center",
              width: 300,
              renderText: (_, { name }) => {
                return (
                  <Typography.Text>
                    {<OrgStructureIcon type={"position"} />} {name}
                  </Typography.Text>
                );
              },
              filters: false,
              formItemProps: {
                tooltip: false,
                label: t("Подразделение / Должность"),
              },
              renderFormItem(_, config) {
                return (
                  <OrgStructureTreeSelect
                    labelWithPath
                    {...config}
                    fieldProps={{ multiple: true }}
                  />
                );
              },
            },
            {
              dataIndex: "courses",
              title: "Курсы",
              renderText: (
                _,
                { learning_rule_courses, learning_rule_external_courses },
              ) => {
                const coursesNames = learning_rule_courses
                  .map(({ name }) => name)
                  .join(", ");

                let externalCoursesNames = learning_rule_external_courses.map(
                  ({ name }, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ", "}
                      <span>
                        <ExternalCourseIcon /> {name}
                      </span>
                    </React.Fragment>
                  ),
                );

                let names: any = [coursesNames];

                if (externalCoursesNames.length) {
                  if (coursesNames.length) {
                    names.push(", ");
                  }
                  names.push(externalCoursesNames);
                }

                return (
                  <Typography.Text ellipsis={{ tooltip: true }}>
                    {names}
                  </Typography.Text>
                );
              },
              formItemProps: {
                label: "Курс",
              },
              renderFormItem(_, config) {
                return (
                  <CourseSelectWithExternal
                    whereHasPositionLearningRules
                    hideAddInput
                    {...config}
                    mode="multiple"
                    name="courses"
                  />
                );
              },
            },
            {
              dataIndex: "whereAllLearningRulesAutoAssigned",
              title: "Включены",
              renderText: (
                _,
                {
                  enabled_position_learning_rules_count,
                  position_learning_rules_count,
                },
              ) =>
                `${enabled_position_learning_rules_count}/${position_learning_rules_count}`,
              width: 100,
              align: "center",
              formItemProps: {
                label: false,
              },
              renderFormItem: (_, config) => {
                return (
                  <ProFormSwitch
                    {...config}
                    label="Есть не запущенные автоназначения"
                  />
                );
              },
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default Page;
