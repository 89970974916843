import axios from "@/axios";
import { Test } from "@/models";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const useTestsResource = () => {
  const deleteTest = useCallback(
    (id: Test["id"]) => axios.delete(`/api/tests/${id}`),
    [],
  );

  const getTest = useCallback(
    (id: Test["id"], config?: AxiosRequestConfig) =>
      axios
        .get<OrionRestShowResponse<Test>>(`/api/tests/${id}`, config)
        .then((res) => res.data.data),
    [],
  );

  const updateTest = useCallback(
    (id: Test["id"], values: Partial<Test>) =>
      axios
        .put<OrionRestUpdateResponse<Test>>(`/api/tests/${id}`, values)
        .then(async (res) => res.data.data),
    [],
  );

  return {
    deleteTest,
    getTest,
    updateTest,
  };
};
