import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Input,
  Space,
  Typography,
  message,
  Skeleton,
} from "antd";
import useSWR from "swr";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import { Course, ExternalCourse } from "@/models";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useCoursesResource, ExternalCourseIcon } from "@/entities/course";

type CourseSelectWithExternalProps = ProFormSelectProps & {
  isActiveFilter?: boolean;
  hideAddInput?: boolean;
  whereHasPositionLearningRules?: boolean;
  listName?: ProFormSelectProps["name"];
};

export const CourseSelectWithExternal: React.FC<
  CourseSelectWithExternalProps
> = ({
  isActiveFilter = true,
  whereHasPositionLearningRules = false,
  hideAddInput = false,
  listName,
  fieldProps,
  ...props
}) => {
  const form = ProForm.useFormInstance();
  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState<string | string[]>("");
  const [name, setName] = useState("");
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const { getCourse, getCourses } = useCoursesResource();

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? props.name));
  }, []);

  const {
    data: courses,
    mutate,
    isLoading,
  } = useSWR(
    [
      "/api/courses/search",
      whereHasPositionLearningRules,
      searchValue,
      initialValue,
    ],
    async ([url]) => {
      const filters: any = [];
      const scopes: any = [];

      if (whereHasPositionLearningRules) {
        scopes.push({
          name: "whereHasPositionLearningRules",
        });
      }

      if (isActiveFilter) {
        filters.push({
          field: "status",
          operator: "=",
          value: "active",
        });
      }

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      const courses = await axios
        .post<OrionRestShowResponse<Course[]>>(
          url,
          {
            filters,
            scopes,
          },
          {
            params: {
              limit: 50,
            },
          },
        )
        .then(async (res) => {
          const data: any = [];

          res.data.data.forEach((item) => {
            data.push({
              label: item.name,
              value: `course#${item.id}`,
              type: "course",
            });
          });

          if (
            initialValue &&
            typeof initialValue === "string" &&
            initialValue.startsWith("course#") &&
            !data.some((option: any) => option.value === initialValue)
          ) {
            const currentData = await getCourse(
              Number(initialValue.split("#")[1]),
            ).then((course) => ({
              label: course.name,
              value: `course#${course.id}`,
              type: "course",
            }));
            data.unshift(currentData);
          }
          if (initialValue && Array.isArray(initialValue)) {
            const missingValues: string[] = initialValue.filter((value) => {
              return (
                value.startsWith("course#") &&
                !data.some((option: any) => option.value === value)
              );
            });

            if (missingValues.length > 0) {
              const missingData = await getCourses({
                filters: [
                  {
                    type: "and",
                    nested: missingValues.map((value) => ({
                      type: "or",
                      field: "id",
                      operator: "=",
                      value: Number(value.split("#")[1]),
                    })),
                  },
                ],
              }).then((res) =>
                res.data.data.map((course) => ({
                  label: course.name,
                  value: `course#${course.id}`,
                  type: "course",
                })),
              );

              data.push(...missingData);
            }
          }

          return data;
        });

      const externalCourses = await axios
        .post<OrionRestShowResponse<ExternalCourse[]>>(
          "/api/external-courses/search",
          {
            filters: [
              {
                field: "name",
                operator: "ilike",
                value: `%${searchValue}%`,
              },
            ],
            scopes,
          },
        )
        .then(async (res) => {
          const data: any = [];

          res.data.data.forEach((item) => {
            data.push({
              label: item.name,
              value: `external_course#${item.id}`,
              type: "external_course",
            });
          });

          if (
            initialValue &&
            typeof initialValue === "string" &&
            initialValue.startsWith("external_course#") &&
            !data.some((option: any) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/external-courses/${initialValue.split("#")[1]}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: `external_course#${data.data.id}`,
                type: "external_course",
              }));
            data.unshift(currentData);
          }
          if (initialValue && Array.isArray(initialValue)) {
            const missingValues: string[] = initialValue.filter((value) => {
              return (
                value.startsWith("external_course#") &&
                !data.some((option: any) => option.value === value)
              );
            });

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<Course>>(
                  "/api/external-courses/search",
                  {
                    filters: [
                      {
                        type: "and",
                        nested: missingValues.map((value) => ({
                          type: "or",
                          field: "id",
                          operator: "=",
                          value: Number(value.split("#")[1]),
                        })),
                      },
                    ],
                  },
                )
                .then((res) =>
                  res.data.data.map((externalCourse) => ({
                    label: externalCourse.name,
                    value: `external_course#${externalCourse.id}`,
                    type: "external_course",
                  })),
                );

              data.push(...missingData);
            }
          }

          return data;
        });

      return [...courses, ...externalCourses];
    },
  );

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  const addExternalCourse = async () => {
    await axios
      .post<OrionRestCreateResponse<Course>>(`/api/external-courses`, {
        name,
      })
      .then(async (res) => {
        await mutate();
        return res.data.data;
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "Ошибка добавления курса");
      });
    setName("");
  };

  return (
    <ProFormSelect
      showSearch
      className="course-select"
      options={courses}
      fieldProps={{
        dropdownRender: (menu) => (
          <>
            {menu}
            {!hideAddInput && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Введите значение"
                    value={name}
                    onChange={onNameChange}
                    maxLength={255}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => addExternalCourse()}
                    disabled={!name}
                  >
                    Создать курс
                  </Button>
                </Space>
              </>
            )}
          </>
        ),
        onSearch: onSearch,
        loading: isLoading,
        optionItemRender: (item: any) => (
          <Flex gap={4}>
            {item.type === "external_course" && <ExternalCourseIcon />}
            <Typography.Text ellipsis> {item.label}</Typography.Text>
          </Flex>
        ),
        ...fieldProps,
      }}
      {...props}
    >
      {isFirstRender && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export type { CourseSelectWithExternalProps };
