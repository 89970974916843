import { Tooltip } from "antd";
import { ContainerOutlined } from "@ant-design/icons";

export const ExternalCourseIcon: React.FC = () => {
  return (
    <Tooltip title="Внешний">
      <ContainerOutlined />
    </Tooltip>
  );
};
