import React from "react";
import { ProForm } from "@ant-design/pro-components";
import { theme } from "antd";
import BaseReactQuill from "react-quill";
import styled from "styled-components";
import { GlobalToken } from "antd/es/theme/interface";
import "react-quill/dist/quill.snow.css";
import { FormData } from "../lib/model";

type Props = {};

type ReactQuillProps = React.ComponentProps<typeof BaseReactQuill>;

const ReactQuill = styled(BaseReactQuill)<
  ReactQuillProps & { token: GlobalToken }
>`
  height: 100%;
  word-break: break-word;

  background: ${({ token }) => token.colorWhite};
  border-radius: ${({ token }) => `${token.borderRadius}px`};
  color: rgba(0, 0, 0, 0.88) !important;

  .ql-toolbar {
    // left top + right top
    border-radius: ${({ token }) =>
      `${token.borderRadius}px ${token.borderRadius}px 0 0`};
  }

  .ql-container {
    // left buttom + right buttom
    border-radius: ${({ token }) =>
      `0 0 ${token.borderRadius}px ${token.borderRadius}px`};
    height: calc(100% - 42px);
  }
  @media screen and (max-width: 1224px) {
    .ql-container {
      height: calc(100% - 66px);
    }
  }
`;

export const LongreadContentField: React.FC<Props> = ({}) => {
  const { token } = theme.useToken();
  const form = ProForm.useFormInstance<FormData>();
  const content = ProForm.useWatch("content", form);

  return (
    <div
      data-text-editor="quill_wrapper"
      style={{ height: "calc(100% - 96px - 22px)" }}
    >
      <ReactQuill
        token={token}
        theme={"snow"}
        bounds={`[data-text-editor="quill_wrapper"]`}
        value={content ? content : undefined}
        onChange={(value) => form.setFieldsValue({ content: value })}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image", "video", "formula"],
            ["code-block"],
            ["clean"],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
      />
    </div>
  );
};
