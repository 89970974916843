import axios from "@/axios";
import { Member } from "@/models";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const useMembersResource = () => {
  const createMember = useCallback(
    (values: Member, config?: AxiosRequestConfig) => {
      return axios.post<OrionRestCreateResponse<Member>>(
        "/api/members",
        values,
        config,
      );
    },
    [],
  );

  const updateMember = useCallback(
    (
      id: Member["id"],
      values: Partial<Member>,
      config?: AxiosRequestConfig,
    ) => {
      return axios
        .put<OrionRestUpdateResponse<Member>>(
          `/api/members/${id}`,
          values,
          config,
        )
        .then((res) => res.data.data);
    },
    [],
  );

  const getMember = useCallback(
    (id: Member["id"], config?: AxiosRequestConfig) => {
      return axios
        .get<OrionRestShowResponse<Member>>(`/api/members/${id}`, config)
        .then((res) => res.data.data);
    },
    [],
  );

  const getMembers = useCallback(
    (
      data?: {
        filters?: any[];
        includes?: { relation: string }[];
        scopes?: { name: string; parameters?: any[] }[];
        sort?: { field: string; direction: "asc" | "desc" }[];
      },
      config?: AxiosRequestConfig,
    ) => {
      return axios
        .post<OrionRestIndexResponse<Member>>(
          `/api/members/search`,
          data,
          config,
        )
        .then((res) => res.data.data);
    },
    [],
  );

  const batchUpdateToDeleteMembers = useCallback(
    (MemberIds: Member["id"][]) => {
      const resources = MemberIds.reduce(
        (acc, id) => ({ ...acc, [id]: { status: "to_delete" } }),
        {} as { [key: string]: Partial<Member> },
      );
      return axios.patch<OrionRestShowResponse<Member>>(`/api/members/batch`, {
        resources,
      });
    },
    [],
  );

  const batchUpdateMembers = useCallback(
    (resources: { [key: string]: Partial<Member> }) => {
      return axios.patch<OrionRestShowResponse<Member>>(`/api/members/batch`, {
        resources,
      });
    },
    [],
  );

  const resetPasswordMembers = useCallback((ids: Member["id"][]) => {
    return axios.post("/api/members/reset-password", {
      resources: ids,
    });
  }, []);

  const generateLoginMember = useCallback((values: Member) => {
    return axios
      .post<{ data: string }>("/api/members/generate-username", values)
      .then(({ data }) => data.data);
  }, []);

  return {
    createMember,
    updateMember,
    getMember,
    getMembers,
    generateLoginMember,
    resetPasswordMembers,
    batchUpdateToDeleteMembers,
    batchUpdateMembers,
  };
};
