import React, { useEffect } from "react";
import { File, Pdf as BasePdf } from "@/models";
import { FilePreview } from "@/entities/file";
import { Flex, Grid, Space, theme, Typography, UploadFile } from "antd";
import { ProFormUploadButton } from "@ant-design/pro-components";
import { downloadFile } from "@/entities/file";

const { useBreakpoint } = Grid;

interface Pdf extends BasePdf {
  attachment_files: (File & UploadFile)[];
}

type PdfDescriptionsProps = {
  dataSource: Pdf;
};

const PdfDescriptions: React.FC<PdfDescriptionsProps> = ({ dataSource }) => {
  const screens = useBreakpoint();
  const { token } = theme.useToken();

  useEffect(() => {
    dataSource?.attachment_files?.forEach((file) => (file.status = "done"));
  }, []);

  return (
    <Flex
      vertical
      gap={8}
      style={{
        height: `calc(100% ${!screens.lg ? "- 32px - 8px" : ""})`,
        width: "100%",
        overflow: "auto",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {dataSource.name}
      </Typography.Title>
      <Typography.Text>{dataSource.description}</Typography.Text>
      {dataSource.content_file ? (
        <div
          style={{
            flex: "auto",
            overflow: "auto",
            paddingRight: token.padding,
            minHeight: `${
              dataSource?.attachment_files?.length ? "720px" : "auto"
            }`,
          }}
        >
          <FilePreview
            key={dataSource.content_file.id}
            file={dataSource.content_file as any}
            displayType={dataSource.display_type}
          />
        </div>
      ) : null}
      {dataSource.attachment_files &&
        dataSource.attachment_files.length > 0 && (
          <Space direction={"vertical"}>
            <Typography.Text style={{ margin: 0 }}>
              Прикреплённые файлы
            </Typography.Text>
            <ProFormUploadButton
              value={dataSource?.attachment_files}
              max={0}
              buttonProps={{ disabled: true, hidden: true }}
              fieldProps={{
                listType: "picture-card",
                onDownload: downloadFile,
                showUploadList: {
                  showDownloadIcon: true,
                  showRemoveIcon: false,
                  showPreviewIcon: false,
                },
              }}
            />
          </Space>
        )}
    </Flex>
  );
};

export default PdfDescriptions;
