import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Space } from "@/models";
import useSWR from "swr";
import { debounce } from "lodash";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { BaseOptionType } from "antd/es/cascader";
import { Skeleton } from "antd";

type SpaceSearchSelectProps = ProFormSelectProps & {
  listName?: string;
};

const SpaceSearchSelect: React.FC<SpaceSearchSelectProps> = ({
  fieldProps,
  listName,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();
  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const {
    data: spaces,
    isLoading,
    error,
  } = useSWR(
    ["/api/spaces/search", searchValue, initialValue],
    async ([url]) => {
      const filters: any = [];

      if (searchValue) {
        filters.push({
          field: "slug",
          operator: "ilike",
          value: `%${searchValue}%`,
        });
      }

      const res = await axios
        .post<{ data: Space[] }>(url, {
          filters,
        })
        .then(async (res) => {
          const data: BaseOptionType[] = [];

          res.data.data.forEach((space) => {
            data.push({
              label: space.slug,
              value: space.id,
            });
          });
          if (initialValue && typeof initialValue === "object") {
            const missingValues: number[] = (
              Array.isArray(initialValue)
                ? initialValue
                : // @ts-ignore
                  selectProps.value ?? []
            ).filter(
              (value: any) => !data.some((option) => option.value === value),
            );

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<Space>>("/api/spaces/search", {
                  filters: [
                    {
                      type: "and",
                      nested: missingValues.map((value) => ({
                        type: "or",
                        field: "id",
                        operator: "=",
                        value,
                      })),
                    },
                  ],
                })
                .then((res) =>
                  res.data.data.map((space) => ({
                    label: space.slug,
                    value: space.id,
                  })),
                );

              data.push(...missingData);
            }
          }

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((option: any) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/spaces/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.slug,
                value: data.data.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  return (
    <ProFormSelect
      options={spaces}
      showSearch
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      {...selectProps}
    >
      {isFirstRender && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export default SpaceSearchSelect;
