import React from "react";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { MemberSelect } from "@/features/member";
import PoolSelect from "@/entities/pool/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";

const CompletedEducationMembersFormFields: React.FC = ({}) => {
  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <DateTimeRangePickerWithTimeControls
        label="Фактическая дата завершения"
        name={["range", "ended_at"]}
        fieldProps={{ allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default CompletedEducationMembersFormFields;
