import React from "react";
import useMe from "@/entities/me/lib/use";
import useSWR from "swr";
import axios from "@/axios";
import { Outlet, useParams } from "react-router-dom";
import NotFound from "@/shared/pages/not-found";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { PoolParticipant } from "@/models";
import ContextPoolParticipant from "@/pages/space/education/[pool_participant_id]/context-pool-participant";
import NotFoundPage from "@/shared/pages/not-found";
import { SpinWrapper } from "@/shared/ant-design/spin/ui/spin-wrapper";

const Context: React.FC = () => {
  const me = useMe();
  const { pool_participant_id } = useParams();
  const {
    data: poolParticipant,
    isLoading,
    error,
  } = useSWR(
    `/api/pool-participants/${pool_participant_id!}?include=pool.course`,
    (url) =>
      axios
        .get<OrionRestShowResponse<PoolParticipant>>(url)
        .then((res) => res.data.data),
    {
      refreshInterval: 10000,
    },
  );

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!poolParticipant) throw new Error("Data is undefined");
  if (poolParticipant.pool?.status !== "started") return <NotFoundPage />;

  if (me.id !== poolParticipant.member_id) {
    return <NotFound />;
  }

  return (
    <SpinWrapper>
      <Spin
        style={{ height: "100%" }}
        spinning={
          poolParticipant.dependencies_status !== "actual" ||
          poolParticipant.pool!.course!.content_status !== "actual"
        }
        tip={"Ваш Курс был изменен, актуализируем данные..."}
      >
        <ContextPoolParticipant.Provider value={poolParticipant}>
          <Outlet />
        </ContextPoolParticipant.Provider>
      </Spin>
    </SpinWrapper>
  );
};

export default Context;
