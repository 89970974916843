export const mutateQuestionAfterResponse = (questions: any) => {
  return questions.map((question: any) => {
    if (
      question.type === "short_answer" ||
      question.type === "sequence" ||
      question.type === "matching"
    ) {
      question.disabled = true;
    }
    if (question.type === "matching") {
      question.matching = question.answers.map((answer: any) => ({
        matchable_uuid: answer.matchable_uuid,
        matched_uuid: answer.matched_uuid,
        matchable_value: question.matchable.find(
          (matchable: any) => matchable.uuid === answer.matchable_uuid,
        )?.value,
        matched_value: question.matched.find(
          (matched: any) => matched.uuid === answer.matched_uuid,
        )?.value,
      }));
    }
    return question;
  });
};
