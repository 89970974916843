import axios from "@/axios";
import { Pdf, PdfFile } from "@/models";
import {
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const usePdfsResource = () => {
  const deleteBatchPdfAttachmentFiles = useCallback((ids: Pdf["id"][]) => {
    return axios.delete("/api/pdf-attachment-files/batch", {
      data: { resources: ids },
    });
  }, []);

  const deletePdf = useCallback((id: Pdf["id"]) => {
    return axios.delete(`/api/pdfs/${id}`);
  }, []);

  const getPdfAttachmentFiles = useCallback((pdfId: Pdf["id"]) => {
    return axios
      .post<OrionRestIndexResponse<PdfFile>>(
        "/api/pdf-attachment-files/search",
        {
          filters: [
            {
              field: "pdf_id",
              operator: "=",
              value: pdfId,
            },
          ],
          includes: [{ relation: "file" }],
        },
      )
      .then((res) => res.data.data);
  }, []);

  const getPdf = useCallback((id: Pdf["id"], config?: AxiosRequestConfig) => {
    return axios
      .get<OrionRestShowResponse<Pdf>>(`/api/pdfs/${id}`, config)
      .then((res) => res.data.data);
  }, []);

  const updateBatchPdfAttachmentFiles = useCallback(
    (resources: Partial<PdfFile>[]) => {
      return axios.post("/api/pdf-attachment-files/batch", {
        resources,
      });
    },
    [],
  );

  const updatePdf = useCallback((id: Pdf["id"], values: Partial<Pdf>) => {
    return axios
      .put<OrionRestUpdateResponse<Pdf>>(`/api/pdfs/${id}`, values)
      .then((res) => res.data.data);
  }, []);

  return {
    deleteBatchPdfAttachmentFiles,
    deletePdf,
    getPdfAttachmentFiles,
    getPdf,
    updateBatchPdfAttachmentFiles,
    updatePdf,
  };
};
