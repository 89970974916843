import React from "react";
import { Member, SupportChat } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { AxiosRequestConfig } from "axios";
import { Tag } from "antd";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";
import { useMembersResource } from "@/entities/member";

type Record = SupportChat & { owner_full_name: string };

type Params = Partial<SupportChat>;

type TableProps = BaseProTableProps<Record, Params>;

type SupportChatTableProps = TableProps;

const SupportChatTable: React.FC<SupportChatTableProps> = ({ ...props }) => {
  const { getMembers } = useMembersResource();
  const request: TableProps["request"] = async (params, sort, filter) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/support-chats/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const data = await axios
      .request<OrionRestIndexResponse<Record>>(config)
      .then((res) => res.data);

    const membersIds = data.data.reduce((acc, item) => {
      if (item.owner_type === "member") {
        acc.push(item.owner_id);
      }
      return acc;
    }, [] as string[]);

    const members = await getMembers({
      filters: [
        {
          field: "id",
          operator: "in",
          value: membersIds,
        },
      ],
    });

    const supportChatsData = data.data.map((item) => {
      return {
        ...item,
        owner_full_name:
          members.find((m) => m.id === Number(item.owner_id))?.full_name ??
          "Анонимный пользователь",
      };
    });

    return {
      data: supportChatsData,
      success: true,
      total: data.meta.total,
    };
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    request,
    options: false,
    style: {
      width: "calc(100vw - 200px - 32px)",
      maxWidth: "calc(1920px - 200px - 32px)",
    },
    pagination: { showSizeChanger: true },
    columns: [
      {
        title: "IID",
        dataIndex: "iid",
        sorter: true,
        valueType: "digit",
        fieldProps: { min: 1 },
        copyable: true,
        width: 100,
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueEnum: {
          open: { text: <Tag color="success">Открыт</Tag> },
          closed: { text: <Tag color="error">Закрыт</Tag> },
        },
        defaultFilteredValue: ["open"],
        fieldProps: {
          tagRender: tagRender,
          mode: "multiple",
        },
      },
      {
        title: "Владелец",
        dataIndex: "owner_id",
        hideInSetting: true,
        disable: true,
        hideInSearch: true,
        renderText: (_, { owner_full_name }) => owner_full_name,
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        defaultSortOrder: "descend",
        sorter: true,
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        formItemProps: {
          normalize: normalizeDateRange,
          tooltip: false,
          name: ["range", "created_at"],
        },
        renderFormItem(_, config) {
          return (
            <DateTimeRangePickerWithTimeControls
              name={["range", "created_at"]}
              {...config}
              fieldProps={{ allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return <ProTable<Record> {...props} />;
};

export default SupportChatTable;
export type { SupportChatTableProps };
