import axios from "@/axios";
import { File } from "@/models";
import { OrionRestCreateResponse } from "@/shared/types/orion-rest";
import { CourseFormData, useCoursesResource } from "@/entities/course";
import { useCallback } from "react";

export const useSyncCourseCoverImage = () => {
  const { updateCourse } = useCoursesResource();

  const syncCourseCoverImage = useCallback(async (formData: CourseFormData) => {
    if (formData.cover_image_file && formData.cover_image_file.length > 0) {
      if (formData.cover_image_file.length > 1) {
        throw new Error("Only one image file is allowed");
      }

      if (formData.cover_image_file.length === 1) {
        const imageFile = formData.cover_image_file[0];
        if (!imageFile.id) {
          await axios
            .post<OrionRestCreateResponse<File>>(`/api/files`, {
              key: imageFile.response.key,
              name: imageFile.name,
              type: imageFile.type,
            })
            .then((res) => res.data.data)
            .then((file) =>
              updateCourse(formData.id, { cover_image_file_id: file.id }),
            );
        }
      }
    } else if (formData.cover_image_file_id) {
      await updateCourse(formData.id, { cover_image_file_id: null });
    }
  }, []);

  return { syncCourseCoverImage };
};
