import { useFilesResource } from "@/entities/file";
import { FormData, useScormsResource } from "@/entities/scorm";
import { useCallback } from "react";

export const useUpdateScormFile = () => {
  const { createFile } = useFilesResource();
  const { updateScorm } = useScormsResource();

  const updateScormFile = useCallback(async (values: FormData) => {
    const { package_file: packageFile } = values;
    if (packageFile && packageFile.length > 0) {
      for (let i = 0; i < packageFile.length; i++) {
        const item: any = packageFile[i];
        if (!item.originFileObj || (item.status && item.status !== "done"))
          continue;
        const key = item.response.key;
        await createFile({
          key,
          name: item.name,
          type: item.type,
        }).then((file) => {
          return updateScorm(values.id, { package_file_id: file.id });
        });
      }
    }
  }, []);

  return {
    updateScormFile,
  };
};
