import { TreeNode } from "@/models";

export const getTreeNodeNames = (node?: TreeNode): string => {
  if (!node) {
    return "";
  }

  const name = node.is_an?.name || "";

  if (node.reverse_tree) {
    const parentName = getTreeNodeNames(node.reverse_tree);

    if (!name) {
      return parentName;
    }

    return `${parentName}, ${name}`;
  }

  return name.trim();
};
