import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { FeatureFlagsPackage } from "@/models";
import { debounce } from "lodash";
import { BaseOptionType } from "antd/es/cascader";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSWR from "swr";
import { Skeleton } from "antd";

type FeatureFlagsPackageSearchSelectProps = Partial<ProFormSelectProps>;

const FeatureFlagsPackageSelect: React.FC<
  FeatureFlagsPackageSearchSelectProps
> = ({ fieldProps, ...selectProps }) => {
  const form = ProForm.useFormInstance();

  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState<any>();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(selectProps.name));
  }, []);

  const {
    data: featureFlagsPackage,
    isLoading,
    error,
  } = useSWR(
    ["/api/feature-flags-packages/search", searchValue, initialValue],
    async ([url]) => {
      const filters = [];

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      const res = await axios
        .post<{ data: FeatureFlagsPackage[] }>(url, { filters })
        .then(async (res) => {
          const data: BaseOptionType[] = [];

          res.data.data.forEach((featureFlagsPackage) => {
            data.push({
              label: featureFlagsPackage.name,
              value: featureFlagsPackage.id,
            });
          });

          if (initialValue && typeof initialValue === "object") {
            const missingValues: number[] = (
              Array.isArray(initialValue)
                ? initialValue
                : // @ts-ignore
                  selectProps.value ?? []
            ).filter(
              (value: any) => !data.some((option) => option.value === value),
            );

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<FeatureFlagsPackage>>(
                  "/api/feature-flags-packages/search",
                  {
                    filters: [
                      {
                        type: "and",
                        nested: missingValues.map((value) => ({
                          type: "or",
                          field: "id",
                          operator: "=",
                          value,
                        })),
                      },
                    ],
                  },
                )
                .then((res) =>
                  res.data.data.map((featureFlagsPackage) => ({
                    label: featureFlagsPackage.name,
                    value: featureFlagsPackage.id,
                  })),
                );

              data.push(...missingData);
            }
          }

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((option) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/feature-flags-packages/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: data.data.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  return (
    <ProFormSelect
      options={featureFlagsPackage}
      showSearch
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      {...selectProps}
    >
      {isFirstRender && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export default FeatureFlagsPackageSelect;
export type { FeatureFlagsPackageSearchSelectProps };
