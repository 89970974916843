import React from "react";
import { Pdf } from "@/models";
import { message, Modal } from "antd";
import { ButtonProps } from "antd/es/button";
import Button from "@/shared/ant-design/button/ui/button";
import { usePdfsResource } from "@/entities/pdf";

type Props = {
  pdfId: Pdf["id"];
  onDelete?: () => void;
};

const PdfDeleteButton: React.FC<Props> = ({
  pdfId,
  onDelete: onDeleteProp,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const { deletePdf } = usePdfsResource();

  const onDelete: ButtonProps["onClick"] = () => {
    modal.warning({
      title: "Удалить текущий pdf?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await deletePdf(pdfId);
        await onDeleteProp?.();
        message.success("Pdf успешно удалён");
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Button danger type={"primary"} onClick={onDelete}>
        Удалить
      </Button>
    </>
  );
};

export default PdfDeleteButton;
