import React, {
  Children,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ProForm } from "@ant-design/pro-components";
import { TabsProps } from "antd/lib/tabs";
import { Question, TestQuestionFormFields } from "@/entities/test";
import { Space, Spin, theme, Typography } from "antd";
import { TestFormRecord } from "@/entities/test";
import { arrayMove } from "@dnd-kit/sortable";
import { DndContextProps } from "@dnd-kit/core";
import { TestDraggableTabs } from "@/entities/test";
import { AddQuestionButton } from "./add-question-button";
import { generateTemplateQuestion } from "../lib/generate-template-question";
import { TabsItemChildrenWrapper } from "./tabs-item-children-wrapper";
import { debounce } from "lodash";

type QuestionsMenuProps = {
  children: React.ReactNode;
  isSubmitButtonLoading: boolean;
  toggleIsSubmitButtonLoading: () => void;
};

const FormQuestionsMenu: React.FC<QuestionsMenuProps> = ({
  children,
  isSubmitButtonLoading,
  toggleIsSubmitButtonLoading,
}) => {
  const form = ProForm.useFormInstance<TestFormRecord>();

  const {
    token: { colorPrimaryText, fontWeightStrong, fontSizeSM },
  } = theme.useToken();

  const [activeTab, setActiveTab] = useState("settings");

  const [tabItems, setTabItems] = useState<Required<TabsProps>["items"]>([
    {
      key: "loading",
      label: <Spin />,
    },
  ]);

  const questionBuilder = useCallback(
    (
      question: TestFormRecord["questions"][number],
      index: number,
      hasValidationErrors: boolean,
    ) => {
      return {
        key: question.uuid,
        label: (
          <Space
            direction={"vertical"}
            size={0}
            align={"start"}
            style={{
              whiteSpace: "normal",
            }}
          >
            <Typography.Text
              type={hasValidationErrors ? "danger" : undefined}
              style={{ margin: 0, fontSize: fontSizeSM }}
              strong
              delete={question.is_deleted}
            >
              Вопрос №{index + 1}
            </Typography.Text>
            <Typography.Paragraph
              delete={question.is_deleted}
              style={{
                margin: 0,
                textAlign: "start",
              }}
              ellipsis={{ rows: 2 }}
            >
              {question.text}
            </Typography.Paragraph>
          </Space>
        ),
        children: (
          <TabsItemChildrenWrapper>
            <TestQuestionFormFields
              currentIndex={index}
              onAfterFinish={updateTabs}
              isSubmitButtonLoading={isSubmitButtonLoading}
              toggleIsSubmitButtonLoading={toggleIsSubmitButtonLoading}
            />
          </TabsItemChildrenWrapper>
        ),
      };
    },
    [isSubmitButtonLoading],
  );

  const updateTabs = useCallback(async () => {
    const fieldsErrors = form.getFieldsError();
    const isQuestionHasErrors = (index: number) => {
      return (
        fieldsErrors.find((error) => {
          return (
            error.name &&
            error.name[0] === "questions" &&
            error.name[1] === index &&
            error.errors.length > 0
          );
        }) !== undefined
      );
    };

    const questions: TestFormRecord["questions"] =
      form.getFieldValue("questions") ?? [];

    setTabItems(() => {
      if (questions === undefined) {
        throw new Error("Questions is undefined");
      }

      const isSettingsHasErrors =
        fieldsErrors.find((error) => {
          return (
            error.name && error.name.length === 1 && error.errors.length > 0
          );
        }) !== undefined;

      return [
        {
          key: "settings",
          label: (
            <Typography.Text
              type={isSettingsHasErrors ? "danger" : undefined}
              style={{
                fontWeight: fontWeightStrong,
                color: colorPrimaryText,
              }}
            >
              Настройки теста
            </Typography.Text>
          ),
          children: (
            <TabsItemChildrenWrapper>
              {Children.map(
                children,
                (child) =>
                  isValidElement(child) &&
                  React.cloneElement(child as React.ReactElement, {
                    onAfterFinish: () => updateTabs(),
                  }),
              )}
            </TabsItemChildrenWrapper>
          ),
        },
        ...questions.map((question, index) => {
          return questionBuilder(question, index, isQuestionHasErrors(index));
        }),
      ];
    });
  }, [questionBuilder]);

  useEffect(() => {
    updateTabs();
  }, [isSubmitButtonLoading]);

  const addQuestion = useCallback(
    debounce(() => {
      const questions = form.getFieldValue("questions") ?? [];

      const newQuestion = generateTemplateQuestion();
      newQuestion.text = `Вопрос №${questions.length + 1}`;

      const newQuestions = [...questions, newQuestion];
      form.setFieldsValue({ questions: newQuestions });
      updateTabs();
      setActiveTab(newQuestion.uuid);
    }, 300),
    [form, updateTabs],
  );

  const onDragEnd: DndContextProps["onDragEnd"] = async ({ active, over }) => {
    if (!active || !over) return;
    if (active.id !== over.id) {
      if (typeof active.id !== "string" || typeof over?.id !== "string") {
        throw new Error("Invalid id type");
      }
      const activeUUID = active.id;
      const overUUID = over.id;
      if (!activeUUID || !overUUID) return;
      const questions: Question[] = form.getFieldValue(["questions"]);
      const getIndexByUUID = (uuid: string): number => {
        return questions.findIndex((item) => item.uuid === uuid);
      };

      const activeIndex = getIndexByUUID(activeUUID);
      const overIndex = getIndexByUUID(overUUID);

      if (activeIndex === -1 || overIndex === -1) return;

      const newQuestions = arrayMove(questions, activeIndex, overIndex);

      form.setFieldsValue({ questions: newQuestions });

      const newTabs = arrayMove(tabItems, activeIndex + 1, overIndex + 1);
      setTabItems(newTabs);
      updateTabs();
    }
  };

  return (
    <TestDraggableTabs
      onDragEnd={onDragEnd}
      size="small"
      tabBarGutter={0}
      tabBarExtraContent={<AddQuestionButton onClick={addQuestion} />}
      tabBarStyle={{ width: "200px" }}
      tabPosition={"left"}
      style={{ height: "100%" }}
      items={tabItems}
      activeKey={activeTab}
      onChange={setActiveTab}
    />
  );
};

export default FormQuestionsMenu;
export type { QuestionsMenuProps };
