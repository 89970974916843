import { cloneElement, FC } from "react";
import { Tabs, TabsProps } from "antd";
import {
  DndContext,
  DndContextProps,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 0 !important;
  }
  .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  "data-node-key": string;
}
const DraggableTabNode = ({ className, ...props }: DraggableTabPaneProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props["data-node-key"],
    });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

export const TestDraggableTabs: FC<
  Omit<TabsProps, "onDragEnd"> & { onDragEnd?: DndContextProps["onDragEnd"] }
> = ({ onDragEnd, ...props }) => {
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  return (
    <StyledTabs
      {...props}
      renderTabBar={(tabBarProps, DefaultTabBar) => {
        return (
          <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            <SortableContext
              items={(props.items ?? []).map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <DefaultTabBar {...tabBarProps}>
                {(node) => (
                  <>
                    {node.key === "settings" ? (
                      node
                    ) : (
                      <DraggableTabNode {...node.props} key={node.key}>
                        {node}
                      </DraggableTabNode>
                    )}
                  </>
                )}
              </DefaultTabBar>
            </SortableContext>
          </DndContext>
        );
      }}
    />
  );
};
