import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Member } from "@/models";
import {
  OrionRestIndexResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import useSpace from "@/entities/space/lib/use";
import { debounce } from "lodash";
import { BaseOptionType } from "antd/es/cascader";
import useSWR from "swr";
import { Skeleton } from "antd";

type SelectProps = ProFormSelectProps;

type MemberSelectProps = SelectProps & {
  whereHaveInCommissions?: boolean;
  listName?: ProFormSelectProps["name"];
};

export const MemberSelect: React.FC<MemberSelectProps> = ({
  whereHaveInCommissions,
  listName,
  fieldProps,
  ...selectProps
}) => {
  const space = useSpace();

  const form = ProForm.useFormInstance();

  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState<any>();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const {
    data: members,
    isLoading,
    error,
  } = useSWR(
    ["/api/members/search", searchValue, initialValue, whereHaveInCommissions],
    async ([url]) => {
      const filters = [];

      filters.push({
        field: "full_name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      const res = await axios
        .post<{ data: Member[] }>(url, {
          filters,
          scopes: whereHaveInCommissions
            ? [
                {
                  name: "whereHaveInCommissions",
                },
              ]
            : [],
        })
        .then(async (res) => {
          const data: BaseOptionType[] = [];

          res.data.data.forEach((member) => {
            data.push({
              label: `${member.full_name}(${
                space.authentication_types.includes("email+password")
                  ? member.email
                  : member.username
              })`,
              value: member.id,
            });
          });

          if (initialValue && typeof initialValue === "object") {
            const missingValues: number[] = (
              Array.isArray(initialValue)
                ? initialValue
                : // @ts-ignore
                  selectProps.value ?? []
            ).filter(
              (value: any) => !data.some((option) => option.value === value),
            );

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<Member>>("/api/members/search", {
                  filters: [
                    {
                      type: "and",
                      nested: missingValues.map((value) => ({
                        type: "or",
                        field: "id",
                        operator: "=",
                        value,
                      })),
                    },
                  ],
                })
                .then((res) =>
                  res.data.data.map((member) => ({
                    label: `${member.full_name}(${
                      space.authentication_types.includes("email+password")
                        ? member.email
                        : member.username
                    })`,
                    value: member.id,
                  })),
                );

              data.push(...missingData);
            }
          }

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((option) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get<OrionRestShowResponse<Member>>(
                `/api/members/${initialValue}`,
              )
              .then(({ data: { data: member } }) => ({
                label: `${member.full_name}(${
                  space.authentication_types.includes("email+password")
                    ? member.email
                    : member.username
                })`,
                value: member.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  return (
    <ProFormSelect
      options={members}
      showSearch
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      {...selectProps}
    >
      {isFirstRender && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export type { MemberSelectProps };
