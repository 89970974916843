import React from "react";
import {
  ProFormDigit,
  ProFormRadio,
  ProFormText,
  ProFormTextArea,
  ProFormUploadButton,
} from "@ant-design/pro-components";
import {
  uploadRequest,
  downloadFile,
  validateContentFile,
  UploadWithPreview,
} from "@/entities/file";
import {
  MAX_ATTACHMENT_FILES_NUMBER,
  MAX_CONTENT_FILES_NUMBER,
} from "@/entities/file/lib/constants";
import { UploadProps } from "antd";

type Props = {
  onRemoveFiles?: UploadProps["onRemove"];
};

export const PdfFormFields: React.FC<Props> = ({ onRemoveFiles }) => {
  return (
    <>
      <ProFormDigit name="id" label="ID" hidden />
      <ProFormDigit name="content_file_id" hidden />
      <ProFormDigit name="course_id" label="ID курса" hidden />
      <ProFormText
        name="name"
        label="Название"
        rules={[{ max: 255, required: true }]}
      />
      <ProFormTextArea name="description" label="Описание" />
      <UploadWithPreview
        name="content_files"
        label="Содержание"
        title={"Загрузите файл"}
        description={"Перетащите файл в эту область или нажмите на нее"}
        rules={[
          { required: true },
          () => ({
            validator(_, files) {
              if (files?.[0]) {
                return validateContentFile(files[0], "pdf");
              } else {
                return Promise.reject();
              }
            },
          }),
        ]}
        max={MAX_CONTENT_FILES_NUMBER}
        fieldProps={{
          listType: "picture",
          onRemove: onRemoveFiles,
          accept: ".pdf",
        }}
      />
      <ProFormRadio.Group
        name="display_type"
        label="Режим просмотра"
        radioType="button"
        rules={[{ required: true }]}
        fieldProps={{ buttonStyle: "solid" }}
        options={[
          {
            label: "Обычный",
            value: "default",
          },
          {
            label: "Презентация",
            value: "presentation",
          },
        ]}
      />

      <ProFormUploadButton
        name="attachment_files"
        label="Прикреплённые файлы"
        title={"Загрузить"}
        max={MAX_ATTACHMENT_FILES_NUMBER}
        rules={[
          { required: false },
          { type: "array", max: MAX_ATTACHMENT_FILES_NUMBER },
        ]}
        fieldProps={{
          listType: "picture-card",
          multiple: true,
          onRemove: onRemoveFiles,
          customRequest: uploadRequest,
          onDownload: downloadFile,
          showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: true,
          },
        }}
      />
    </>
  );
};
