import React, { ReactNode } from "react";
import { Course } from "@/models.ts";

export const contenViewOrderEnum: Record<
  Course["content_view_order_default"],
  ReactNode
> = {
  arbitrary: "Произвольный",
  consistent: "Последовательный",
};
