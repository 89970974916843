export function getAllLocalStorageItems(): { key: string; value: string }[] {
  const items: { key: string; value: string }[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key !== null) {
      const value = localStorage.getItem(key);
      if (value !== null) {
        items.push({ key, value });
      }
    }
  }
  return items;
}
