import React, { useMemo } from "react";
import { RenderError, ViewerProps, ViewMode } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Space, Alert } from "antd";
import * as pdfJS from "pdfjs-dist";
import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker?url";
import useTheme from "@/shared/theme/lib/use";
import * as Sentry from "@sentry/react";
import { Pdf } from "@/models";
import { PresentationPreviewPdf } from "./presentation-preview-pdf";
import { DefaultPreviewPdf } from "./default-preview-pdf";

type PreviewPdfProps = {
  src: string;
  displayType: Pdf["display_type"];
};

pdfJS.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

const PreviewPdf: React.FC<PreviewPdfProps> = ({ src, displayType }) => {
  const colorTheme = useTheme();

  const renderError: RenderError = (error) => {
    Sentry.captureException(error);
    return (
      <Space
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
        align="center"
      >
        <Alert message={error.message} type="error" />
      </Space>
    );
  };

  const defaultProps: Partial<ViewerProps> = useMemo(
    () => ({
      theme: colorTheme.value,
      viewMode: ViewMode.SinglePage,
      defaultScale: SpecialZoomLevel.PageFit,
      renderError,
    }),
    [],
  );

  return (
    <>
      {displayType === "presentation" ? (
        <PresentationPreviewPdf src={src} {...defaultProps} />
      ) : (
        <DefaultPreviewPdf src={src} {...defaultProps} />
      )}
    </>
  );
};

export default PreviewPdf;
export type { PreviewPdfProps };
