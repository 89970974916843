import React, { useState } from "react";
import { Longread } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormText,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Flex, message, Spin } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import "react-quill/dist/quill.snow.css";
import useMe from "@/entities/me/lib/use";
import {
  LongreadContentField,
  FormData,
  useLongreadsResource,
} from "@/entities/longread";
import { SpinWrapper } from "@/shared/ant-design/spin/ui/spin-wrapper";
import LongreadDeleteButton from "./delete-button";
import { useFilesResource } from "@/entities/file";

type Props = ProFormProps<FormData> & {
  rest: RestProps<Longread>;
  onDelete?: () => void;
};

const LongreadForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const { createFile } = useFilesResource();
  const { getLongread, updateLongread } = useLongreadsResource();

  const member = useMe();

  const [form] = ProForm.useForm<FormData>();

  const defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      setIsLoading(true);
      return getLongread(Number(rest.recordKey), {
        params: { include: "content_file" },
      })
        .then((longread) => {
          if (!longread.content_file) {
            return { ...longread, content: null };
          }

          return axios.get(longread.content_file!.url!).then((res) => {
            return { ...longread, content: res.data };
          });
        })
        .catch((err) => {
          setError(err);
          throw err;
        })
        .finally(() => setIsLoading(false));
    };

    defaultProps.onFinish = async (values) => {
      setIsLoading(true);

      const { content, ...restValues } = values;

      return updateLongread(Number(rest.recordKey), restValues)
        .then(async (longread) => {
          if (content) {
            const name = `${longread.uuid}.longread`;
            await axios
              .post(`/api/filesystem/s3`, { name })
              .then((res) => res.data.data)
              .then(async (data) => {
                await axios.put(data.url, content);
                return data.key;
              })
              .then(async (key) => {
                return await createFile({
                  name,
                  type: "longread",
                  key,
                }).then((file) => {
                  return updateLongread(values.id, {
                    content_file_id: file.id,
                  });
                });
              });
          }

          return longread;
        })
        .then(async (longread) => {
          rest.onAfterUpdate?.(longread);
          message.success("Лонгрид успешно обновлен");
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? `Ошибка при обновлении лонгрида`,
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  if (error) throw error;

  return (
    <SpinWrapper>
      <Spin style={{ height: "100%" }} spinning={isLoading}>
        <ProForm<FormData> {...props} style={{ height: "100%" }}>
          <Flex vertical style={{ width: "100%", height: "100%" }}>
            <ProFormDigit name="id" label="ID" hidden />
            <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
              {member.permissions.includes("course:update") && (
                <>
                  <LongreadDeleteButton
                    onDelete={onDeleteProp}
                    longreadId={Number(props.id)}
                  />
                  <Button
                    loading={isLoading}
                    type={"primary"}
                    onClick={form.submit}
                  >
                    Сохранить
                  </Button>
                </>
              )}
            </Flex>
            <ProFormDigit name="course_id" label="ID курса" hidden />
            <ProFormText
              name="name"
              label="Название"
              rules={[{ required: true, max: 255 }]}
            />
            <ProFormText name="content" hidden />
            <LongreadContentField />
          </Flex>
        </ProForm>
      </Spin>
    </SpinWrapper>
  );
};

export default LongreadForm;
