import React, { useEffect, useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { Flex, Space, Button, theme, Spin } from "antd";
import { Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSplitPdf } from "../lib/use-split-pdf";

const CarouselWrapper = styled.div`
  .ant-carousel {
    /*_____________Button height */
    height: calc(100% - 32px);
    width: 100%;
  }
  .slick-list {
    height: 100% !important;
  }
  .slick-track {
    height: 100% !important;
  }
  .slick-slide > div {
    height: 100% !important;
  }
  .rpv-core__inner-pages {
  }
  .rpv-core__inner-pages {
    display: flex;
    align-items: center;
  }
  .slick-arrow.slick-prev.ant-space,
  .slick-arrow.slick-next.ant-space {
    height: 40px !important;
    width: 40px !important;
    display: flex !important;
    align-items: center;
  }

  .slick-arrow.slick-prev.ant-space:after,
  .slick-arrow.slick-next.ant-space:after {
    display: none !important;
  }
`;

type PreviewPdfProps = {
  src: string;
};

export const PresentationPreviewPdf: React.FC<PreviewPdfProps> = ({
  src,
  ...props
}) => {
  const { token } = theme.useToken();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const nodes: HTMLElement[] = Array.from(
        ref.current.querySelectorAll(".slick-current"),
      );
      nodes[0] && nodes[0].focus?.();
    }
  }, [ref.current, isFullScreen]);

  // Разделение PDF на страницы
  const { pageUrls, isLoading } = useSplitPdf(src);

  if (isLoading)
    return (
      <Flex
        justify="center"
        align="center"
        style={{ width: "100%", height: "100%" }}
      >
        <Spin />
      </Flex>
    );

  return (
    <Flex vertical style={{ width: "100%", height: "100%" }}>
      <Flex justify="end">
        <Button type="link" onClick={() => setIsFullScreen(true)}>
          Во весь экран
        </Button>
      </Flex>
      <CarouselWrapper
        ref={ref}
        style={{
          width: isFullScreen ? "100vw" : "100%",
          height: isFullScreen ? "100vh" : "100%",
          position: isFullScreen ? "fixed" : "static",
          top: 0,
          left: 0,
          zIndex: 99,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: token.colorBgContainer,
        }}
      >
        {isFullScreen && (
          <Flex justify="end" align="center" style={{ width: "100%" }}>
            <Button type="link" onClick={() => setIsFullScreen(false)}>
              Вернуться
            </Button>
          </Flex>
        )}
        <Carousel
          style={{ width: "100%", height: "100%" }}
          arrows
          swipeToSlide
          focusOnSelect
          draggable
          nextArrow={
            <Space styles={{ item: { color: token.colorText } }}>
              <Button
                type="text"
                size="large"
                icon={<RightOutlined size={token.fontSizeHeading3} />}
              />
            </Space>
          }
          prevArrow={
            <Space styles={{ item: { color: token.colorText } }}>
              <Button
                type="text"
                size="large"
                icon={<LeftOutlined size={token.fontSizeHeading3} />}
              />
            </Space>
          }
          dots={false}
          infinite
        >
          {(pageUrls ?? []).map((pageUrl) => (
            <Viewer key={pageUrl} {...props} fileUrl={pageUrl} />
          ))}
        </Carousel>
      </CarouselWrapper>
    </Flex>
  );
};

export type { PreviewPdfProps };
