import React from "react";
import { ProForm, ProFormProps } from "@ant-design/pro-components";
import FormQuestionsMenu from "@/features/test/ui/form-questions-menu";
import { Test } from "@/models";
import FormFields from "@/features/test/ui/form-fields";
import { deepmerge } from "deepmerge-ts";
import { message } from "antd";
import {
  mutateQuestionAfterResponse,
  mutateQuestionBeforeRequest,
  DEFAULT_TEST_VALUES,
  useTestsResource,
  TestFormRecord,
} from "@/entities/test";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useMe from "@/entities/me/lib/use";

import { RestProps } from "@/shared/rest/lib/types";

type TestFormProps = Partial<ProFormProps<TestFormRecord>> & {
  rest: RestProps<Test>;
  onDelete?: () => void;
};

const TestForm: React.FC<TestFormProps> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [form] = ProForm.useForm<TestFormRecord>();
  const { updateTest, deleteTest, getTest } = useTestsResource();

  const member = useMe();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] =
    React.useState(false);

  const toggleIsSubmitButtonLoading = () =>
    setIsSubmitButtonLoading((prev) => !prev);

  const transformTestQuestions = (test: Test) => ({
    ...test,
    questions: mutateQuestionAfterResponse(test.questions),
  });

  const defaultProps: Partial<ProFormProps<TestFormRecord>> = {
    initialValues: DEFAULT_TEST_VALUES,
    submitter: {
      searchConfig: {
        resetText: "Сбросить",
        submitText: rest.type === "create" ? "Создать тест" : "Обновить тест",
      },
      resetButtonProps: {
        style: { display: "none" },
      },
    },
  };

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }
  defaultProps.request = () => {
    return getTest(Number(rest.recordKey)).then((test) => {
      return transformTestQuestions(test);
    });
  };

  defaultProps.submitter = false;

  defaultProps.onFinish = async (value) => {
    value = form.getFieldsValue(true);

    if (!value.questions) value.questions = [];

    value.questions = mutateQuestionBeforeRequest(value.questions);

    return await updateTest(Number(rest.recordKey), value)
      .then(async (test) => {
        message.success("Тест успешно обновлен");
        await rest.onAfterUpdate?.(test);

        form.setFieldsValue(transformTestQuestions(test));
        return true;
      })
      .catch((reason) => {
        message.error(reason.response.data.message ?? "Что-то пошло не так...");

        if (reason.response.status === 422) {
          setValidationErrorsToFormFields(form, reason.response.data.errors);
        } else {
          console.error(reason);
        }

        return false;
      })
      .finally(() => {
        setIsSubmitButtonLoading(false);
      });
  };

  const overrideProps: Partial<ProFormProps<TestFormRecord>> = {
    form,
  };

  props = deepmerge(defaultProps, props, overrideProps);

  const onDelete = async () => {
    if (rest.type !== "update") {
      throw new Error(`Delete is not supported for create`);
    }

    return deleteTest(Number(rest.recordKey))
      .then(() => {
        message.success("Тест удален");
        onDeleteProp?.();
      })
      .catch((reason) => {
        message.error(reason.response.data.message ?? "Что-то пошло не так");
      });
  };

  return (
    <ProForm<TestFormRecord>
      {...props}
      style={{ height: "100%" }}
      disabled={!member.permissions.includes("course:update")}
    >
      <FormQuestionsMenu
        isSubmitButtonLoading={isSubmitButtonLoading}
        toggleIsSubmitButtonLoading={toggleIsSubmitButtonLoading}
      >
        <FormFields
          onDelete={onDelete}
          isSubmitButtonLoading={isSubmitButtonLoading}
          toggleIsSubmitButtonLoading={toggleIsSubmitButtonLoading}
        />
      </FormQuestionsMenu>
    </ProForm>
  );
};

export default TestForm;
export type { TestFormProps, TestFormRecord };
