import React, { useMemo } from "react";
import {
  ModalForm,
  ModalFormProps,
  ProForm,
  ProFormItem,
  ProFormProps,
} from "@ant-design/pro-components";
import { Group, Member } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
} from "@/shared/types/orion-rest";
import { Button, message } from "antd";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { MemberSelectTable } from "./members-select-table";

type Record = Group & {
  members_ids: Member["id"][];
};

type GroupParticipantFormBatchRecord = {
  groupId: number;
  memberIds: number[];
};

type GroupFormProps = ProFormProps<Record> & {
  modal?: boolean;
  groupId?: Group["id"][];
  onAfterAdd?: () => void;
};

const GroupParticipantForm: React.FC<GroupFormProps> = ({
  modal = false,
  groupId,
  onAfterAdd,
  ...props
}) => {
  const [form] = ProForm.useForm<Record>(props.form);

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  defaultProps = deepmerge(defaultProps, {
    submitter: { searchConfig: { submitText: "Добавить" } },
  });
  defaultProps.onFinish = async (values) => {
    const filters = {
      id: groupId ?? null,
    };
    const existingGroupsParticipants = await axios
      .post<OrionRestIndexResponse<Group>>("/api/groups/search", {
        includes: [{ relation: "participants" }],
        ...axiosConfigAdapter({}, {}, filters),
      })
      .then((res) =>
        res.data.data.map((group) => ({
          groupId: group.id,
          memberIds: group.participants.map(
            (participant) => participant.member_id,
          ),
        })),
      )
      .catch((err) => {
        message.error(
          err.response.data.message ?? "Ошибка при добавлении участников",
        );
      });

    const selectedGroupsParticipants = groupId?.map((groupId) => ({
      groupId,
      memberIds: values.members_ids,
    }));

    const filterUniqueMembers = (
      existing: GroupParticipantFormBatchRecord[],
      selected: GroupParticipantFormBatchRecord[],
    ) => {
      return selected.map((selectedGroup) => {
        const existingGroup = existing.find(
          (group) => group.groupId === selectedGroup.groupId,
        );
        if (!existingGroup) {
          return selectedGroup;
        }

        const uniqueMembers = selectedGroup.memberIds.filter((memberId) => {
          return !existingGroup.memberIds.includes(memberId);
        });

        return {
          groupId: selectedGroup.groupId,
          memberIds: uniqueMembers,
        };
      });
    };

    if (!existingGroupsParticipants || !selectedGroupsParticipants)
      return false;

    const filteredGroupsParticipants = filterUniqueMembers(
      existingGroupsParticipants,
      selectedGroupsParticipants,
    );

    const resources = filteredGroupsParticipants.flatMap(
      ({ groupId, memberIds }) => {
        return memberIds?.map((memberId) => ({
          member_id: memberId,
          group_id: groupId,
        }));
      },
    );

    if (resources.length === 0) {
      message.error("Пользователи уже есть в указанных группе/группах");
      return false;
    }

    return await axios
      .post<OrionRestCreateResponse<Group>>("/api/group-participants/batch", {
        resources,
      })
      .then(() => {
        message.success("Участники добавлены в указанные группу/группы");
        onAfterAdd?.();
        return true;
      })
      .catch((err) => {
        message.error(
          err.response.data.message ?? "Ошибка при добавлении участников",
        );

        if (err.response.status === 422) {
          setValidationErrorsToFormFields(form, err.response.data.errors);
        } else {
          console.error(err);
        }

        return false;
      });
  };

  /** Pre Render */

  const FormComponent = useMemo(
    () => (modal ? ModalForm<Record> : ProForm<Record>),
    [modal],
  );
  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Type Modal */

  if (modal) {
    const disabled = groupId === undefined || groupId?.length === 0;
    const modalFormProps: Partial<ModalFormProps<Group>> = {
      width: "80%",
      title: "Добавление участника в группу",
      trigger: (
        <Button type={"primary"} disabled={disabled}>
          Добавить в группу
        </Button>
      ),
      modalProps: {
        destroyOnClose: true,
      },
    };

    props = deepmerge(modalFormProps, props);
  }

  /** Render */

  return (
    <FormComponent {...props}>
      <ProFormItem
        name={"members_ids"}
        label="Пользователи"
        rules={[
          { required: true, message: "Выберите хотя бы одного участника" },
        ]}
      >
        <MemberSelectTable groupIds={groupId} />
      </ProFormItem>
    </FormComponent>
  );
};
export default GroupParticipantForm;
export type { GroupFormProps };
