import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import useSWR from "swr";
import { debounce } from "lodash";
import { Button, Flex, Skeleton } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BaseOptionType } from "antd/es/cascader";
import { useCoursesResource } from "@/entities/course";

type CourseSearchSelectProps = Partial<ProFormSelectProps> & {
  isActiveFilter?: boolean;
  listName?: ProFormSelectProps["name"];
  whereHaveInCommissions?: boolean;
  withExternalLink?: boolean;
};

export const CourseSelect: React.FC<CourseSearchSelectProps> = ({
  isActiveFilter,
  fieldProps,
  listName,
  whereHaveInCommissions,
  withExternalLink,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();
  const value = ProForm.useWatch(listName ?? selectProps.name, form);
  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState<any>();
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const navigate = useNavigate();
  const { getCourse, getCourses } = useCoursesResource();

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const {
    data: courses,
    isLoading,
    error,
  } = useSWR(
    ["/api/courses/search", searchValue, initialValue, whereHaveInCommissions],
    async ([url]) => {
      const filters = [];

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      if (isActiveFilter) {
        filters.push({
          field: "status",
          operator: "=",
          value: "active",
        });
      }

      const res = await getCourses({
        filters,
        scopes: whereHaveInCommissions
          ? [
              {
                name: "whereHaveInCommissions",
              },
            ]
          : [],
      }).then(async (res) => {
        const data: BaseOptionType[] = [];

        res.data.data.forEach((course) => {
          data.push({
            label: course.name,
            value: course.id,
          });
        });

        if (initialValue && typeof initialValue === "object") {
          const missingValues: number[] = (
            Array.isArray(initialValue)
              ? initialValue
              : // @ts-ignore
                selectProps.value ?? []
          ).filter(
            (value: any) => !data.some((option) => option.value === value),
          );

          if (missingValues.length > 0) {
            const missingData = await getCourses({
              filters: [
                {
                  type: "and",
                  nested: missingValues.map((value) => ({
                    type: "or",
                    field: "id",
                    operator: "=",
                    value,
                  })),
                },
              ],
            }).then((res) =>
              res.data.data.map((course) => ({
                label: course.name,
                value: course.id,
              })),
            );

            data.push(...missingData);
          }
        }

        if (
          initialValue &&
          typeof initialValue !== "object" &&
          !data.some((option) => option.value === initialValue)
        ) {
          const currentData = await getCourse(initialValue).then((course) => ({
            label: course.name,
            value: course.id,
          }));
          data.unshift(currentData);
        }

        return data;
      });
      return res;
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  const TabsItemChildrenWrapper: React.FC<{ children: React.ReactNode }> =
    useCallback(
      ({ children }) => {
        const Wrapper = withExternalLink ? Flex : React.Fragment;
        return <Wrapper>{children}</Wrapper>;
      },
      [withExternalLink],
    );

  return (
    <TabsItemChildrenWrapper>
      <ProFormSelect
        options={courses}
        showSearch
        label={"Курс"}
        fieldProps={{
          onSearch: onSearch,
          loading: isLoading,
          ...fieldProps,
        }}
        {...selectProps}
      >
        {isFirstRender && isLoading && <Skeleton.Input active block />}
      </ProFormSelect>
      {value && withExternalLink && (
        <Button
          style={{ position: "relative" }}
          type="link"
          onClick={() => navigate(`/manage/courses/${value}`)}
        >
          <ExportOutlined />
        </Button>
      )}
    </TabsItemChildrenWrapper>
  );
};

export type { CourseSearchSelectProps };
