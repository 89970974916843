import React, { useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Divider,
  Dropdown,
  Flex,
  GlobalToken,
  message,
  Modal,
  theme,
  Typography,
} from "antd";
import {
  ChatMessage,
  ProChat as AntdProChat,
  ProChatInstance,
} from "@ant-design/pro-chat";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import {
  Member,
  SupportChatMessage,
  SupportChat as SupportChatModel,
} from "@/models";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useMe from "@/entities/me/lib/use";
import dayjs from "dayjs";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import styled from "styled-components";
import {
  DownOutlined,
  EllipsisOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import SupportChatItem from "@/entities/support/ui/chat-item";

const ProChat = styled(AntdProChat)<{ token: GlobalToken }>`
  .ant-pro-chat-list-item-message-container p {
    color: ${({ token }) => token.colorWhite} !important;
  }
  .ant-pro-chat-list-item-message-content {
    background-color: ${({ token }) => token.colorPrimary};
  }
  .ant-pro-chat-input-area {
    padding: 0;
    border-radius: ${({ token }) => token.borderRadius}px;
    background-image: none;
  }
  .ant-pro-chat-chat-list-container {
    flex-grow: 1 !important;
    overflow: hidden auto;
  }
  .ant-pro-chat-list-item {
    padding: ${({ token }) => `${token.paddingSM}px ${token.padding}px`};
  }
`;

type SupportChatProps = {
  supportChatId?: SupportChatModel["id"];
  supportChatOwnerId?: SupportChatModel["owner_id"];
  onAfterClose?: () => void;
  isInModal?: boolean;
  isMobile?: boolean;
  status?: SupportChatModel["status"];
  navigateToUser?: (memberId: Member["id"]) => void;
};

const SupportChat: React.FC<SupportChatProps> = ({
  supportChatId: _supportChatId,
  supportChatOwnerId,
  onAfterClose,
  status,
  navigateToUser,
  isInModal,
}) => {
  const member = useMe();
  const supportChatMessagesURL = `/api/support-chat-messages/search`;
  const loadButtonItem = React.useRef({
    content: "",
    role: "load-more",
    createAt: 0,
    updateAt: 0,
    id: "load-more",
  });

  const isNewChat = useRef<boolean>(false);

  const [supportChatId, setSupportChatId] = React.useState(
    _supportChatId || null,
  );
  const [supportChatMessages, setSupportChatMessages] = React.useState<
    ChatMessage[] | []
  >([]);
  const ref = useRef<HTMLDivElement>(null);

  const [disabled, setDisabled] = React.useState(false);
  const chatRef = useRef<ProChatInstance | undefined>(undefined);

  const [sender] = React.useState<{
    id: string;
    type: SupportChatModel["owner_type"];
  }>(() => {
    if (member)
      return {
        id: member.id.toString(),
        type: "member",
      };
    let anonimId = localStorage.getItem("anonimId");
    if (!anonimId) {
      anonimId = uuidv4();
      localStorage.setItem("anonimId", anonimId);
    }

    return {
      id: anonimId,
      type: "anonymous",
    };
  });

  const { token } = theme.useToken();
  const [modal, modalHolder] = Modal.useModal();
  const [isLastPage, setIsLastPage] = React.useState<boolean | undefined>(
    undefined,
  );

  const pageSize = 20;

  const fetchSupportMessages = async (url: string, current?: number) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url,
      headers: sender.type === "anonymous" ? { ANONIM_UUID: sender.id } : {},
      ...axiosConfigAdapter({ current, pageSize }),
    };
    config.data.filters.push({
      field: "support_chat_id",
      operator: "=",
      value: supportChatId,
    });
    config.data.sort.push({
      field: "created_at",
      order: "desc",
    });
    config.data.sort.push({
      field: "id",
      direction: "desc",
    });

    return axios
      .request<OrionRestIndexResponse<SupportChatMessage>>(config)
      .then(async (res) => {
        const resources = res.data.data.reduce(
          (acc: { [key: string]: Partial<SupportChatMessage> }, message) => {
            if (!message.is_read && sender.id !== message.sender_id) {
              acc[message.id] = {
                ...message,
                is_read: true,
              };
            }
            return acc;
          },
          {},
        );

        if (Object.keys(resources).length > 0) {
          await axios.request({
            method: "PATCH",
            url: `/api/support-chat-messages/batch`,
            data: { resources },
            headers:
              sender.type === "anonymous" ? { ANONIM_UUID: sender.id } : {},
          });
        }

        return res;
      })
      .then((res) => {
        const data = res.data.data.map((supportChatMessage) => ({
          id: supportChatMessage.id.toString(),
          content: supportChatMessage.body,
          createAt: dayjs(supportChatMessage.created_at).valueOf(),
          updateAt: dayjs(supportChatMessage.updated_at).valueOf(),
          role: supportChatOwnerId
            ? supportChatMessage.sender_id === supportChatOwnerId
              ? "assistant"
              : "user"
            : sender.id === supportChatMessage.sender_id
            ? "user"
            : "assistant",
          senderId: supportChatMessage.sender_id,
          senderType: supportChatMessage.sender_type,
        }));
        if (current) {
          setIsLastPage(res.data.meta.current_page >= res.data.meta.last_page);
        }

        return data;
      });
  };

  const { data, error, isLoading, setSize } = useSWRInfinite(
    (pageIndex: number, previousPageData: SupportChatMessage[]) => {
      if (previousPageData && !previousPageData.length) return null;
      if (!supportChatId) return null;
      return [supportChatMessagesURL, pageIndex + 1, supportChatId];
    },
    async ([url, current]) => {
      return fetchSupportMessages(url, current);
    },
  );

  const { error: supportChatError } = useSWR(
    !supportChatId
      ? ["/api/support-chats/search", sender.id, sender.type]
      : null,
    async ([url]) => {
      return axios
        .post<OrionRestIndexResponse<SupportChatMessage>>(
          url,
          {
            filters: [
              {
                field: "owner_id",
                operator: "=",
                value: sender.id,
              },
              {
                field: "owner_type",
                operator: "=",
                value: sender.type,
              },
              {
                field: "status",
                operator: "=",
                value: "open",
              },
            ],
          },
          {
            headers:
              sender.type === "anonymous" ? { ANONIM_UUID: sender.id } : {},
          },
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            setSupportChatId(res.data.data[0].id);
          }
          return res.data;
        });
    },
  );

  useEffect(() => {
    let interval: undefined | NodeJS.Timeout;
    if (supportChatId && status !== "closed") {
      interval = setInterval(() => {
        fetchSupportMessages(supportChatMessagesURL).then((res) => {
          setSupportChatMessages(res);
        });
        axios
          .get<OrionRestShowResponse<SupportChatModel>>(
            `/api/support-chats/${supportChatId}`,
          )
          .then((res) => {
            if (res.data.data.status === "closed" && !supportChatOwnerId) {
              onAfterClose?.();
            }
          });
      }, 5000);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [supportChatId]);

  useEffect(() => {
    if (!disabled) {
      setTimeout(() => {
        ref.current?.focus();
      }, 300);
    }
  }, [disabled]);

  const createSupportChatMessage = async (
    supportChatId: SupportChatModel["id"],
    messageBody: any,
  ) => {
    setDisabled(true);
    await axios
      .post<OrionRestCreateResponse<SupportChatMessage>>(
        "/api/support-chat-messages",
        {
          support_chat_id: supportChatId,
          body: messageBody,
          sender_id: sender.id,
          sender_type: sender.type,
        },
        {
          headers:
            sender.type === "anonymous" ? { ANONIM_UUID: sender.id } : {},
        },
      )
      .then((res) => {
        setSupportChatMessages((prev) => [
          ...prev,
          {
            id: res.data.data.id.toString(),
            content: res.data.data.body,
            createAt: dayjs(res.data.data.created_at).valueOf(),
            updateAt: dayjs(res.data.data.updated_at).valueOf(),
            role: "user",
            senderId: res.data.data.sender_id,
            senderType: res.data.data.sender_type,
          },
        ]);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const closeSupportChat = () => {
    modal.confirm({
      zIndex: 99999,
      title: "Закрытие обращения",
      content: "Вы действительно хотите закрыть обращение?",
      onOk: async () => {
        await axios
          .put(
            `/api/support-chats/${supportChatId}`,
            { status: "closed" },
            {
              headers:
                sender.type === "anonymous" ? { ANONIM_UUID: sender.id } : {},
            },
          )
          .then(() => onAfterClose?.());
      },
    });
  };

  if (error) throw error;
  if (supportChatError) throw supportChatError;

  const mergedMessages = [
    ...(data?.flat().reverse() ?? []),
    ...supportChatMessages,
  ];
  const sortedMessages = mergedMessages.sort((a, b) => a.createAt - b.createAt);
  const chatMessages =
    isLastPage === false
      ? [loadButtonItem.current, ...sortedMessages]
      : sortedMessages;

  return (
    <Flex
      style={{
        width: "100%",
        maxWidth: "calc(1920px - 200px - 32px)",
        height: "100%",
      }}
      vertical
    >
      {modalHolder}
      {supportChatId && (
        <>
          {isInModal ? (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "close",
                    label: "Закрыть обращение",
                    onClick: closeSupportChat,
                  },
                ],
              }}
            >
              <Button
                style={{
                  position: "absolute",
                  zIndex: 1,
                  color: token.colorWhite,
                  top: "6px",
                  right: "46px",
                }}
                type="text"
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
          ) : (
            <>
              <Flex
                justify={status === "closed" ? "space-between" : "end"}
                align="center"
                style={{
                  padding: token.paddingSM,
                }}
              >
                {status === "closed" && (
                  <Alert
                    style={{ padding: token.paddingSM }}
                    type="success"
                    message="Обращение закрыто"
                  />
                )}

                <Button
                  disabled={status === "closed"}
                  danger
                  type="primary"
                  onClick={closeSupportChat}
                >
                  Закрыть обращение
                </Button>
              </Flex>
              <Divider style={{ margin: 0 }} />
            </>
          )}
        </>
      )}
      <div
        style={{
          flexGrow: 1,
          height: `calc(100% - ${
            isInModal ? 0 : status === "closed" ? 64 : 56
          }px)`,
          width: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: token.borderRadius,
        }}
      >
        <ProChat
          inputAreaProps={{
            disabled: status === "closed" || disabled,
            ref: ref,
          }}
          chatRef={chatRef}
          onChatsChange={() => {
            setTimeout(() => {
              if (chatRef.current) {
                chatRef.current.scrollToBottom?.();
              }
            }, 300);
          }}
          sendButtonRender={(_, defaultProps) => (
            <Button
              {...defaultProps}
              type="text"
              icon={<SendOutlined />}
              disabled={status === "closed" || disabled}
              loading={disabled}
            />
          )}
          token={token}
          loading={isLoading && !isNewChat.current}
          placeholder="Введите ваш вопрос"
          backToBottomConfig={{
            visibilityHeight: 20,
            render: (_, scrollToBottom, backBottomConfig: any) => {
              const { current: target } = backBottomConfig.target;
              const visibleDownButton =
                target?.scrollHeight -
                  (target?.clientHeight + target?.scrollTop) >
                backBottomConfig.visibilityHeight;

              return (
                <Button
                  icon={<DownOutlined />}
                  type="primary"
                  size="large"
                  onClick={scrollToBottom}
                  style={{
                    opacity: visibleDownButton ? 0.8 : 0,
                    visibility: visibleDownButton ? "visible" : "hidden",
                    transition: "all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
                    borderRadius: "50%",
                    bottom: "180px",
                    position: "absolute",
                    right: token.margin,
                  }}
                />
              );
            },
          }}
          chats={chatMessages}
          chatItemRenderConfig={{
            actionsRender: false,
            render: (item) => {
              if (item?.originData?.role === "hello") return <></>;

              if (item?.originData?.role === "load-more") {
                return (
                  <Flex justify="center">
                    <Button
                      type="link"
                      onClick={() => setSize((prev) => prev + 1)}
                    >
                      Загрузить еще
                    </Button>
                  </Flex>
                );
              }

              return (
                <SupportChatItem
                  key={item.message?.toString()}
                  senderId={item.originData?.senderId}
                  senderType={item.originData?.senderType}
                  supportChatOwnerId={supportChatOwnerId}
                  role={item.originData?.role}
                  navigateToUser={navigateToUser}
                  {...item}
                />
              );
            },
            titleRender: (props) => {
              const createdAt = props.originData?.createAt;
              return (
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: token.fontSizeSM }}
                >
                  {dayjs(createdAt).format(dateSTime)}
                </Typography.Text>
              );
            },
          }}
          actions={{
            render: () => {
              return [];
            },
          }}
          request={async (messages) => {
            let currentSupportChatId = supportChatId;
            if (!currentSupportChatId) {
              isNewChat.current = true;
              axios
                .post<OrionRestCreateResponse<SupportChatModel>>(
                  "/api/support-chats",
                  {
                    owner_id: sender.id,
                    owner_type: sender.type,
                    status: "open",
                  },
                  {
                    headers:
                      sender.type === "anonymous"
                        ? { ANONIM_UUID: sender.id }
                        : {},
                  },
                )
                .then(async (res) => {
                  currentSupportChatId = res.data.data.id;
                  setSupportChatId(currentSupportChatId);
                  createSupportChatMessage(
                    currentSupportChatId,
                    messages[messages.length - 1].content,
                  );
                })
                .catch((err) => {
                  message.error(
                    err.response.data.message ?? "Не удалось создать чат",
                  );
                  throw err;
                });
            } else {
              createSupportChatMessage(
                currentSupportChatId,
                messages[messages.length - 1].content,
              );
            }
          }}
        />
      </div>
    </Flex>
  );
};

export default SupportChat;
export type { SupportChatProps };
