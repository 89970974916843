import React, { useState } from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { message, Spin } from "antd";
import { Course, Pool, PoolTemplate } from "@/models";
import { CourseSelect } from "@/features/course";
import dayjs from "dayjs";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { RestProps } from "@/shared/rest/lib/types";
import useSWR from "swr";
import {
  dateSTime,
  dateSTimeNormalize,
  toISOString,
} from "@/shared/dayjs/lib/formats";
import useFeatures from "@/entities/features/lib/use.ts";
import DateTimePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-picker-with-time-controls/ui/date-time-picker-with-time-controls";

type PoolFormProps = ProFormProps<Pool> & {
  rest: RestProps<Pool>;
  courseId?: Course["id"];
};

const PoolForm: React.FC<PoolFormProps> = ({ rest, courseId, ...props }) => {
  const features = useFeatures();
  const {
    data: course,
    isLoading: isCourseLoading,
    error: courseLoadingError,
  } = useSWR(courseId ? `/api/courses/${courseId}` : null, async (url) => {
    return axios
      .get<OrionRestShowResponse<Course>>(url)
      .then((res) => res.data.data);
  });
  const [form] = ProForm.useForm<Pool>(props.form);

  const is_unlimited = ProForm.useWatch("is_unlimited", form);
  const status = ProForm.useWatch("status", form);
  const [error, setError] = useState<Error | null>(null);

  if (isCourseLoading) return <Spin />;
  if (courseLoadingError) throw courseLoadingError;

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: false,
    },
    preserve: false,
    initialValues: {
      status: "waiting_start",
      starts_at: toISOString(dayjs()),
      is_unlimited: false,
    },
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(
      {
        submitter: { searchConfig: { submitText: "Создать" } },
      },
      defaultProps,
    );

    if (course) {
      defaultProps.request = async () => {
        return axios
          .post<OrionRestIndexResponse<PoolTemplate>>(
            `/api/pool-templates/search`,
            {
              filters: [
                {
                  field: "course_id",
                  operator: "=",
                  value: course.id,
                },
                {
                  field: "is_default",
                  operator: "=",
                  value: "true",
                },
              ],
            },
          )
          .then(({ data }) => {
            if (data.data.length > 1) {
              throw new Error(
                "Поддержка нескольких шаблонов потока по умолчанию в данный момент недоступна.",
              );
            } else if (data.data.length === 1) {
              const { value } = data.data[0];
              value.protocol_date = toISOString(dayjs());
              const initialValues = {
                course_id: course.id,
                name: `${course.name} от ${dayjs().format(dateSTime)}`,
                status: "waiting_start",
                starts_at: toISOString(dayjs()),
                is_unlimited: false,
              };

              return {
                ...value,
                ...initialValues,
              };
            } else {
              throw new Error("Не удалось получить шаблон потока по умолчанию");
            }
          })
          .catch((err) => {
            setError(err);
          });
      };
    }

    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Pool>>("/api/pools", values)
        .then((res) => {
          message.success("Поток успешно создан");
          rest.onAfterCreate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании потока",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      disabled: status === "completed",
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Pool>>(`/api/pools/${rest.recordKey}`)
        .then((res) => res.data.data)
        .then((pool) => {
          if (pool.status === "completed") {
            message.warning("Нельзя изменить поток, который завершен");
          }
          if (pool.status === "started") {
            message.warning(
              "Некоторые настройки нельзя изменить в запущенном потоке",
            );
          }

          return pool;
        });
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<Pool>>(
          `/api/pools/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Поток успешно обновлен");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении потока: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  if (error) throw error;

  /** Render */

  return (
    <ProForm<Pool> {...props}>
      <ProFormDigit label={"ID"} name={"id"} disabled hidden />
      <CourseSelect
        isActiveFilter
        label={"Курс"}
        name={"course_id"}
        disabled={rest.type === "update"}
        rules={[{ required: true }]}
        hidden={!!courseId}
      />
      <ProFormSelect
        options={[
          { value: "waiting_start", label: "Ожидает запуска" },
          { value: "started", label: "Запущен" },
          { value: "completed", label: "Завершен" },
        ]}
        label={"Статус"}
        name={"status"}
        disabled
        rules={[{ required: true }]}
        hidden
      />
      <ProFormText
        label="Название"
        name="name"
        rules={[{ required: true, max: 255 }]}
      />
      <ProFormText
        label={"Идентификатор потока"}
        name={"key"}
        hidden={!features.isEnabled("pools_keys")}
      />
      <DateTimePickerWithTimeControls
        label={"Дата и время начала"}
        name={"starts_at"}
        rules={[{ required: true }]}
        disabled={rest.type === "update" && status !== "waiting_start"}
        fieldProps={{
          format: dateSTime,
          showNow: false,
          showToday: false,
        }}
        normalize={dateSTimeNormalize}
      />
      <ProFormSwitch
        label={"Бессрочный"}
        name={"is_unlimited"}
        rules={[{ required: true }]}
        fieldProps={{
          onChange: (checked) => {
            if (checked) {
              form.setFieldValue("ends_at", null);
            }
          },
        }}
      />
      <DateTimePickerWithTimeControls
        label={"Дата и время завершения"}
        name={"ends_at"}
        rules={[{ required: !is_unlimited }]}
        hidden={is_unlimited}
        disabled={rest.type === "update" && status === "completed"}
        fieldProps={{
          format: dateSTime,
          showNow: false,
        }}
        normalize={dateSTimeNormalize}
      />
    </ProForm>
  );
};
export default PoolForm;
export type { PoolFormProps };
