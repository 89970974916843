import React from "react";
import { Link, useParams } from "react-router-dom";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { TestParticipant } from "@/models";
import { Flex, message } from "antd";
import { AxiosRequestConfig } from "axios";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import {
  ModalForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import dayjs from "dayjs";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import PoolSelect from "@/entities/pool/ui/select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";

type Record = TestParticipant;

const Page: React.FC = () => {
  const { test_id } = useParams();
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");

  const [someSelected, setSomeSelected] = React.useState(false);
  const [total, setTotal] = React.useState<number>();

  if (test_id === undefined) throw new Error("test_id is undefined");
  const batchUpdate = async (
    records: TestParticipant[],
    values: any,
    type: "append" | "rewrite",
  ) => {
    let resources: { [key: string]: TestParticipant } = {};

    records.forEach((item) => {
      if (type === "append") {
        item.test_snapshot.testing_attempts_limit =
          item.test_snapshot.testing_attempts_limit +
          values.testing_attempts_limit;
      } else {
        item.test_snapshot.testing_attempts_limit =
          values.testing_attempts_limit;
      }
      resources[item.id] = item;
    });

    return axios
      .patch(`/api/test-participants/batch`, { resources })
      .then(() => {
        message.success("У участника обновлено количество попыток");
        return true;
      })
      .catch((error) => {
        message.error("В время добавления произошла ошибка");
        console.error(error);
        return false;
      });
  };

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }} ref={ref}>
        <ProTable<Record>
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
          }}
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          columnsState={{
            persistenceKey: "test-report-table",
          }}
          saveFilters={{
            persistenceKey: "test-report-table",
          }}
          scroll={{
            y: parentHeight + (total ? 0 : 40) - (someSelected ? 247 : 187),
            x: 1200,
          }}
          request={async (params, sort, filter) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/test-participants/search",
              ...axiosConfigAdapter(params, sort, filter),
            };

            config.data.includes = [
              { relation: "latest_exam.pool_participant" },
              { relation: "latest_exam.pool_participant.pool" },
              { relation: "latest_exam.pool_participant.member" },
              { relation: "latest_exam" },
              { relation: "test" },
            ];

            config.data.aggregates = [
              {
                relation: "exams",
                type: "count",
                filters: [
                  {
                    field: "status",
                    operator: "in",
                    value: ["completed", "failed"],
                  },
                ],
              },
            ];

            config.data.filters.push({
              field: "latest_exam.status",
              operator: "not in",
              value: ["to_check", "checking"],
            });

            if (test_id) {
              config.data.filters.push({
                field: "test_id",
                operator: "=",
                value: test_id,
              });
            }

            const data = await axios
              .request<OrionRestIndexResponse<Record>>(config)
              .then((res) => res.data);

            setTotal(data.meta.total);

            return {
              data: data.data,
              success: true,
              total: data.meta.total,
            };
          }}
          rowKey={"id"}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSomeSelected(selectedRowKeys.length > 0);
            },
          }}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              hideInSearch: true,
            },
            {
              title: "ФИО",
              dataIndex: [
                "latest_exam",
                "pool_participant",
                "member",
                "full_name",
              ],
              hideInSetting: true,
              disable: true,
              render: (dom, record) => {
                const member_id =
                  record.latest_exam!.pool_participant!.member!.id;

                return <Link to={`/manage/members/${member_id}`}>{dom}</Link>;
              },
            },
            {
              title: "Поток",
              dataIndex: ["latest_exam", "pool_participant", "pool", "id"],
              renderText: (_, record) => {
                return <>{record.latest_exam!.pool_participant!.pool!.name}</>;
              },
              hideInSetting: true,
              disable: true,
              render: (dom, record) => {
                const course_id =
                  record.latest_exam!.pool_participant!.pool!.course_id;
                const pool_id = record.latest_exam!.pool_participant!.pool!.id;

                return (
                  <Link to={`/manage/courses/${course_id}/pools/${pool_id}`}>
                    {dom}
                  </Link>
                );
              },
              renderFormItem(_, config) {
                return <PoolSelect {...config} mode="multiple" />;
              },
            },
            {
              title: () => "Дата последней попытки",
              tooltip: true,
              dataIndex: ["latest_exam", "created_at"],
              sorter: true,
              formItemProps: {
                normalize: normalizeDateRange,
                name: ["range", "latest_exam", "created_at"],
                tooltip: false,
              },
              renderFormItem(_, config) {
                return (
                  <DateTimeRangePickerWithTimeControls
                    name={["range", "latest_exam", "created_at"]}
                    {...config}
                    fieldProps={{ allowEmpty: [true, true] }}
                  />
                );
              },
              render: (_, record) => {
                if (record.exams_count === 0) {
                  return "-";
                } else {
                  return dayjs(record.latest_exam?.created_at).format(
                    dateSTime,
                  );
                }
              },
            },
            {
              title: "Результат",
              dataIndex: ["latest_exam", "status"],
              valueType: "select",
              valueEnum: {
                not_started: { text: "Не начат" },
                launched: { text: "Запущен" },
                completed: { text: "Завершен" },
                failed: { text: "Провален" },
              },
              renderFormItem(_, config) {
                return <ProFormSelect {...config} mode="multiple" />;
              },
            },
            {
              title: () => "Использовано попыток",
              tooltip: true,
              dataIndex: ["exams_count"],
              hideInSearch: true,
            },
            {
              title: () => "Всего попыток",
              tooltip: true,
              dataIndex: ["test_snapshot", "testing_attempts_limit"],
              hideInSearch: true,
            },
            {
              title: "Действие",
              hideInSetting: true,
              disable: true,
              hideInTable: !member.permissions.includes(
                "test_participant:update",
              ),
              hideInSearch: true,
              render: (_, record, __, action) => {
                return (
                  <ModalForm
                    style={{ width: "100%" }}
                    modalProps={{
                      destroyOnClose: true,
                    }}
                    onFinish={(values) =>
                      batchUpdate([record], values, "rewrite").finally(() => {
                        action?.reload();
                      })
                    }
                    title={"Изменение попыток"}
                    trigger={
                      <Button
                        disabled={
                          !record.test_snapshot.is_limits_testing_attempts
                        }
                        type={"link"}
                      >
                        Изменить попытки
                      </Button>
                    }
                  >
                    <ProFormDigit
                      name="testing_attempts_limit"
                      label="Количество попыток"
                      rules={[{ required: true, max: 200, type: "number" }]}
                    />
                  </ModalForm>
                );
              },
            },
          ]}
          toolBarRender={(action, { selectedRows }) => {
            return [
              member.permissions.includes("test_participant:update") && (
                <ModalForm
                  modalProps={{
                    destroyOnClose: true,
                  }}
                  onFinish={(values) =>
                    batchUpdate(selectedRows!, values, "append").finally(() => {
                      action?.reload();
                    })
                  }
                  title={"Добавить попытки"}
                  trigger={
                    <Button
                      disabled={
                        selectedRows!.length === 0 ||
                        selectedRows!.length !==
                          selectedRows!.filter(
                            (value) =>
                              value.test_snapshot.is_limits_testing_attempts,
                          ).length
                      }
                      type={"primary"}
                    >
                      Добавить попытки
                    </Button>
                  }
                >
                  <ProFormDigit
                    name="testing_attempts_limit"
                    label="Количество попыток"
                    rules={[{ required: true, max: 200, type: "number" }]}
                  />
                </ModalForm>
              ),
            ];
          }}
          pagination={{ showSizeChanger: true }}
          hasQueryParams
        />
      </div>
    </Flex>
  );
};

export default Page;
