import React, { useEffect, useRef } from "react";
import { Scorm } from "@/models";
import { Button, Flex, message, Result, Tag, theme } from "antd";
import Spin from "@/shared/components/spin";
import useSWR from "swr";
import axios from "@/axios";
import "scorm-again";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useHeader from "@/entities/layout/lib/use-header";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import {
  LeftOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";

declare global {
  interface Window {
    API?: any;
    API_1484_11?: any;
  }
}

const Page: React.FC = ({}) => {
  const { pool_participant_id, scorm_id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = theme.useToken();
  const contextValue = useHeader();
  const divRef = useRef<
    HTMLDivElement & {
      mozRequestFullScreen?: () => void;
      webkitRequestFullscreen?: () => void;
      msRequestFullscreen?: () => void;
    }
  >(null);
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  useEffect(() => {
    contextValue!.setHidden(true);
    return () => {
      contextValue!.setHidden(false);
    };
  }, []);

  const navigateToCourse = () => {
    navigate(
      searchParams.get("pool_participant_url") ??
        `/education/${pool_participant_id}`,
    );
  };

  const {
    data: scorm,
    error: scormError,
    isLoading: scormIsLoading,
  } = useSWR(
    `/api/scorms/${scorm_id}`,
    (url) =>
      axios
        .get<OrionRestShowResponse<Scorm>>(url)
        .then(async (res) => res.data.data),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const {
    data: manifest,
    error,
    isLoading,
  } = useSWR(
    scorm ? `${scorm.url}/imsmanifest.xml` : null,
    async (url) => {
      return axios
        .get(url)
        .then(({ data }) => new DOMParser().parseFromString(data, "text/xml"));
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const [scormStatus, setScormStatus] = React.useState<
    "failed" | "completed" | undefined
  >(undefined);

  const onAfterSuccessFinish = () => {
    const educationMarkId = searchParams.get("education_mark_id");
    if (educationMarkId) {
      axios
        .put(`/api/education-marks/${educationMarkId}`, {
          value: "completed",
        })
        .then(() => {
          setSearchParams((prev) => {
            prev.set("education_mark_value", "completed");
            prev.delete("education_mark_id");
            return prev;
          });
          message.success("Вы успешно завершили SCORM");
        });
    }
  };

  useEffect(() => {
    if (scormStatus === "completed") {
      onAfterSuccessFinish();
    }
  }, [scormStatus]);

  useEffect(() => {
    const settings = {};

    if (manifest) {
      const scormsKey = `scorms:${scorm!
        .url!}/pool-participant:${pool_participant_id}`;
      const scormVersion =
        manifest.getElementsByTagName("schemaversion")[0]?.textContent;

      const suspendData = localStorage.getItem(scormsKey);

      if (scormVersion === "1.2") {
        //@ts-ignore
        window.API = new Scorm12API(settings);
        window.API.on("LMSSetValue.cmi.suspend_data", () => {
          localStorage.setItem(scormsKey, window.API.cmi.suspend_data);
        });

        if (suspendData) {
          window.API.loadFromJSON(
            {
              cmi: {
                suspend_data: suspendData,
              },
            },
            "",
          );
        }

        window.API.on("LMSSetValue.cmi.lesson_status", () => {
          if (
            (window.API.cmi.lesson_status === "completed" ||
              window.API.cmi.lesson_status === "passed") &&
            scormStatus !== "completed"
          ) {
            setScormStatus("completed");
          } else if (window.API.cmi.lesson_status === "failed") {
            setScormStatus("failed");
          }
        });
      } else {
        //@ts-ignore
        window.API_1484_11 = new Scorm2004API(settings);

        window.API_1484_11.on("SetValue.cmi.suspend_data", () => {
          localStorage.setItem(scormsKey, window.API_1484_11.cmi.suspend_data);
        });

        if (suspendData) {
          window.API_1484_11.loadFromJSON(
            {
              cmi: {
                suspend_data: suspendData,
              },
            },
            "",
          );
        }

        window.API_1484_11.on("SetValue.cmi.completion_status", () => {
          if (
            ((window.API_1484_11.cmi.completion_status === "completed" &&
              window.API_1484_11.cmi.success_status !== "failed") ||
              window.API_1484_11.cmi.success_status === "passed") &&
            scormStatus !== "completed"
          ) {
            setScormStatus("completed");
          } else if (window.API_1484_11.cmi.success_status === "failed") {
            setScormStatus("failed");
          }
        });
      }
    }

    return () => {
      if (window.API) {
        window.API = null;
      }
      if (window.API_1484_11) {
        window.API_1484_11 = null;
      }
    };
  }, [manifest]);

  const toggleFullscreen = () => {
    if (divRef.current) {
      if (!isFullscreen) {
        if (divRef.current.requestFullscreen) {
          divRef.current.requestFullscreen();
        } else if (divRef.current.mozRequestFullScreen) {
          divRef.current.mozRequestFullScreen();
        } else if (divRef.current.webkitRequestFullscreen) {
          divRef.current.webkitRequestFullscreen();
        } else if (divRef.current.msRequestFullscreen) {
          divRef.current.msRequestFullscreen();
        } else {
          message.warning("Ваш браузер не поддерживает полноэкранный режим");
          return;
        }
        setIsFullscreen(true);
      } else {
        const crossBrowserDocument = document as Document & {
          mozCancelFullScreen?: () => Promise<void>;
          webkitExitFullscreen?: () => Promise<void>;
          msExitFullscreen?: () => Promise<void>;
        };

        if (crossBrowserDocument.exitFullscreen) {
          crossBrowserDocument.exitFullscreen();
        } else if (crossBrowserDocument.mozCancelFullScreen) {
          crossBrowserDocument.mozCancelFullScreen();
        } else if (crossBrowserDocument.webkitExitFullscreen) {
          crossBrowserDocument.webkitExitFullscreen();
        } else if (crossBrowserDocument.msExitFullscreen) {
          crossBrowserDocument.msExitFullscreen();
        }
        setIsFullscreen(false);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange,
      );
    };
  }, []);

  if (scormIsLoading || isLoading) return <Spin />;
  if (scormError || error) throw error;
  if (!scorm) throw new Error("Не удалось загрузить SCORM");
  if (!manifest) throw new Error("Не удалось загрузить содержимое пакета");

  switch (scorm.status) {
    case "pending":
      return (
        <Result
          status={"warning"}
          title={"Пакет еще не загружен"}
          subTitle={"Пожалуйста, обратитесь к администратору"}
        />
      );
    case "active":
      break;
    default:
      return (
        <Result
          status={"error"}
          title={"Пакет не активен"}
          subTitle={"Пожалуйста, дождитесь активации пакета"}
        />
      );
  }

  const href = manifest
    .getElementsByTagName("resource")[0]
    .getAttribute("href");

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        zIndex: 999,
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <Flex
        justify="space-between"
        align="center"
        style={{ width: "100%", backgroundColor: token.colorBgContainer }}
      >
        <Button type="link" onClick={() => navigateToCourse()}>
          <LeftOutlined />
          Вернуться к курсу
        </Button>
        <Flex align="center" gap={8}>
          {searchParams.get("education_mark_value") === "completed" && (
            <Tag bordered={false} color={"success"}>
              Пройден
            </Tag>
          )}
          <Button
            type="link"
            icon={<FullscreenOutlined />}
            onClick={toggleFullscreen}
          />
        </Flex>
      </Flex>
      <div
        ref={divRef}
        style={{
          width: "100%",
          height: "calc(100% - 32px)",
        }}
      >
        {isFullscreen && (
          <Flex
            justify="end"
            align="center"
            style={{ width: "100%", backgroundColor: token.colorBgContainer }}
          >
            <Button
              type="link"
              icon={<FullscreenExitOutlined />}
              onClick={toggleFullscreen}
            />
          </Flex>
        )}
        <iframe
          loading="eager"
          src={`${scorm.url}/${href}`}
          style={{
            width: "100%",
            height: `calc(100% - ${isFullscreen ? 32 : 0}px)`,
            border: "none",
          }}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          sandbox={
            "allow-scripts allow-same-origin allow-popups allow-forms allow-downloads"
          }
        />
      </div>
    </div>
  );
};

export default Page;
