import React, { useState } from "react";
import { Pdf } from "@/models";
import { ProForm, ProFormProps } from "@ant-design/pro-components";
import { Flex, message, Spin } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import useMe from "@/entities/me/lib/use";
import { PdfFormFields, FormData } from "@/entities/pdf";
import { useUpdatePdfFiles } from "../lib/use-update-pdf-files";
import PdfDeleteButton from "./delete-button";
import { usePdfsResource } from "@/entities/pdf";

type Props = ProFormProps<FormData> & {
  rest: RestProps<Pdf>;
  onDelete?: () => void;
};

const PdfForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updatePdfFiles } = useUpdatePdfFiles();
  const {
    deleteBatchPdfAttachmentFiles,
    getPdfAttachmentFiles,
    getPdf,
    updatePdf,
  } = usePdfsResource();

  const [form] = ProForm.useForm<FormData>();

  const [toRemoveFileIds, setToRemoveFileIds] = useState<number[]>([]);
  const member = useMe();

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    const request = async () => {
      return getPdf(Number(rest.recordKey)).then(async (data) => {
        const attachmentFiles = await getPdfAttachmentFiles(data.id)
          .then((files) =>
            files.map((file) => ({
              ...file.file,
              status: "done",
              pdfFileId: file.id,
            })),
          )
          .catch((err) => {
            message.error(
              err.response.data.message ?? "Ошибка загрузки файлов",
            );
            throw err;
          });

        return {
          ...data,
          attachment_files: attachmentFiles,
          content_files: data.content_file
            ? [{ ...data.content_file, status: "done" }]
            : [],
        } as FormData;
      });
    };

    defaultProps.request = async () => {
      setIsLoading(true);
      return request().finally(() => setIsLoading(false));
    };

    defaultProps.onFinish = async (values) => {
      setIsLoading(true);
      return updatePdf(Number(rest.recordKey), values)
        .then(async (lesson) => {
          await updatePdfFiles(values).then(async () => {
            if (toRemoveFileIds.length > 0) {
              await deleteBatchPdfAttachmentFiles(toRemoveFileIds).then(() => {
                setToRemoveFileIds([]);
              });
            }
          });
          rest.onAfterUpdate?.(lesson);
          await request().then((res) => form.setFieldsValue(res as any));
          message.success("Pdf успешно обновлен");
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении pdf: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const onRemove = (file: any) => {
    if (file.id) setToRemoveFileIds((prev) => [...prev, file.pdfFileId]);
  };

  return (
    <Spin spinning={isLoading}>
      <ProForm<FormData> {...props}>
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          {member.permissions.includes("course:update") && (
            <>
              <PdfDeleteButton
                pdfId={Number(props.id)}
                onDelete={onDeleteProp}
              />
              <Button
                loading={isLoading}
                type={"primary"}
                onClick={form.submit}
              >
                Сохранить
              </Button>
            </>
          )}
        </Flex>
        <PdfFormFields onRemoveFiles={onRemove} />
      </ProForm>
    </Spin>
  );
};

export default PdfForm;
