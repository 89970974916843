import React from "react";
import { Flex, Space, theme, Typography } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import {
  ProDescriptions,
  ProList,
  ProListProps,
} from "@ant-design/pro-components";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axios from "@/axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { Commission } from "@/models";
import CommissionModalForm from "@/entities/commission/ui/modal-form";
import { Link } from "react-router-dom";
import { MemberSelect } from "@/features/member";
import styled from "styled-components";
import { CourseSelect } from "@/features/course";
import useMe from "@/entities/me/lib/use";
import CommissionDeleteButton from "@/entities/commission/ui/delete-button";

const StyledProList = styled(ProList)<ProListProps<Commission>>`
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;

  .ant-pro-card:last-child {
    flex-grow: 1;
    overflow: hidden;
  }
  .ant-pro-card-body {
    display: flex !important;
    flex-direction: column;
  }
  .ant-list.ant-list-vertical {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .ant-spin-nested-loading {
    flex-grow: 1;
    overflow: auto;
  }
`;

const Page: React.FC = () => {
  const { token } = theme.useToken();
  const member = useMe();
  const maxTitleWidth = 190;

  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        style={{ width: "100%", height: "calc(100% - 30px)" }}
        styles={{ item: { height: "100%", width: "100%" } }}
      >
        <StyledProList
          style={{ height: "100%" }}
          search={{
            layout: "vertical",
            defaultColsNumber: 6,
          }}
          rowSelection={{}}
          pagination={{ defaultPageSize: 10 }}
          request={async (params) => {
            const { current, pageSize, ...restParams } = params;
            Object.entries(restParams).forEach(([key, value]) => {
              if (
                value === undefined ||
                (Array.isArray(value) && value.length === 0)
              ) {
                delete restParams[key];
              }
            });
            const actionConfig: AxiosRequestConfig = {
              method: "POST",
              url: "/api/commissions/search",
              ...axiosConfigAdapter(
                {
                  current,
                  pageSize,
                },
                {},
                restParams,
              ),
            };

            actionConfig.data.includes.push({
              relation: "members.member",
            });
            actionConfig.data.includes.push({
              relation: "course_commissions.course",
            });

            const data = await axios
              .request<OrionRestIndexResponse<Commission>>(actionConfig)
              .then((res) => res.data);

            return {
              data: data.data,
              success: true,
              total: data.meta.total,
            };
          }}
          toolBarRender={(action, { selectedRowKeys }) => {
            if (!selectedRowKeys)
              throw new Error("selectedRowKeys is undefined");
            if (!action) throw new Error("action is undefined");

            return [
              member.permissions.includes("commission:delete") && (
                <CommissionDeleteButton
                  recordKeys={selectedRowKeys.map(Number)}
                  afterRemove={() => {
                    action.reload();
                    action.clearSelected?.();
                  }}
                />
              ),
              member.permissions.includes("commission:create") && (
                <CommissionModalForm
                  rest={{
                    type: "create",
                    onAfterCreate: () => action.reload(),
                  }}
                />
              ),
            ];
          }}
          rowKey={"id"}
          itemLayout="vertical"
          metas={{
            title: {
              dataIndex: "name",
              title: "Название",
              render: (_, { name, id }) => (
                <Link
                  to={`/manage/commissions/${id}`}
                  style={{ color: token.colorTextHeading }}
                >
                  {name}
                </Link>
              ),
            },
            members: {
              dataIndex: "members.member_id",
              title: "Участники",
              renderFormItem: (_: any, config: any) => {
                return (
                  <MemberSelect
                    whereHaveInCommissions
                    {...config}
                    mode="multiple"
                  />
                );
              },
            },
            courses: {
              dataIndex: "course_commissions.course_id",
              title: "Курсы",
              renderFormItem: (_: any, config: any) => {
                return (
                  <CourseSelect
                    whereHaveInCommissions
                    {...config}
                    mode="multiple"
                    label=""
                  />
                );
              },
            },

            description: {
              search: false,
              render: (_, record) => {
                return (
                  <ProDescriptions<Commission>
                    column={1}
                    size={"small"}
                    layout="horizontal"
                    labelStyle={{ width: maxTitleWidth }}
                    dataSource={record}
                    columns={[
                      {
                        style: { padding: 0 },
                        title: (
                          <Typography.Text type="secondary">
                            Учредительный документ
                          </Typography.Text>
                        ),
                        render: (
                          _,
                          {
                            establishment_document_name,
                            establishment_document_number,
                            company_head_position,
                            company_head_full_name,
                            company_name,
                          },
                        ) => {
                          return (
                            <Typography.Text type="secondary">
                              {establishment_document_name} №
                              {establishment_document_number} , учредитель –{" "}
                              {company_head_position}, {company_head_full_name},{" "}
                              {company_name}
                            </Typography.Text>
                          );
                        },
                      },
                      {
                        title: (
                          <Typography.Text type="secondary">
                            Участники
                          </Typography.Text>
                        ),
                        dataIndex: "members",
                        render: (_, { members }) => {
                          const membersNode = members?.map(
                            (commissionMember, index) => {
                              return (
                                <React.Fragment key={commissionMember.id}>
                                  {index > 0 && ", "}
                                  <Link
                                    to={`/manage/members/${commissionMember.member_id}`}
                                  >
                                    {commissionMember.member?.full_name}
                                  </Link>
                                </React.Fragment>
                              );
                            },
                          );

                          return (
                            <Typography.Text>{membersNode}</Typography.Text>
                          );
                        },
                      },
                      {
                        title: (
                          <Typography.Text type="secondary">
                            Курсы
                          </Typography.Text>
                        ),
                        dataIndex: "courses",
                        render: (_, { course_commissions }) => {
                          const courseNodes = course_commissions?.map(
                            (course, index) => {
                              return (
                                <React.Fragment key={course.id}>
                                  {index > 0 && ", "}
                                  <Link
                                    to={`/manage/courses/${course.course_id}`}
                                  >
                                    {course.course?.name}
                                  </Link>
                                </React.Fragment>
                              );
                            },
                          );

                          return (
                            <Typography.Text>{courseNodes}</Typography.Text>
                          );
                        },
                      },
                    ]}
                  />
                );
              },
            },
          }}
        />
      </Space>
    </Flex>
  );
};

export default Page;
