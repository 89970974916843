import React from "react";
import { Image, Typography } from "antd";
import { File as FileModel, Pdf } from "@/models";
import PreviewPdf from "./preview-pdf";
import PreviewVideo from "./preview-video";

type FilePreviewProps = {
  file: Pick<FileModel, "type"> & {
    url: string;
  };
  displayType?: Pdf["display_type"];
};

export const FilePreview: React.FC<FilePreviewProps> = ({
  file: { url, type },
  displayType,
}) => {
  switch (type.split("/")[1]) {
    case "pdf":
      return <PreviewPdf displayType={displayType} src={url} />;
  }

  switch (type.split("/")[0]) {
    case "video":
      return <PreviewVideo src={url} />;
    case "image":
      return <Image src={url} preview={false} />;
    default:
      return (
        <Typography.Text>
          Невозможно отобразить данный тип файла
        </Typography.Text>
      );
  }
};

export type { FilePreviewProps };
