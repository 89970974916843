import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { PoolParticipant } from "@/models";
import { debounce } from "lodash";
import { BaseOptionType } from "antd/es/cascader";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSWR from "swr";
import { Skeleton } from "antd";
import useMe from "@/entities/me/lib/use";
import { removeDuplicates } from "@/shared/helpers/remove-duplicates";
import { toISOString } from "@/shared/dayjs/lib/formats";
import dayjs from "dayjs";

type MemberEducationCoursesSelectProps = Partial<ProFormSelectProps>;

export const MemberEducationCoursesSelect: React.FC<
  MemberEducationCoursesSelectProps
> = ({ fieldProps, ...selectProps }) => {
  const form = ProForm.useFormInstance();
  const me = useMe();

  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState<any>();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(selectProps.name));
  }, []);

  const {
    data: courses,
    isLoading,
    error,
  } = useSWR(
    ["/api/pool-participants/search", searchValue, initialValue],
    async ([url]) => {
      const filters = [];

      filters.push({
        field: "pool.course.name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      const now = toISOString(dayjs());

      filters.push(
        { field: "member_id", operator: "=", value: me.id },
        { field: "pool.status", operator: "=", value: "started" },
        { field: "status", operator: "!=", value: "failed" },
        {
          type: "and",
          nested: [
            { field: "ends_at", operator: ">", value: now, type: "or" },
            {
              field: "is_unlimited",
              operator: "=",
              value: true,
              type: "or",
            },
          ],
        },
        { field: "starts_at", operator: "<", value: now },
      );

      const res = await axios
        .post<{ data: PoolParticipant[] }>(url, {
          filters,
          sort: [{ field: "created_at", direction: "desc" }],
          includes: [{ relation: "pool.course" }],
        })
        .then(async (res) => {
          const data: BaseOptionType[] = [];

          res.data.data.forEach((poolParticipant) => {
            data.push({
              label: poolParticipant.pool!.course!.name,
              value: poolParticipant.pool!.course!.id,
            });
          });

          if (initialValue && typeof initialValue === "object") {
            const missingValues: number[] = (
              Array.isArray(initialValue)
                ? initialValue
                : // @ts-ignore
                  selectProps.value ?? []
            ).filter(
              (value: any) => !data.some((option) => option.value === value),
            );

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<PoolParticipant>>(
                  "/api/pool-participants/search",
                  {
                    filters: [
                      {
                        type: "and",
                        nested: missingValues.map((value) => ({
                          type: "or",
                          field: "id",
                          operator: "=",
                          value,
                        })),
                      },
                    ],
                    includes: [{ relation: "pool.course" }],
                  },
                )
                .then((res) =>
                  res.data.data.map((poolParticipant) => ({
                    label: poolParticipant.pool!.course!.name,
                    value: poolParticipant.pool!.course!.id,
                  })),
                );

              data.push(...missingData);
            }
          }

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((option) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/pool-participants/${initialValue}?include=pool.course`)
              .then(({ data }) => ({
                label: data.data.pool!.course!.name,
                value: data.data.pool!.course!.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return removeDuplicates(res, "value");
    },
  );
  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  return (
    <ProFormSelect
      options={courses}
      showSearch
      label={"Курсы"}
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      {...selectProps}
    >
      {isFirstRender && isLoading && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export type { MemberEducationCoursesSelectProps };
