import React from "react";
import { CoursesTable } from "@/widgets/course";
import { Link, useNavigate } from "react-router-dom";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useMe from "@/entities/me/lib/use";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const member = useMe();

  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }}>
        <CoursesTable
          hasQueryParams
          saveFilters={{
            persistenceKey: "course-table",
          }}
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          columnsState={{
            persistenceKey: "course-table",
          }}
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
          }}
          columns={[
            {
              dataIndex: "name",
              render: (dom, { id: course_id }) => {
                if (member.permissions.includes("course:view")) {
                  return <Link to={`/manage/courses/${course_id}`}>{dom}</Link>;
                }
                return dom;
              },
              hideInSetting: true,
              disable: true,
            },
            {
              dataIndex: "status",
            },
            { dataIndex: "key" },
            { dataIndex: "description" },
            { dataIndex: "created_at" },
          ]}
          rest={{
            onAfterCreate: (course) => {
              navigate(`/manage/courses/${course.id}?tab=embeds`);
            },
          }}
        />
      </div>
    </Flex>
  );
};

export default Page;
