import React from "react";
import { Group } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { Typography } from "antd";
import GroupModalForm from "@/entities/group/ui/modal-form";
import GroupDeleteButton from "@/entities/group/ui/delete-button";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { MemberEducationPurposeModalForm } from "@/features/member";
import GroupParticipantForm from "@/entities/group-participant/ui/form-batch";
import { RestCallbacks } from "@/shared/rest/lib/types";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { ProFormDigitRange } from "@ant-design/pro-components";

type Data = Group;

type Params = Partial<Group>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<Group>;
};

const GroupTable: React.FC<Props> = ({ rest, ...props }) => {
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");

  const [someSelected, setSomeSelected] = React.useState(false);
  const [total, setTotal] = React.useState<number>();

  const request: TableProps["request"] = async (params, sort, filter) => {
    if (!Object.keys(sort).length) {
      sort = { created_at: "descend" };
    }

    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/groups/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    actionConfig.data.includes.push({
      relation: "participants",
    });

    const { data } = await axios.request<OrionRestIndexResponse<Group>>(
      actionConfig,
    );

    setTotal(data.meta.total);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const toolBarRender: TableProps["toolBarRender"] = (
    action,
    { selectedRowKeys, selectedRows },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
    if (!selectedRows) throw new Error("selectedRows is undefined");
    if (!action) throw new Error("action is undefined");

    const membersIds = selectedRows.flatMap((item) =>
      item.participants.map((participant) => participant.member_id),
    );

    const membersIdsWithoutDuplicates = membersIds.filter(
      (value, index, self) => self.indexOf(value) === index,
    );

    return [
      (member.permissions.includes("member:update") ||
        member.permissions.includes("member:create")) && (
        <GroupDeleteButton
          key={"delete"}
          recordKeys={selectedRowKeys.map(Number)}
          afterRemove={() => {
            action.reload();
            action.clearSelected?.();
          }}
        />
      ),
      (member.permissions.includes("member:update") ||
        member.permissions.includes("member:create")) && (
        <GroupModalForm
          key={`update-${selectedRowKeys.join("-")}`}
          rest={{
            type: "update",
            recordKey: selectedRowKeys,
            onAfterUpdate: () => {
              action.reload();
            },
          }}
        />
      ),
      (member.permissions.includes("member:update") ||
        member.permissions.includes("member:create")) && (
        <GroupModalForm
          key={"create"}
          rest={{
            type: "create",
            onAfterCreate: (record) => {
              rest?.onAfterCreate?.(record);
            },
          }}
        />
      ),
      member.permissions.includes("member:update") && (
        <GroupParticipantForm
          key="addMembers"
          modal
          groupId={selectedRowKeys.map(Number)}
          onAfterAdd={() => action.reload()}
        />
      ),
      member.permissions.includes("pool_participant:create") && (
        <MemberEducationPurposeModalForm
          key="education-purpose"
          membersIds={membersIdsWithoutDuplicates}
        />
      ),
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {
      preserveSelectedRowKeys: true,
      onChange: (selectedRowKeys) => {
        setSomeSelected(selectedRowKeys.length > 0);
      },
    },
    options: false,
    pagination: { showSizeChanger: true },
    request,
    scroll: { y: parentHeight + (total ? 0 : 40) - (someSelected ? 247 : 187) },
    toolBarRender,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Описание",
        dataIndex: "description",
        hideInSearch: true,
        render: (_, record) => (
          <Typography.Text>{record.description}</Typography.Text>
        ),
      },
      {
        title: () => "Количество участников",
        tooltip: true,
        dataIndex: "participants_count",
        formItemProps: {
          name: ["range", "participants_count"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDigitRange
              {...config}
              fieldProps={{ min: 0 }}
              separator="-"
              placeholder={["от", "до"]}
            />
          );
        },
        sorter: true,
      },
    ],
  };

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <ProTable<Data, Params>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </div>
  );
};

export default GroupTable;
