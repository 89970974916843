import axios from "@/axios";
import { CommissionSelect } from "@/entities/commission/ui/select";
import { useExport } from "@/entities/export/lib/use-export";
import { Commission, Course, Export, Pool } from "@/models";
import { date, dateNormalize } from "@/shared/dayjs/lib/formats";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import {
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormProps,
  ProFormText,
} from "@ant-design/pro-components";
import { Col, Flex, message, Modal, Row } from "antd";

type PoolPartocopantExportType = Extract<
  Export["type"],
  "export_command" | "export_protocol" | "export_for_registry"
>;

type Record = {
  commission_id: Commission["id"];
  course_id: Course["id"];
  pool_id: Pool["id"];
  type: PoolPartocopantExportType;
};

const usePoolParticipantExport = () => {
  const [exportForm] = ProForm.useForm<Record>();
  const [modal, contextHolder] = Modal.useModal();
  const { messageContextHolder, modalContextHolder, exportEntity } =
    useExport();

  const onFinish: ProFormProps<Record>["onFinish"] = (values) => {
    Modal.destroyAll();
    const { type, ...rest } = values;
    exportEntity(values.type, { ...rest });
    return Promise.resolve();
  };

  const exportDocument = async (
    type: PoolPartocopantExportType,
    title: string,
    courseId: Course["id"],
    poolId: Pool["id"],
  ) => {
    const commissions = await axios
      .post<OrionRestIndexResponse<Commission>>(`/api/commissions/search`, {
        filters: [
          {
            field: "course_commissions.course_id",
            operator: "=",
            value: courseId,
          },
        ],
      })
      .then((res) => res.data.data)
      .catch((err) => {
        message.error(
          err.response.data.message ?? "Ошибка при получении комиссий",
        );
        throw err;
      });

    if (commissions.length === 1) {
      exportForm.setFieldsValue({
        commission_id: commissions[0].id,
        pool_id: poolId,
        type,
      });
    }
    if (commissions.length === 1 && type === "export_command") {
      exportEntity(type, { commission_id: commissions[0].id, pool_id: poolId });
      return;
    }
    if (commissions.length) {
      modal.confirm({
        width: 600,
        title,
        destroyOnClose: true,
        onCancel: () => {
          exportForm.resetFields();
        },
        onClose: () => {
          exportForm.resetFields();
        },
        content: (
          <ProForm
            form={exportForm}
            onFinish={onFinish}
            submitter={{
              resetButtonProps: false,
              searchConfig: { submitText: "Экспортировать" },
              render: (_, dom) => <Flex justify={"end"}>{dom}</Flex>,
            }}
            preserve={false}
            initialValues={{
              pool_id: poolId,
              commission_id:
                commissions.length === 1 ? commissions[0].id : null,
              type,
            }}
          >
            <CommissionSelect
              rules={[{ required: true }]}
              label={"Комиссия"}
              courseId={courseId}
              name={"commission_id"}
              hidden={commissions.length === 1}
            />
            <ProFormDigit name={"pool_id"} hidden />
            <ProFormText name={"type"} hidden />
            {type !== "export_command" && (
              <ProForm.Group
                title={"Протокол"}
                spaceProps={{
                  style: { width: "100%" },
                  styles: { item: { width: "100%" } },
                }}
              >
                <Row>
                  <Col span={12}>
                    <ProFormDatePicker
                      label={"Дата в протоколе от"}
                      name={"protocol_date"}
                      rules={[{ required: true, max: 255 }]}
                      fieldProps={{
                        format: date,
                        needConfirm: false,
                      }}
                      normalize={dateNormalize}
                      width={"sm"}
                    />
                  </Col>
                  <Col span={12}>
                    <ProFormText
                      label={"Номер Протокола"}
                      name={"protocol_code"}
                      rules={[{ required: true, max: 255 }]}
                    />
                  </Col>
                </Row>
              </ProForm.Group>
            )}
          </ProForm>
        ),
        closable: true,
        icon: null,
        footer: null,
      });
    } else {
      message.error(
        "Нет доступных комиссий для экспорта. Перейдите в настройки курса и выберите комиссию",
      );
    }
  };

  return {
    exportDocument,
    contextHolder,
    messageContextHolder,
    modalContextHolder,
  };
};

export default usePoolParticipantExport;
