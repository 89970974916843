import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Pool } from "@/models";
import useSWR from "swr";
import { debounce } from "lodash";
import { Skeleton } from "antd";
import { BaseOptionType } from "antd/es/cascader";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";

type SelectProps = ProFormSelectProps;

type PoolSelectProps = SelectProps & {
  courseId?: number;
  listName?: SelectProps["name"];
  notCompletedFilter?: boolean;
};

const PoolSelect: React.FC<PoolSelectProps> = ({
  courseId,
  listName,
  notCompletedFilter,
  fieldProps,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();
  const [initialValue, setInitialValue] = React.useState();

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const [searchValue, setSearchValue] = React.useState("");
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  const {
    data: pools,
    isLoading,
    error,
  } = useSWR(
    [
      "/api/pools/search",
      searchValue,
      courseId,
      initialValue,
      notCompletedFilter,
    ],
    async ([url]) => {
      const filters: any = [];
      if (courseId) {
        filters.push({
          field: "course_id",
          operator: "=",
          value: courseId,
        });
      }

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      if (notCompletedFilter) {
        filters.push({
          field: "status",
          operator: "!=",
          value: "completed",
        });
      }

      const res = await axios
        .post<{ data: Pool[] }>(url, {
          filters,
          sort: [{ field: "created_at", direction: "desc" }],
        })
        .then(async (res) => {
          const data: BaseOptionType[] = [];
          res.data.data.forEach((pool) => {
            data.push({
              label: pool.name,
              value: pool.id,
            });
          });

          if (initialValue && typeof initialValue === "object") {
            const missingValues: number[] = (
              Array.isArray(initialValue)
                ? initialValue
                : // @ts-ignore
                  selectProps.value ?? []
            ).filter(
              (value: any) => !data.some((option) => option.value === value),
            );

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<Pool>>("/api/pools/search", {
                  filters: [
                    {
                      type: "and",
                      nested: missingValues.map((value) => ({
                        type: "or",
                        field: "id",
                        operator: "=",
                        value,
                      })),
                    },
                  ],
                })
                .then((res) =>
                  res.data.data.map((pool) => ({
                    label: pool.name,
                    value: pool.id,
                  })),
                );

              data.push(...missingData);
            }
          }

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((obj: any) => obj.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/pools/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: data.data.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  return (
    <ProFormSelect
      showSearch
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      options={pools}
      {...selectProps}
    >
      {isFirstRender && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export default PoolSelect;
