import React, { useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, message, Spin, theme } from "antd";
import NotificationList, { SWRHook } from "@/entities/notification/ui/list";
import useSWR from "swr";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useMe from "@/entities/me/lib/use";

const NotificationDrawer: React.FC = () => {
  const member = useMe();
  const { token } = theme.useToken();
  const swrRef = React.useRef<SWRHook>(null);

  const {
    data: badgeCount,
    isLoading,
    error,
    mutate,
  } = useSWR(["/api/notifications/search", "unread-count"], ([url]) =>
    axios
      .post<OrionRestIndexResponse<Notification>>(url, {
        filters: [
          {
            field: "is_read",
            operator: "=",
            value: false,
          },
          {
            field: "member_id",
            operator: "=",
            value: member.id,
          },
        ],
      })
      .then((res) => res.data.meta.total),
  );

  const mutateBangeCount = () => {
    mutate();
  };

  const onReadAll = async () => {
    return await axios
      .patch(`/api/notifications/batch-by-filter`, {
        filters: [
          {
            field: "is_read",
            operator: "=",
            value: false,
          },
          {
            field: "member_id",
            operator: "=",
            value: member.id,
          },
        ],
        attributes: {
          is_read: true,
        },
      })
      .then(() => {
        mutate();
        swrRef.current?.mutate();
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "Что-то пошло не так");
        throw error;
      });
  };

  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (badgeCount === undefined) throw new Error("No data");

  return (
    <>
      <Badge count={badgeCount}>
        <Button
          type={"text"}
          onClick={showDrawer}
          icon={<BellOutlined style={{ fontSize: token.fontSizeXL }} />}
        />
      </Badge>
      <Drawer
        title={"Список уведомлений"}
        extra={
          <Button type="link" style={{ paddingRight: 0 }} onClick={onReadAll}>
            Прочитать все
          </Button>
        }
        onClose={onClose}
        open={open}
      >
        <NotificationList swrRef={swrRef} onCheckMarked={mutateBangeCount} />
      </Drawer>
    </>
  );
};

export default NotificationDrawer;
