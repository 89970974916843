import React from "react";
import { Group, Member } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { theme, Tooltip, Typography } from "antd";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import { useTranslation } from "react-i18next";
import useFeatures from "@/entities/features/lib/use";
import { getTreeNodeNames } from "@/entities/member/lib/get-tree-node-names";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import styled from "styled-components";
import { createPositionFilter } from "@/entities/member";

type MemberSelectFormItemTableProps = {
  groupIds?: Group["id"][];
  onChange?: (selectedRowKeys: React.Key[]) => void;
};

const TableSearchFormStyled = styled.div`
  .ant-form.ant-form-vertical {
    padding-inline: 0 !important;
    padding-block: 4px !important;
  }

  .ant-pro-card.ant-pro-table-search {
    background: none !important;
  }
`;

export const MemberSelectTable: React.FC<MemberSelectFormItemTableProps> = ({
  groupIds,
  ...props
}) => {
  const { t } = useTranslation();
  const features = useFeatures();
  const { token } = theme.useToken();

  return (
    <ProTable<Member>
      ErrorBoundary={false}
      rowKey="id"
      scroll={{ y: 400 }}
      toolBarRender={false}
      pagination={{
        style: {
          paddingBlockEnd: token.sizeSM,
        },
      }}
      searchFormRender={(_, defaultDom) => (
        <TableSearchFormStyled>{defaultDom}</TableSearchFormStyled>
      )}
      request={async (params, sort, filters) => {
        const { ["positions,position_id"]: positionIds, ...filter } = filters;

        const config: AxiosRequestConfig = {
          method: "POST",
          url: "/api/members/search",
          ...axiosConfigAdapter(params, sort, filter),
        };

        if (groupIds && groupIds.length === 1) {
          config.data.scopes.push({
            name: "whereDoesntHaveInGroup",
            parameters: groupIds,
          });
        }

        config.data.includes.push({
          relation: "positions.position.tree_node.reverse_tree.is_an",
        });

        if (positionIds) {
          config.data.filters.push(
            createPositionFilter(positionIds, "positions.position_id"),
          );
        }

        return axios
          .request<OrionRestIndexResponse<Member>>(config)
          .then((res) => {
            return {
              data: res.data.data,
              success: true,
              total: res.data.meta.total,
            };
          });
      }}
      tableAlertRender={false}
      columns={[
        {
          dataIndex: "full_name",
          title: "ФИО",
        },
        {
          title: t("Подразделение / Должность"),
          order: features.isEnabled("restrict_accesses") ? 1 : undefined,
          dataIndex: ["positions", "position_id"],
          renderText: (_, { positions }) => {
            if (positions && positions.length === 0) {
              return "-";
            }

            const positionNames = positions?.map(({ position }) => {
              const name = position?.name;
              // @ts-ignore
              const treeNodeName = getTreeNodeNames(position?.tree_node);

              return name
                ? `${treeNodeName ? treeNodeName + ", " : ""}${name}`
                : "-";
            });

            return (
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={positionNames?.join(";\n")}
              >
                <Typography.Text
                  ellipsis
                  style={{
                    maxWidth: "300px",
                    width: "100%",
                  }}
                >
                  {positionNames?.join("; ")}
                </Typography.Text>
              </Tooltip>
            );
          },

          renderFormItem(_, config) {
            return (
              <OrgStructureTreeSelect
                fieldProps={{ multiple: true }}
                labelWithPath
                searchUser
                {...config}
              />
            );
          },
        },
        {
          title: () => "Дата добавления",
          tooltip: true,
          dataIndex: "created_at",
          valueType: "dateTime",
          fieldProps: { format: dateSTime },
          sorter: true,
          width: 200,
          formItemProps: {
            normalize: normalizeDateRange,
            tooltip: false,
            name: ["range", "created_at"],
          },
          renderFormItem(_, config) {
            return (
              <DateTimeRangePickerWithTimeControls
                name={["range", "created_at"]}
                {...config}
                fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
              />
            );
          },
        },
      ]}
      rowSelection={{
        preserveSelectedRowKeys: true,
        onChange: (_selectedRowKeys) => {
          props.onChange?.(_selectedRowKeys);
        },
      }}
    />
  );
};
