import { useFilesResource } from "@/entities/file";
import { FormData } from "@/entities/pdf";
import { usePdfsResource } from "@/entities/pdf";
import { message } from "antd";
import { useCallback } from "react";

export const useUpdatePdfFiles = () => {
  const { createBatchFiles, createFile } = useFilesResource();
  const { updateBatchPdfAttachmentFiles, updatePdf } = usePdfsResource();

  const updatePdfFiles = useCallback(async (formData: FormData) => {
    const resources = (formData.attachment_files ?? [])
      .filter((file) => !file.id)
      .map((file) => ({
        key: file.response.key,
        name: file.name,
        type: file.type,
      }));

    if (resources.length > 0) {
      await createBatchFiles(resources)
        .then(async (res) => {
          const lessonFilesResource = res.data.data.map((file) => ({
            file_id: file.id,
            pdf_id: formData.id,
          }));
          await updateBatchPdfAttachmentFiles(lessonFilesResource).catch(
            (err) => {
              message.error(err.response.data.message ?? "Ошибка сохранения");
              throw err;
            },
          );
        })
        .catch((err) => {
          message.error(err.response.data.message ?? "Ошибка сохранения");
          throw err;
        });
    }

    if (formData.content_files && formData.content_files.length > 0) {
      if (formData.content_files.length > 1) {
        throw new Error("Only one content file is allowed");
      }

      if (formData.content_files.length === 1) {
        const contentFile = formData.content_files[0];

        if (!contentFile.id) {
          await createFile({
            key: String(contentFile.response.key),
            name: contentFile.name,
            type: contentFile.type,
          }).then((file) => {
            return updatePdf(formData.id, {
              ...formData,
              content_file_id: file.id,
            });
          });
        }
      }
    }
  }, []);

  return {
    updatePdfFiles,
  };
};
