import React, { useEffect, useMemo, useState } from "react";
import {
  ModalForm,
  ModalFormProps,
  ProFormUploadDragger,
  ProFormUploadDraggerProps,
} from "@ant-design/pro-components";
import { Button, Flex, message, Modal, Space, theme, Typography } from "antd";
import axios from "@/axios";
import { uploadRequest } from "@/entities/file";
import { Import as BaseImport } from "@/entities/import/lib/model";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import styled from "styled-components";
import fileDownload from "js-file-download";
import PoolImportResult from "@/entities/import/ui/button-pool-result";
import ButtonDefaultResult from "@/entities/import/ui/button-default-result";
import { Pool } from "@/models.ts";
import ButtonMembersResult from "@/entities/import/ui/button-members-result";
import ButtonPoolParticipantResult from "@/entities/import/ui/button-pool-participant-result";
import useFeatures from "@/entities/features/lib/use.ts";

const StyledModalParagraph = styled.div`
  .ant-modal-confirm-paragraph {
    max-width: none !important;
  }
`;

export type QuestionImport = {
  type: "questions";
  onBeforeImport: () => Promise<boolean>;
  testId: number;
};

export type MembersImport = {
  type: "members";
};

export type PositionLearningRuleImport = {
  type: "position_learning_rules";
};

export type PoolImport = {
  type: "registry";
  poolId?: Pool["id"];
  massiveImports?: boolean;
};

export type CourseImport = {
  type: "course";
};

interface Import extends BaseImport {
  files: any[];
}

type ImportInfo = {
  type: Import["type"];
  sampleFilePath?: string;
  resultTitle: string;
  title: string;
  fileFormat: string[];
  uploadDraggerDescription?: string;
  sampleFileDescription: string;
  fileType: string[];
  ResultComponent: React.FC<{ result: any }>;
  importData: Partial<Import>;
  onBeforeImport?: QuestionImport["onBeforeImport"];
  onOk: (cancelModal: () => void, result: any) => void;
};

type ImportButtonProps = ModalFormProps &
  (
    | QuestionImport
    | PoolImport
    | MembersImport
    | PositionLearningRuleImport
    | CourseImport
  ) & {
    trigger?: JSX.Element;
    onAfterFinish: () => void;
  };

const ImportButton: React.FC<ImportButtonProps> = ({
  trigger = <Button type="primary">Импорт</Button>,
  ...props
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const features = useFeatures();
  const [fileList, setFileList] = useState<ProFormUploadDraggerProps["value"]>(
    [],
  );

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (props.open) {
      setModalVisible(props.open);
    }
  }, [props.open]);

  const { token } = theme.useToken();

  const importInfo: ImportInfo = useMemo(() => {
    const data = {
      type: props.type!,
      resultTitle: "Результат импорта",
      ResultComponent: ButtonDefaultResult,
      onOk: (cancelModal: () => void) => {
        cancelModal?.();
        props.onAfterFinish();
      },
    };
    switch (props.type) {
      case "members":
        return {
          ...data,
          resultTitle: "Результат импорта пользователей",
          sampleFilePath: "/files/Пример файла импорта.xlsx",
          title: "Импорт пользователей",
          fileFormat: ["csv", "xlsx"],
          fileType: [
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
          uploadDraggerDescription:
            "Сохраните порядок столбцов и используйте запятую, как разделитель для файла",
          sampleFileDescription:
            "Скачайте шаблон и заполните данными пользователей, сохраните файл в формате .csv или .xlsx и загрузите его в область ниже",
          ResultComponent: ButtonMembersResult,
          importData: {
            type: props.type,
            status: "pending",
          },
          onOk: (cancelModal, result) => {
            cancelModal?.();
            if (features.isEnabled("pools_keys")) {
              modal.info({
                title: "Результат назначения обучения",
                icon: null,
                width: "60%",
                onCancel: () => {
                  props.onAfterFinish();
                },
                onOk: () => {
                  props.onAfterFinish();
                },
                content: <ButtonPoolParticipantResult result={result} />,
                okText: "Продолжить",
                modalRender: (modal) => (
                  <StyledModalParagraph>{modal}</StyledModalParagraph>
                ),
              });
            } else {
              props.onAfterFinish();
            }
          },
        };
      case "questions":
        return {
          ...data,
          sampleFilePath: "/files/Импорт вопросов теста.xlsx",
          title: "Импорт вопросов",
          fileFormat: ["xlsx"],
          fileType: [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
          uploadDraggerDescription: "",
          sampleFileDescription:
            "Скачайте и заполните шаблон данными, загрузите получившийся файл в область ниже",
          importData: {
            type: props.type,
            status: "pending",
            meta_fields: {
              test_id: props.testId,
            },
          },
          onBeforeImport: props.onBeforeImport,
        };

      case "registry":
        return {
          ...data,
          title: "Импорт номеров из реестра",
          fileFormat: ["xlsx"],
          fileType: [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
          uploadDraggerDescription:
            "Для корректного импорта обязательно наличие столбцов Номер в Реестре Минтруда, СНИЛС и Номер протокола",
          sampleFileDescription:
            "Скачайте и заполните шаблон данными, загрузите получившийся файл в область ниже",
          ResultComponent: PoolImportResult,
          importData: {
            type: props.type,
            status: "pending",
            meta_fields: {
              pool_id: props.poolId ?? 0,
              massiveImports: props.massiveImports ?? false,
            },
          },
        };
      case "position_learning_rules":
        return {
          ...data,
          sampleFilePath: "/files/Импорт правил обучения.xlsx",
          title: "Импорт правил обучения",
          fileFormat: ["xlsx"],
          fileType: [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
          uploadDraggerDescription: "",
          sampleFileDescription:
            "Скачайте и заполните шаблон данными, загрузите получившийся файл в область ниже",
          importData: {
            type: props.type,
            status: "pending",
          },
        };
      case "course":
        return {
          ...data,
          title: "Импорт курса",
          fileFormat: ["zip"],
          fileType: ["application/zip", "application/x-zip-compressed"],
          uploadDraggerDescription: "",
          sampleFileDescription: "Загрузите файл в область ниже",
          importData: {
            type: props.type,
            status: "pending",
          },
        };
      default:
        throw new Error(`Unsupported type`);
    }
  }, [props.type]);

  const handleDownloadSample = () => {
    axios
      .get(importInfo.sampleFilePath!, { responseType: "blob" })
      .then((response) => {
        fileDownload(
          response.data,
          `Пример файла импорта ${importInfo.title.replace("Импорт", "")}.xlsx`,
        );
      })
      .catch(() => {
        message.error("Ошибка загрузки файла");
      });
  };

  const onFinish: ModalFormProps<Import>["onFinish"] = async (value) => {
    for (const file of value.files) {
      if (file.status !== "done") {
        message.error("Дождитесь загрузки файла");
        return;
      }
    }

    setModalVisible(false);
    setFileList(undefined);

    if (await importInfo.onBeforeImport?.()) {
      return;
    }

    let importRecord: Import;

    try {
      importRecord = await axios
        .post<OrionRestCreateResponse<Import>>(
          `/api/imports`,
          importInfo.importData,
        )
        .then((res) => res.data.data);
    } catch (error: any) {
      message.error(error.response.data.message ?? "Ошибка создания импорта");
      return;
    }

    if (!importRecord) {
      message.error("Не удалось создать импорт");
      return;
    }

    for (const file of value.files) {
      if (!file.originFileObj) continue;

      const key = file.response.key;

      await axios
        .post(`/api/files`, {
          key,
          name: file.name,
          type: file.type,
        })
        .then(({ data }) => {
          return axios.put<OrionRestUpdateResponse<Import>>(
            `/api/imports/${importRecord.id}`,
            { file_id: data.data.id },
          );
        });
    }

    messageApi.open({
      type: "loading",
      content: `${importInfo.title} №${importRecord.id}...`,
      duration: 0,
    });

    const waitModal = modal.info({
      title: importInfo.title,
      content: (
        <Space direction={"vertical"} size={token.size}>
          <Typography.Text>Идет импорт ...</Typography.Text>
          <Typography.Text>
            Вы можете закрыть данное окно, файл все равно будет загружаться По
            завершении будет отображено окно с результатами импорта
          </Typography.Text>
        </Space>
      ),
      width: "40%",
      maskClosable: true,
      okText: "Продолжить",
    });

    let attempts = 120;

    importRecord = await axios
      .put<OrionRestUpdateResponse<Import>>(`/api/imports/${importRecord.id}`, {
        status: "to_process",
      })
      .then((res) => res.data.data);

    while (
      importRecord.status !== "processed" &&
      importRecord.status !== "failed" &&
      attempts--
    ) {
      importRecord = await axios
        .get<OrionRestShowResponse<Import>>(`/api/imports/${importRecord.id}`)
        .then((res) => res.data.data);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    waitModal.destroy();
    messageApi.destroy();

    const ResultImport = importInfo.ResultComponent;

    if (importRecord.status === "failed") {
      if (Array.isArray(importRecord.result)) {
        importRecord.result?.forEach((result) => {
          message.error(result.message);
        });
      } else {
        modal.error({
          title: `Импорт №${importRecord.id} завершился с ошибкой`,
          width: "60%",
          content: importRecord.result?.message,
        });
      }
      return Promise.resolve(false);
    } else if (importRecord.status === "processed") {
      message.success("Импорт успешно завершен");
      modal.info({
        title: `${importInfo.resultTitle} №${importRecord.id}`,
        icon: null,
        width: "60%",
        onCancel: () => {
          props.onAfterFinish();
        },
        onOk: (cancelModal) => {
          importInfo.onOk(cancelModal, importRecord.result);
        },
        content: <ResultImport result={importRecord.result} />,
        okText: "Продолжить",
        modalRender: (modal) => (
          <StyledModalParagraph>{modal}</StyledModalParagraph>
        ),
      });
      return Promise.resolve(true);
    } else if (attempts <= 0) {
      message.warning(
        "Мониторинг импорта завершен, перейдите в список импортов для дальнейшего отслеживания его статуса",
      );
      return Promise.resolve(false);
    }
  };

  return (
    <>
      {contextHolder}
      {messageContextHolder}
      <ModalForm
        open={modalVisible}
        onOpenChange={props.onOpenChange}
        width={"60%"}
        modalProps={{
          destroyOnClose: true,
          onCancel: () => {
            setFileList(undefined);
            setModalVisible(false);
          },
        }}
        title={importInfo.title}
        trigger={React.cloneElement(trigger, {
          onClick: () => setModalVisible(true),
        })}
        onFinish={onFinish}
        submitter={{
          searchConfig: {
            submitText: "Импортировать",
          },
          resetButtonProps: false,
          render: (_, dom) => (fileList && fileList.length > 0 ? dom : null),
        }}
      >
        {importInfo.sampleFilePath && (
          <Flex
            style={{
              paddingBottom: token.paddingXL,
              paddingTop: token.padding,
            }}
            gap={16}
            justify="space-between"
            align="center"
          >
            <Typography.Text type="secondary">
              {importInfo.sampleFileDescription}
            </Typography.Text>

            <Button type="primary" onClick={() => handleDownloadSample()}>
              Скачать шаблон файла импорта в формате .xlsx
            </Button>
          </Flex>
        )}
        <ProFormUploadDragger
          name={"files"}
          title={`Нажмите на область для выбора или перетащите сюда файл в формате .${importInfo.fileFormat.join(
            ", .",
          )}`}
          description={importInfo.uploadDraggerDescription}
          rules={[
            { required: true, message: "Файл не прикреплён" },
            () => ({
              validator(_, files) {
                const maxFileSizeBytes = 204800;
                if (files[0].size > maxFileSizeBytes) {
                  return Promise.reject(
                    new Error("Размер файла не должен превышать 200 КБ"),
                  );
                }

                let isTrueFormat = false;

                importInfo.fileType.forEach((type) => {
                  if (files[0].type === type) {
                    isTrueFormat = true;
                  }
                });

                if (isTrueFormat) return Promise.resolve();
                return Promise.reject(
                  new Error(
                    `Недопустимый тип файла: Можно загружать файлы только в формате .${importInfo.fileFormat.join(
                      ", .",
                    )}`,
                  ),
                );
              },
            }),
          ]}
          max={1}
          onChange={({ fileList: newFileList }) => setFileList(newFileList)}
          fieldProps={{
            customRequest: uploadRequest,
            listType: "picture",
            accept: `.${importInfo.fileFormat.join(",.")}`,
          }}
        />
      </ModalForm>
    </>
  );
};

export default ImportButton;
export type { ImportButtonProps };
