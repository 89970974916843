import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component";
import ProFormSnils from "@/shared/ant-design-pro-components/form-snils/ui/component";
import {
  date,
  dateNormalize,
  dateSTime,
  dateSTimeNormalize,
} from "@/shared/dayjs/lib/formats";
import emailPattern from "@/shared/patterns/lib/email-pattern";
import {
  ProForm,
  ProFormCheckbox,
  ProFormDatePicker,
  ProFormDigit,
  ProFormProps,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Col, Flex, message, Row, theme } from "antd";
import { Member, Space } from "@/models";
import { RestProps } from "@/shared/rest/lib/types";
import DateTimePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-picker-with-time-controls/ui/date-time-picker-with-time-controls";
import { themeValueEnum } from "@/shared/theme/lib/value-enum";
import {
  statusValueEnum,
  keycloakStatusValueEnum,
  useMembersResource,
} from "@/entities/member";

type MemberFormProps = ProFormProps<Member> & {
  space: Space;
  rest: RestProps<Member>;
};

const MemberForm: React.FC<MemberFormProps> = ({ rest, space }) => {
  const [form] = ProForm.useForm();
  const { token } = theme.useToken();
  const { getMember, updateMember } = useMembersResource();

  const defaultProps: Partial<MemberFormProps> = {
    form,
    submitter: {
      searchConfig: {
        submitText: "Сохранить",
      },
      resetButtonProps: false,
      render: (_, dom) => <Flex justify="end">{dom}</Flex>,
    },
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") throw new Error("Not implemented on admin panel");

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return getMember(Number(rest.recordKey), {
        params: {
          includes: [
            { relation: "positions" },
            { relation: "avatar_image_file" },
            { relation: "accessed_departments" },
          ],
        },
      });
    };
    defaultProps.onFinish = async (values) => {
      return updateMember(Number(rest.recordKey), values)
        .then(async (member) => {
          message.success("Пользователь успешно обновлен");
          rest.onAfterUpdate?.(member);
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении пользователя: ${messageText}`);
          return false;
        });
    };
  }
  return (
    <ProForm {...defaultProps}>
      <Row
        gutter={token.paddingXS}
        style={{ width: "100%", height: "calc(100% - 32px)" }}
      >
        <Col xs={24} sm={24} md={12} style={{ height: "100%" }}>
          <ProFormDigit name={"space_id"} hidden />
          <ProFormText
            label={"Фамилия"}
            name={"last_name"}
            rules={[
              { max: 255, required: true },
              {
                pattern: /^[\p{L}-]*$/u,
                message: "Некорректное значение поля Фамилия",
              },
            ]}
            transform={(value) => value.trim()}
          />
          <ProFormText
            label={"Имя"}
            name={"first_name"}
            transform={(value) => value.trim()}
            rules={[
              { max: 255, required: true },
              {
                pattern: /^[\p{L}-]*$/u,
                message: "Некорректное значение поля Имя",
              },
            ]}
          />
          <ProFormText
            transform={(value) => value.trim()}
            label={"Отчество"}
            name={"middle_name"}
            rules={[
              { max: 255, required: false },
              {
                pattern: /^[\p{L}-]*$/u,
                message: "Некорректное значение поля Отчество",
              },
            ]}
          />
          <ProFormDatePicker
            label="Дата рождения"
            name="birth_date"
            fieldProps={{
              format: date,
              style: { width: "100%" },
              needConfirm: false,
            }}
            normalize={dateNormalize}
          />
          <ProFormRadio.Group
            label="Пол"
            name="gender"
            rules={[{ required: false }]}
            options={[
              { value: "male", label: "Мужской" },
              { value: "female", label: "Женский" },
            ]}
            fieldProps={{ optionType: "button", buttonStyle: "solid" }}
          />
          <ProFormText
            label="Гражданство"
            name="citizenship"
            rules={[{ required: false, max: 255 }]}
          />
          <ProFormText
            label="Уровень образования"
            name="education_level"
            rules={[{ required: false, max: 255 }]}
          />
          <ProFormText
            label="Серия диплома"
            name="education_diploma_series"
            rules={[{ required: false, max: 255, min: 10 }]}
          />
          <ProFormText
            label="Номер диплома"
            name="education_diploma_number"
            rules={[{ required: false }]}
          />
          <ProFormText
            label="Фамилия в дипломе"
            name="education_diploma_last_name"
            rules={[{ required: false, max: 255 }]}
          />
          <ProFormDigit label={"Аватар ID"} name={"avatar_image_file_id"} />
        </Col>
        <Col xs={24} sm={24} md={12} style={{ height: "100%" }}>
          <ProFormSnils
            label={"СНИЛС"}
            name={"snils"}
            style={{ padding: `0 8px` }}
            rules={[{ required: false }]}
          />
          <ProFormPhone
            label={"Телефон"}
            name={"phone"}
            style={{ padding: `0 8px` }}
            rules={[{ required: false }]}
          />
          <ProFormText
            label={"Почта"}
            name={"email"}
            rules={[
              {
                required: space.authentication_types.includes("email+password"),
              },
              {
                type: "email",
                message: "Атрибут email должен быть действительным email",
              },
              { pattern: emailPattern, message: "Некорректный формат почты" },
            ]}
          />
          <ProFormText
            label={"Логин"}
            name={"username"}
            rules={[
              {
                required:
                  space.authentication_types.includes("username+password"),
                max: 255,
              },
            ]}
          />
          <ProFormSelect
            label="Статус"
            name="status"
            rules={[{ required: true }]}
            valueEnum={statusValueEnum}
          />
          <ProFormSelect
            valueEnum={keycloakStatusValueEnum}
            label={"Статус пользователя в Keycloak"}
            name={"keycloak_user_status"}
            rules={[{ required: true }]}
          />
          <ProFormText
            label={"Идентификатор пользователя в Keycloak"}
            name={"keycloak_user_sub"}
            style={{ padding: `0 8px` }}
            rules={[{ required: true }]}
          />
          <DateTimePickerWithTimeControls
            label={"Дата добавления"}
            name={"created_at"}
            style={{ padding: `0 8px` }}
            rules={[{ required: true }]}
            fieldProps={{
              format: dateSTime,
              style: { width: "100%" },
              showNow: false,
            }}
            normalize={dateSTimeNormalize}
          />
          <DateTimePickerWithTimeControls
            label={"Дата изменения"}
            name={"updated_at"}
            style={{ padding: `0 8px` }}
            fieldProps={{
              format: dateSTime,
              style: { width: "100%" },
              showNow: false,
            }}
            normalize={dateSTimeNormalize}
          />
          <ProFormDigit
            label={"ID роли"}
            name={"role_id"}
            rules={[{ required: true }]}
          />
          <ProFormCheckbox
            name={"is_restricted_access"}
            rules={[{ required: true }]}
          >
            Изоляция доступа по подразделениям
          </ProFormCheckbox>
          <ProFormSelect
            valueEnum={themeValueEnum}
            label={"Тема"}
            name={"theme"}
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
    </ProForm>
  );
};

export default MemberForm;
export type { MemberFormProps };
