import React from "react";
import { Longread } from "@/models";
import { message, Modal } from "antd";
import { ButtonProps } from "antd/es/button";
import Button from "@/shared/ant-design/button/ui/button";
import { useLongreadsResource } from "@/entities/longread";

type Props = {
  longreadId: Longread["id"];
  onDelete?: () => void;
};

const LongreadDeleteButton: React.FC<Props> = ({
  longreadId,
  onDelete: onDeleteProp,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const { deleteLongread } = useLongreadsResource();

  const onDelete: ButtonProps["onClick"] = () => {
    modal.warning({
      title: "Удалить текущий лонгрид?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await deleteLongread(longreadId);
        await onDeleteProp?.();
        message.success("Лонгрид успешно удалён");
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Button danger type={"primary"} onClick={onDelete}>
        Удалить
      </Button>
    </>
  );
};

export default LongreadDeleteButton;
