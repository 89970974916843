import React, { useEffect } from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { Role } from "@/models";
import { message, Skeleton, Tag, Typography } from "antd";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import useSWR from "swr";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useMe from "../me/lib/use";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";
import styled from "styled-components";

const StyledProFormSelect = styled(ProFormSelect)<ProFormSelectProps>`
  .ant-select-selection-overflow-item > span {
    max-width: 100%;
  }
  .ant-select-selection-overflow-item > span > span {
    max-width: 100%;
    display: flex;
  }
`;

type RolesSearchSelectProps = Partial<ProFormSelectProps> & {
  isDefaultEmployee?: boolean;
  filteredId?: number;
};

const RolesSearchSelect: React.FC<RolesSearchSelectProps> = ({
  isDefaultEmployee,
  filteredId,
  ...selectProps
}) => {
  const member = useMe();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  const { data, isLoading } = useSWR(
    member.permissions.includes("role:view") ? "/api/roles/search" : null,
    async (url) => {
      return await axios
        .post<OrionRestIndexResponse<Role>>(url)
        .then((res) => {
          const data: Role[] = [];
          res.data.data.forEach((role) => {
            if (filteredId === role.id) return;
            data.push(role);
          });
          return data;
        })
        .catch((error) => {
          message.error(
            error.response.data.message ?? "Ошибка получения ролей",
          );
        });
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  return (
    <StyledProFormSelect
      options={data?.map((role) => ({
        label: role.name,
        value: role.id,
        color: role.color,
      }))}
      fieldProps={{
        tagRender,
        optionItemRender(item) {
          return (
            <Tag color={item.color} bordered style={{ maxWidth: "100%" }}>
              <Typography.Text
                ellipsis
                style={{ color: invertColor(item.color) }}
              >
                {item.label}
              </Typography.Text>
            </Tag>
          );
        },
      }}
      showSearch
      initialValue={
        isDefaultEmployee && data?.find((role) => role.is_default)?.id
      }
      {...selectProps}
    >
      {isFirstRender && <Skeleton.Input active block />}
    </StyledProFormSelect>
  );
};

export default RolesSearchSelect;
export type { RolesSearchSelectProps };
