import axios from "@/axios";
import { Section } from "@/models";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { useCallback } from "react";

export const useSectionsResource = () => {
  const deleteSection = useCallback((id: Section["id"]) => {
    axios.delete(`/api/sections/${id}`);
  }, []);

  const getSection = useCallback(
    (id: Section["id"]) =>
      axios
        .get<OrionRestShowResponse<Section>>(`/api/sections/${id}`)
        .then((res) => res.data.data),
    [],
  );

  const updateSection = useCallback(
    (id: Section["id"], values: Partial<Section>) =>
      axios
        .put<OrionRestUpdateResponse<Section>>(`/api/sections/${id}`, values)
        .then(async (res) => res.data.data),
    [],
  );

  return {
    deleteSection,
    getSection,
    updateSection,
  };
};
