import { useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import * as Sentry from "@sentry/react";

export const useSplitPdf = (src: string) => {
  const [pageUrls, setPageUrls] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const splitPDF = async (pdfSrc: string) => {
      try {
        const pdfBytes = await fetch(pdfSrc).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const pagesArray = await Promise.all(
          Array.from({ length: pdfDoc.getPageCount() }, async (_, i) => {
            const newPdf = await PDFDocument.create();
            const [copiedPage] = await newPdf.copyPages(pdfDoc, [i]);
            newPdf.addPage(copiedPage);

            const pdfBytes = await newPdf.save();
            const blob = new Blob([pdfBytes], { type: "application/pdf" });
            return URL.createObjectURL(blob);
          }),
        );
        setPageUrls(pagesArray);
        setIsLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Ошибка при обработке PDF:", error);
      }
    };

    splitPDF(src);
  }, [src]);

  return { pageUrls, isLoading };
};
