import { Button, Space, theme } from "antd";

type Props = {
  onClick?: () => void;
};

export const AddQuestionButton: React.FC<Props> = ({ onClick }) => {
  const {
    token: { marginMD },
  } = theme.useToken();

  return (
    <Space
      direction={"vertical"}
      align={"center"}
      style={{ marginTop: marginMD }}
    >
      <Button
        style={{ width: "180px" }}
        type="primary"
        onClick={() => onClick?.()}
      >
        Добавить вопрос
      </Button>
    </Space>
  );
};
