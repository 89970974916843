export const validateCourseCoverImage = (file: File) => {
  const oneMb = 1048000;
  if (file.size > oneMb) {
    return Promise.reject("Необходимо выбрать файл меньше 1 МБ");
  }

  if (!file.type) {
    return Promise.reject("Не удалось определить тип файла");
  }

  const isImage = ["image/png", "image/jpg", "image/jpeg"].includes(file.type);
  if (!isImage) {
    return Promise.reject(
      "Недопустимый тип файла: Можно загружать файлы с расширением PNG, JPG или JPEG",
    );
  }

  return Promise.resolve();
};
