import axios from "@/axios";
import { Course } from "@/models";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const useCoursesResource = () => {
  const createCourse = useCallback(
    (values: Course, config?: AxiosRequestConfig) => {
      return axios.post<OrionRestCreateResponse<Course>>(
        "/api/courses",
        values,
        config,
      );
    },
    [],
  );

  const updateCourse = useCallback(
    (
      id: Course["id"],
      values: Partial<Course>,
      config?: AxiosRequestConfig,
    ) => {
      return axios
        .put<OrionRestUpdateResponse<Course>>(
          `/api/courses/${id}`,
          values,
          config,
        )
        .then((res) => res.data.data);
    },
    [],
  );

  const getCourse = useCallback(
    (id: Course["id"], config?: AxiosRequestConfig) => {
      return axios
        .get<OrionRestShowResponse<Course>>(`/api/courses/${id}`, config)
        .then((res) => res.data.data);
    },
    [],
  );

  const getCourses = useCallback(
    (
      config?: AxiosRequestConfig & {
        filters?: any[];
        includes?: { relation: string }[];
        scopes?: { name: string; parameters?: any[] }[];
        sort?: { field: string; direction: "asc" | "desc" }[];
      },
    ) => {
      return axios.post<OrionRestIndexResponse<Course>>(
        `/api/courses/search`,
        config,
      );
    },
    [],
  );

  const batchUpdateToDeleteCourses = useCallback(
    (courseIds: Course["id"][]) => {
      const resources = courseIds.reduce(
        (acc, id) => ({ ...acc, [id]: { status: "to_delete" } }),
        {} as { [key: string]: Partial<Course> },
      );
      return axios.patch<OrionRestShowResponse<Course>>(`/api/courses/batch`, {
        resources,
      });
    },
    [],
  );

  const batchUpdateCourses = useCallback(
    (resources: { [key: string]: Partial<Course> }) => {
      return axios.patch<OrionRestShowResponse<Course>>(`/api/courses/batch`, {
        resources,
      });
    },
    [],
  );

  return {
    createCourse,
    updateCourse,
    getCourse,
    getCourses,
    batchUpdateToDeleteCourses,
    batchUpdateCourses,
  };
};
