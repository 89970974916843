import { UploadWithPreview } from "@/entities/file";
import { ProForm } from "@ant-design/pro-components";
import { useEffect, useState } from "react";

export const AvatarUpload: React.FC = () => {
  const [uploadListType, setUploadListType] = useState<
    "text" | "picture-card" | undefined
  >("picture-card");
  const [incorrectImage, setIncorrectImage] = useState(false);

  const form = ProForm.useFormInstance();
  const avatarImageFile = ProForm.useWatch("avatar_image_file", form);

  useEffect(() => {
    if (avatarImageFile && avatarImageFile.length > 0) {
      setUploadListType("text");
    } else {
      setUploadListType("picture-card");
    }
  }, [avatarImageFile]);

  return (
    <UploadWithPreview
      type="button"
      name="avatar_image_file"
      label="Фотография профиля"
      max={1}
      fieldProps={{
        listType: uploadListType,
        onRemove: () => {
          if (incorrectImage) {
            setIncorrectImage(false);
          }
        },
        accept: ".png, .jpg, .jpeg",
      }}
      title="Загрузить"
      rules={[
        { required: false },
        () => ({
          validator(_, files) {
            if (files) {
              for (const file of files) {
                if (incorrectImage) {
                  return Promise.reject(
                    new Error("Файл поврежден или не является изображением"),
                  );
                }
                if (!file.type) {
                  return Promise.reject(
                    new Error("Не удалось определить тип файла"),
                  );
                }

                if (file.originFileObj) {
                  const img = new Image();
                  img.src = URL.createObjectURL(file.originFileObj!);
                  img.onerror = () => {
                    if (!incorrectImage) {
                      setIncorrectImage(true);
                    }
                  };
                }

                const isImage = [
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                ].includes(file.type);

                if (!isImage) {
                  return Promise.reject(
                    new Error(
                      "Недопустимый тип файла: Можно загружать файлы с расширением PNG, JPG или JPEG",
                    ),
                  );
                }
              }
            }
            if (incorrectImage) {
              setIncorrectImage(false);
            }

            return Promise.resolve();
          },
        }),
      ]}
    />
  );
};
