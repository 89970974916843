import React from "react";
import { Modal, ModalProps } from "antd";
import { FilePreviewProps, FilePreview } from "./file-preview";

type Props = Omit<ModalProps, "content" | "children"> & {
  previewProps: FilePreviewProps;
};

export const FilePreviewModal: React.FC<Props> = ({
  previewProps,
  ...modalProps
}) => {
  return (
    <Modal destroyOnClose footer={null} title={"Просмотр"} {...modalProps}>
      <FilePreview {...previewProps} />
    </Modal>
  );
};
