import React from "react";
import { Flex, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { useParams } from "react-router-dom";
import { MemberForm } from "@/features/member";

const Page: React.FC = () => {
  const { member_id } = useParams();
  const { token } = theme.useToken();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Flex
        vertical
        gap={8}
        style={{
          width: "100%",
          height: "calc(100% - 30px)",
          backgroundColor: token.colorBgContainer,
        }}
      >
        <MemberForm rest={{ type: "update", recordKey: member_id }} />
      </Flex>
    </Flex>
  );
};

export default Page;
