import { Question } from "./question";

export const mutateQuestionBeforeRequest = (questions: Question[]) => {
  return questions.reduce((acc, question) => {
    if (question.is_deleted) return acc;
    if (question.type === "matching") {
      const answers: any = [];
      const matched: any = [];
      const matchable: any = [];
      question.matching.forEach((answer) => {
        answers.push({
          matchable_uuid: answer.matchable_uuid,
          matched_uuid: answer.matched_uuid,
        });
        matched.push({
          uuid: answer.matched_uuid,
          value: answer.matched_value,
        });
        matchable.push({
          uuid: answer.matchable_uuid,
          value: answer.matchable_value,
        });
      });
      question.answers = answers;
      question.matched = matched;
      question.matchable = matchable;
    }
    return [...acc, question];
  }, [] as Question[]);
};
