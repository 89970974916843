import React, { useState } from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormProps,
} from "@ant-design/pro-components";
import { Course, CourseCommission } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import { OrionRestBatchCreateResponse } from "@/shared/types/orion-rest";
import { Button, Flex, message, Typography } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useFeatures from "@/entities/features/lib/use.ts";
import { CommissionSelect } from "@/entities/commission/ui/select";
import useMe from "@/entities/me/lib/use";
import { FieldData } from "rc-field-form/es/interface";
import {
  useCoursesResource,
  CourseFormData,
  CourseFormFields,
} from "@/entities/course";
import { useSyncCourseCoverImage } from "../lib/sync-course-cover-image";

type CourseFormProps = ProFormProps<CourseFormData> & {
  rest: RestProps<Course>;
};

export const CourseForm: React.FC<CourseFormProps> = ({ rest, ...props }) => {
  const features = useFeatures();
  const [form] = ProForm.useForm<CourseFormData>(props.form);
  const [loading, setLoading] = useState(false);
  const commissions = ProForm.useWatch("course_commissions", form);
  const [toDeleteCourseCommissions, setToDeleteCourseCommissions] =
    React.useState<CourseCommission["id"][]>([]);
  const { createCourse, getCourse, updateCourse } = useCoursesResource();
  const { syncCourseCoverImage } = useSyncCourseCoverImage();

  const member = useMe();

  const checkDublicateCommissions = (commissions: CourseCommission[]) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;
    commissions.forEach((item, index) => {
      if (!item.commission_id) return;
      if (item.commission_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.commission_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["course_commissions", index, "commission_id"],
            errors: [
              "Комиссия дублируется, выберите другую комиссию или удалите поле",
            ],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  const prepareValues = (values: CourseFormData) => {
    const { cover_image_file, ...restValues } = values;
    return restValues;
  };
  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
    });
    defaultProps.onFinish = async (values) => {
      /** Check duplicate commissions */
      if (
        values.course_commissions &&
        checkDublicateCommissions(values.course_commissions)
      ) {
        return;
      }

      return await createCourse(prepareValues(values))
        .then(async (res) => {
          await syncCourseCoverImage({ ...values, id: res.data.data.id });
          return res.data.data;
        })
        .then(async (data) => {
          const toCreateCourseCommissions = values.course_commissions;

          if (
            toCreateCourseCommissions &&
            toCreateCourseCommissions.length > 0
          ) {
            await axios
              .post<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toCreateCourseCommissions.map((el) => {
                    return {
                      ...el,
                      course_id: data.id,
                    };
                  }),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка создания курсов коммиссии",
                );
                throw err;
              });
          }
          return data;
        })
        .then((data) => {
          message.success("Курс успешно создан");
          rest.onAfterCreate?.(data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании курса",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.submitter = false;
    defaultProps.request = async () => {
      return getCourse(Number(rest.recordKey), {
        params: {
          includes: [
            { relation: "cover_image_file" },
            { relation: "course_commissions.commission" },
          ],
        },
      }).then((course) => {
        return {
          ...course,
          cover_image_file: course.cover_image_file
            ? [{ ...course.cover_image_file, status: "done" }]
            : [],
        } as CourseFormData;
      });
    };

    defaultProps.onFinish = async (values) => {
      /** Check duplicate commissions */
      if (
        values.course_commissions &&
        checkDublicateCommissions(values.course_commissions)
      ) {
        return;
      }

      setLoading(true);
      return updateCourse(Number(rest.recordKey), {
        ...prepareValues(values),
        learning_expiration_months_count:
          values.learning_expiration_months_count || null,
      })
        .then(async (course) => {
          await syncCourseCoverImage({ ...values, id: course.id });
          return course;
        })
        .then(async (data) => {
          if (toDeleteCourseCommissions?.length) {
            await axios
              .delete(`/api/course-commissions/batch`, {
                data: {
                  resources: toDeleteCourseCommissions,
                },
              })
              .then(() => {
                setToDeleteCourseCommissions([]);
              })
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка удаления курсов коммиссии",
                );
                throw err;
              });
          }

          return data;
        })
        .then(async (data) => {
          const toUpdateCourseCommissions = values.course_commissions?.filter(
            (el) => el.id,
          );

          if (
            toUpdateCourseCommissions &&
            toUpdateCourseCommissions.length > 0
          ) {
            await axios
              .patch<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toUpdateCourseCommissions
                    .map((el) => {
                      return {
                        course_id: data.id,
                        commission_id: el.commission_id ?? null,
                        id: el.id ?? null,
                      };
                    })
                    .reduce((acc, courseCommission) => {
                      acc[courseCommission.id] = courseCommission;
                      return acc;
                    }, {} as Record<string, Partial<CourseCommission>>),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка обновления курсов коммиссии",
                );
                throw err;
              });
          }
          return data;
        })
        .then(async (data) => {
          const toCreateCourseCommissions = values.course_commissions?.filter(
            (el) => !el.id,
          );

          if (
            toCreateCourseCommissions &&
            toCreateCourseCommissions.length > 0
          ) {
            await axios
              .post<OrionRestBatchCreateResponse<CourseCommission>>(
                `/api/course-commissions/batch`,
                {
                  resources: toCreateCourseCommissions.map((el) => {
                    return {
                      ...el,
                      course_id: data.id,
                    };
                  }),
                },
              )
              .catch((err) => {
                message.error(
                  err.response.data.message ??
                    "Ошибка создания курсов коммиссии",
                );
                throw err;
              });
          }
          return data;
        })
        .then((data) => {
          message.success("Курс успешно обновлён");
          rest.onAfterUpdate?.(data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении курса: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(async () => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          setLoading(false);
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <>
      {rest.type === "update" && (
        <Flex justify={"end"} gap={16}>
          <Button
            loading={loading}
            type="primary"
            onClick={() => form.submit()}
          >
            Сохранить
          </Button>
        </Flex>
      )}
      <ProForm<CourseFormData> {...props}>
        <CourseFormFields showKeyField={features.isEnabled("courses_keys")} />
        {member.permissions.includes("commission:update") && (
          <ProFormList
            style={{ width: "100%" }}
            creatorButtonProps={{
              creatorButtonText: "Добавить еще",
              type: "primary",
              block: false,
              icon: false,
            }}
            name="course_commissions"
            max={10}
            containerStyle={{ width: "100%" }}
            label={<Typography.Text strong>Комиссии</Typography.Text>}
            copyIconProps={false}
            deleteIconProps={{ tooltipText: "Удалить" }}
            onAfterRemove={async (index) => {
              if (
                commissions &&
                typeof index === "number" &&
                commissions[index].id
              ) {
                setToDeleteCourseCommissions((prev) => [
                  ...prev,
                  commissions[index].id,
                ]);
              }
            }}
          >
            {(_, index) => (
              <>
                <CommissionSelect
                  listName={["course_commissions", index, "commission_id"]}
                  name={"commission_id"}
                  rules={[{ required: true }]}
                  withExternalLink
                />
                <ProFormDigit hidden name="id" />
              </>
            )}
          </ProFormList>
        )}
      </ProForm>
    </>
  );
};

export default CourseForm;
export type { CourseFormProps };
