import { useCallback, useState } from "react";
import { FilePreviewProps } from "../ui/file-preview";
import { message } from "antd";

export const usePreview = () => {
  const [preview, setPreview] = useState<FilePreviewProps | null>(null);

  const onPreview = useCallback(
    (file: any | null) => {
      if (!file) {
        setPreview(null);
        return;
      }

      const type = file.type.split("/");
      if (type[0] === "image" || type[1] === "pdf" || type[0] === "video") {
        setPreview(
          file.originFileObj
            ? {
                file: {
                  type: file.type,
                  url: URL.createObjectURL(file.originFileObj),
                },
              }
            : { file },
        );
      } else {
        message.warning("Просмотр данного типа файла не поддерживается");
      }
    },
    [setPreview],
  );

  return { preview, onPreview };
};
