import { Member } from "@/models";
import { ProDescriptions } from "@ant-design/pro-components";
import { message, Modal, Space, Typography } from "antd";
import copy from "copy-to-clipboard";
import { useCallback } from "react";

export const useShowMemberInfoModal = () => {
  const [modalMember, modalMemberHolder] = Modal.useModal();

  const showMemberInfoModal = useCallback((member: Member) => {
    modalMember.info({
      title: `Информация о созданном пользователе`,
      okText: "Скопировать все и закрыть",
      onOk: () => {
        let copiedData = `ФИО: ${member.full_name}`;

        if (member.email) {
          copiedData += `\nПочта: ${member.email}`;
        }

        if (member.snils) {
          copiedData += `\nСНИЛС: ${member.snils}`;
        }

        if (member.username) {
          copiedData += `\nЛогин: ${member.username}`;
        }
        copy(copiedData);
        message.info("Данные скопированы в буфер обмена");
      },
      width: "60%",
      icon: null,
      content: (
        <Space direction="vertical">
          <ProDescriptions
            column={1}
            dataSource={member}
            layout="horizontal"
            columns={[
              {
                dataIndex: "full_name",
                title: <Typography.Text>ФИО</Typography.Text>,
              },
              {
                dataIndex: "snils",
                hideInDescriptions: !member.snils,
                title: <Typography.Text>СНИЛС</Typography.Text>,
              },
              {
                dataIndex: "email",
                hideInDescriptions: !member.email,
                title: <Typography.Text>Почта</Typography.Text>,
              },
              {
                dataIndex: "username",
                hideInDescriptions: !member.username,
                title: <Typography.Text>Логин</Typography.Text>,
              },
            ]}
          />
        </Space>
      ),
    });
  }, []);

  return {
    modalMemberHolder,
    showMemberInfoModal,
  };
};
