import React from "react";
import { message, Modal } from "antd";
import { Member } from "@/models";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { useMembersResource } from "@/entities/member";

type MemberResetPasswordButtonProps = {
  membersIds: Member["id"][];
  onAfterFinish: () => void;
  trigger?: React.ReactElement;
};

export const MemberResetPasswordButton: React.FC<
  MemberResetPasswordButtonProps
> = ({ membersIds, onAfterFinish, trigger }) => {
  if (!trigger) {
    trigger = (
      <Button type="primary" danger>
        Сбросить пароль
      </Button>
    );
  }
  const [modal, modalHolder] = Modal.useModal();
  const { resetPasswordMembers } = useMembersResource();

  const onResetPassword = () => {
    return modal.confirm({
      title: "Вы уверены, что хотите сбросить пароль?",
      content: "Пользователи смогут войти в систему с новым паролем.",
      onOk: async () => {
        return await resetPasswordMembers(membersIds)
          .then((res) => {
            message.success(res.data.message);
            return onAfterFinish();
          })
          .catch((err) => {
            message.error(err.response.data.message ?? err.message);
            console.error(err);
          });
      },
    });
  };

  return (
    <>
      {modalHolder}
      {React.cloneElement(trigger, {
        onClick: onResetPassword,
        disabled: membersIds.length === 0,
      })}
    </>
  );
};

export type { MemberResetPasswordButtonProps };
