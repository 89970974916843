import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Group } from "@/models";
import { debounce } from "lodash";
import { BaseOptionType } from "antd/es/cascader";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSWR from "swr";
import { Skeleton } from "antd";

type GroupSearchSelectProps = Partial<ProFormSelectProps> & {
  listName?: ProFormSelectProps["name"];
};

const GroupSearchSelect: React.FC<GroupSearchSelectProps> = ({
  fieldProps,
  listName,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();

  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState<any>();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const {
    data: groups,
    isLoading,
    error,
  } = useSWR(
    ["/api/groups/search", searchValue, initialValue],
    async ([url]) => {
      const filters = [];

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      const res = await axios
        .post<{ data: Group[] }>(url, {
          filters,
          sort: [{ field: "created_at", direction: "desc" }],
        })
        .then(async (res) => {
          const data: BaseOptionType[] = [];

          res.data.data.forEach((group) => {
            data.push({
              label: group.name,
              value: group.id,
            });
          });

          if (initialValue && typeof initialValue === "object") {
            const missingValues: number[] = (
              Array.isArray(initialValue)
                ? initialValue
                : // @ts-ignore
                  selectProps.value ?? []
            ).filter(
              (value: any) => !data.some((option) => option.value === value),
            );

            if (missingValues.length > 0) {
              const missingData = await axios
                .post<OrionRestIndexResponse<Group>>("/api/groups/search", {
                  filters: [
                    {
                      type: "and",
                      nested: missingValues.map((value) => ({
                        type: "or",
                        field: "id",
                        operator: "=",
                        value,
                      })),
                    },
                  ],
                })
                .then((res) =>
                  res.data.data.map((group) => ({
                    label: group.name,
                    value: group.id,
                  })),
                );

              data.push(...missingData);
            }
          }

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((option) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/groups/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: data.data.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );
  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  if (error) throw error;

  return (
    <ProFormSelect
      options={groups}
      showSearch
      label={"Группа"}
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      {...selectProps}
    >
      {isFirstRender && isLoading && <Skeleton.Input active block />}
    </ProFormSelect>
  );
};

export default GroupSearchSelect;
export type { GroupSearchSelectProps };
