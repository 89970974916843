import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAutoRouter } from "@/shared/auto-router/lib/context.ts";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { Breadcrumb as BaseBreadcrumb, Typography } from "antd";
import { DIRECTORY_SEPARATOR } from "@/shared/auto-router/lib/constants.ts";
import styled from "styled-components";

const { Text: BaseText } = Typography;

const breadcrumbItemsFiles = import.meta.glob<React.FC>(
  "@/pages/**/breadcrumb-item.tsx",
  {
    eager: true,
    import: "default",
  },
);

const Breadcrumb = styled(BaseBreadcrumb)`
  .anticon-down {
    display: none;
  }
`;

const Text = styled(BaseText)`
  max-width: 20vw !important;
`;

const AutoBreadcrumb: React.FC = () => {
  const params = useParams();
  const router = useAutoRouter();
  const { pathname } = useLocation();

  let items: ItemType[] = Object.entries(breadcrumbItemsFiles)
    /** Sort by length */
    .sort(([a]: [string, any], [b]: [string, any]): number => {
      const aSegmentsLength = a.split(DIRECTORY_SEPARATOR).length;
      const bSegmentsLength = b.split(DIRECTORY_SEPARATOR).length;

      return aSegmentsLength - bSegmentsLength;
    })
    .map<ItemType | null>(([filePath, BreadcrumbItemComponent]) => {
      const result: ItemType = {
        title: <BreadcrumbItemComponent />,
      };

      const to = filePath
        .replace(/^\/src\/pages/, "")
        .replace(new RegExp(`^${router.basePath}`, "g"), "")
        .replace(new RegExp(`/breadcrumb-item.tsx$`, "g"), "")
        /**
         * if path contains "[<any>]" then replace with ":<any>"
         * where <any> is any string from current params in route
         */
        .replace(/\[([^\]]+)\]/g, (_, p1) => {
          return params[p1]!;
        });

      /** Фильтруем элементы, которые не соответствуют текущему пути */
      const regExp = new RegExp(`^${to}(/.*)?$`);
      if (!regExp.test(pathname)) return null;

      result.title = (
        <Text
          ellipsis={{ tooltip: true }}
          type={to !== pathname ? "secondary" : undefined}
        >
          {result.title}
        </Text>
      );

      /** Для всех элементов, кроме последнего, добавляем ссылку */
      if (to !== pathname) {
        result.title = <Link to={to}>{result.title}</Link>;
      }

      const toSegmentsLength = to.split(DIRECTORY_SEPARATOR).length;
      const pathnameSegmentsLength = pathname.split(DIRECTORY_SEPARATOR).length;

      if (toSegmentsLength > pathnameSegmentsLength) return null;

      return result;
    })
    .filter((item) => item !== null) as ItemType[];

  if (items.length > 4) {
    items = [
      {
        title: "...",
        menu: {
          items: items.slice(0, items.length - 4),
        },
      },
      ...items.slice(items.length - 4),
    ];
  }

  return <Breadcrumb items={items} />;
};

export default AutoBreadcrumb;
