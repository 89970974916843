import React from "react";
import { Flex, Modal, Typography } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { Import } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axios from "@/axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { typeValueEnum } from "@/entities/import/lib/type-value-enum";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import ButtonMembersResult from "@/entities/import/ui/button-members-result";
import PoolImportResult from "@/entities/import/ui/button-pool-result";
import ButtonDefaultResult from "@/entities/import/ui/button-default-result";
import { statusValueEnum } from "@/entities/import/lib/status-value-enum";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";

const Page: React.FC = () => {
  const [total, setTotal] = React.useState(0);
  const { parentHeight, ref } = useParentHeight("table");
  const [modal, modalHolder] = Modal.useModal();

  const onResultModal = (type: Import["type"], result: Import["result"]) => {
    let ContentComponent;

    switch (type) {
      case "members":
        ContentComponent = ButtonMembersResult;
        break;
      case "registry":
        ContentComponent = PoolImportResult;
        break;
      default:
        ContentComponent = ButtonDefaultResult;
        break;
    }

    modal.info({
      title: "Результат импорта",
      icon: null,
      width: "60%",
      style: {
        maxHeight: "90vh",
      },
      closable: true,
      okButtonProps: {
        style: { display: "none" },
      },
      content: <ContentComponent result={result} />,
    });
  };

  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      {modalHolder}
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }} ref={ref}>
        <ProTable
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          scroll={{
            y: parentHeight + (total ? 0 : 40) - 145,
          }}
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
          }}
          request={async (params, sort, filter) => {
            const config = {
              method: "POST",
              url: "/api/imports/search",
              ...axiosConfigAdapter(params, sort, filter),
            };

            const data = await axios
              .request<OrionRestIndexResponse<Import>>(config)
              .then((res) => res.data);

            setTotal(data.meta.total);

            return {
              data: data.data,
              success: true,
              total: data.meta.total,
            };
          }}
          columnsState={{
            persistenceKey: "imports-table",
          }}
          saveFilters={{
            persistenceKey: "imports-table",
          }}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              sorter: true,
              hideInSearch: true,
              copyable: true,
              width: 75,
            },
            {
              title: () => "Тип импорта",
              tooltip: true,
              dataIndex: "type",
              valueEnum: typeValueEnum,
              sorter: true,
              formItemProps: {
                tooltip: false,
              },
              fieldProps: {
                mode: "multiple",
              },
            },
            {
              title: "Статус",
              dataIndex: "status",
              sorter: true,
              valueEnum: statusValueEnum,
              fieldProps: { mode: "multiple", tagRender },
            },
            {
              title: () => "Дата создания",
              tooltip: true,
              dataIndex: "created_at",
              valueType: "dateTime",
              fieldProps: { format: dateSTime },
              sorter: true,
              defaultSortOrder: "descend",
              formItemProps: {
                normalize: normalizeDateRange,
                tooltip: false,
                name: ["range", "created_at"],
              },
              renderFormItem(_, config) {
                return (
                  <DateTimeRangePickerWithTimeControls
                    {...config}
                    name={["range", "created_at"]}
                    fieldProps={{ allowEmpty: [true, true] }}
                  />
                );
              },
            },
            {
              title: "Действие",
              search: false,
              renderText: (_, record) => {
                if (!record.result) return null;
                return (
                  <Typography.Link
                    onClick={() => onResultModal(record.type, record.result)}
                  >
                    Результат
                  </Typography.Link>
                );
              },
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default Page;
