import axios from "@/axios";
import { Longread } from "@/models";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const useLongreadsResource = () => {
  const deleteLongread = useCallback(
    (id: Longread["id"]) => axios.delete(`/api/longreads/${id}`),
    [],
  );

  const getLongread = useCallback(
    (id: Longread["id"], config?: AxiosRequestConfig) =>
      axios
        .get<OrionRestShowResponse<Longread>>(`/api/longreads/${id}`, config)
        .then((res) => res.data.data),
    [],
  );

  const updateLongread = useCallback(
    (id: Longread["id"], values: Partial<Longread>) =>
      axios
        .put<OrionRestUpdateResponse<Longread>>(`/api/longreads/${id}`, values)
        .then((res) => res.data.data),
    [],
  );

  return {
    deleteLongread,
    getLongread,
    updateLongread,
  };
};
