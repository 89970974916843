import { useEffect, useRef, useState } from "react";
import type { ProTableProps as AntProTableProps } from "@ant-design/pro-table";
import { ProTable as AntProTable } from "@ant-design/pro-table";
import { ParamsType } from "@ant-design/pro-provider";
import {
  ActionType,
  ProColumns as AntProColumns,
} from "@ant-design/pro-table/es/typing";
import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  GlobalToken,
  Input,
  Space,
  theme,
  Typography,
} from "antd";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import useQueryState from "@/shared/hooks/use-query-state.ts";
import { ProForm } from "@ant-design/pro-components";
import styled from "styled-components";
import { getCountFilledFormFields } from "@/shared/helpers/get-count-filled-form-fields";

const AntdProTableStyled = styled(AntProTable<any, any>)<
  ProTableProps<any, any> & {
    token: GlobalToken;
  }
>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-pro-card:last-child {
    flex-grow: 1;
    background-color: transparent;
    overflow: hidden;
  }

  .ant-pro-card-body {
    background-color: ${({ token }) => token.colorBgContainer};
    height: auto;
  }

  .ant-pro-table-list-toolbar-right {
    flex-wrap: nowrap;
  }
` as <DataType, Params = Partial<DataType>, ValueType = "text">(
  props: ProTableProps<DataType, Params, ValueType> & { token: GlobalToken },
) => React.ReactElement;

type ProColumns<DataType, ValueType> = Omit<
  AntProColumns<DataType, ValueType>,
  "filterMode" | "valueType"
> & {
  filterMode?: AntProColumns<DataType, ValueType>["filterMode"] | "search";
  valueType?: AntProColumns<DataType, ValueType>["valueType"] | "boolean";
};

type ProTableProps<DataType, Params, ValueType = "text"> = Omit<
  AntProTableProps<DataType, Params, ValueType>,
  "columns"
> & {
  columns?: ProColumns<DataType, ValueType>[];
  hasQueryParams?: boolean | { paramName: string };
  saveFilters?: {
    persistenceKey: string;
  };
};

const flattenObject = (
  obj: Record<string, any>,
  parentKey = "",
  result: Record<string, any> = {},
) => {
  for (const key in obj) {
    if (key === "range") {
      result[key] = obj[key];
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenObject(obj[key], `${parentKey}${key},`, result);
    } else {
      result[parentKey + key] = obj[key];
    }
  }
  return result;
};

const unflattenObject = (obj: Record<string, any>) => {
  const result: Record<string, any> = {};

  for (const key in obj) {
    const keys = key.split(",");
    let current = result;

    keys.forEach((k, index) => {
      if (!current[k]) {
        current[k] = index === keys.length - 1 ? obj[key] : {};
      }
      current = current[k];
    });
  }

  return result;
};

const saveFiltersKeyPostfix = "_save_filter";

function ProTable<
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = "text",
>(props: ProTableProps<DataType, Params, ValueType>) {
  const { hasQueryParams = false, saveFilters } = props;
  const [countFilter, setCountFilter] = useState<number>(0);

  const [form] = ProForm.useForm<DataType>();
  const range = ProForm.useWatch("range", form);

  const { token } = theme.useToken();
  const [tableConfigURL, setTableConfigURL] = useQueryState<
    Record<string, any>
  >(
    typeof hasQueryParams === "object"
      ? hasQueryParams.paramName
      : hasQueryParams
      ? "query"
      : undefined,
    {
      valueType: "object",
    },
  );

  useEffect(() => {
    const defaultFilteredValues = {} as Record<string, any>;
    props.columns?.forEach((column) => {
      if (column.defaultFilteredValue) {
        let dataIndex = Array.isArray(column.dataIndex)
          ? column.dataIndex.join(",")
          : column.dataIndex;

        defaultFilteredValues[dataIndex as string] =
          column.defaultFilteredValue;
      }
    });

    // --------------------------------  Default filters by localStorage -------------------------------- //
    let filtersByLocalStorage = {} as Record<string, any>;
    if (saveFilters) {
      const filters = localStorage.getItem(
        saveFilters.persistenceKey + saveFiltersKeyPostfix,
      );
      if (filters) {
        filtersByLocalStorage = JSON.parse(filters);
      }
    }

    const initialValues =
      hasQueryParams && tableConfigURL
        ? { ...defaultFilteredValues, ...tableConfigURL.filter }
        : { ...defaultFilteredValues, ...filtersByLocalStorage };

    form.setFieldsValue(unflattenObject(initialValues));
    form.submit();
  }, []);

  if (typeof props.actionRef === "function") {
    throw new Error(
      "actionRef as function is not supported. Please use useRef instead",
    );
  }

  let actionRef = useRef<ActionType | undefined | null>();
  if (props.actionRef) {
    actionRef = props.actionRef;
  }

  const defaultProps: AntProTableProps<DataType, Params, ValueType> = {
    columnsState: {
      persistenceType: "localStorage",
    },
    ErrorBoundary: false,
    search: {
      form: form,
      layout: "vertical",
      resetText: "Очистить",
      searchText: "Применить",
      collapseRender: (collapsed) => {
        return (
          <Typography.Link ellipsis>
            Фильтр {countFilter > 0 && `(${countFilter})`}{" "}
            {collapsed ? <DownOutlined /> : <UpOutlined />}
          </Typography.Link>
        );
      },
    },
  };

  const overrideProps: AntProTableProps<DataType, Params, ValueType> = {
    actionRef,
  };

  props.columns?.forEach((column) => {
    // --------------------------------  Default Sorted by Query params -------------------------------- //
    if (hasQueryParams) {
      if (Object.keys(tableConfigURL?.sort ?? {}).length > 0) {
        column.defaultSortOrder =
          tableConfigURL?.sort?.[column.dataIndex as string] ||
          tableConfigURL?.sort?.[column.sorter as string];
      }

      if (typeof props.search === "boolean" && !props.search) {
        const filteredValue = tableConfigURL?.filter?.[
          column.dataIndex as string
        ]
          ? Array.isArray(tableConfigURL?.filter?.[column.dataIndex as string])
            ? tableConfigURL.filter?.[column.dataIndex as string]
            : [
                tableConfigURL.filter?.[column.dataIndex as string],
                "searchMode",
              ]
          : undefined;

        column.filteredValue = filteredValue;

        column.defaultFilteredValue =
          filteredValue ?? column.defaultFilteredValue;
      }
    }

    // --------------------------------  Filters mode: Search -------------------------------- //

    if (column.filterMode === "search") {
      column.filterMode = undefined;

      if (column.filterIcon === undefined) {
        column.filterIcon = <SearchOutlined />;
      }

      if (column.filterDropdown === undefined) {
        column.filterDropdown = ({
          confirm,
          clearFilters,
          selectedKeys,
          setSelectedKeys,
        }) => {
          const onSearch = async () => {
            confirm();
          };

          const onSearchReset = async () => {
            clearFilters?.();
            confirm({ closeDropdown: false });
          };

          return (
            <Space direction={"vertical"} style={{ padding: token.paddingXS }}>
              <Input
                value={selectedKeys[0]}
                placeholder="Введите значение"
                size="small"
                onChange={(e) => {
                  setSelectedKeys(
                    e.target.value ? [e.target.value, "searchMode"] : [],
                  );
                }}
                onPressEnter={() => onSearch()}
              />

              <Flex justify={"space-between"}>
                <Button
                  size={"small"}
                  onClick={onSearchReset}
                  type={"link"}
                  disabled={!selectedKeys[0]}
                >
                  Сбросить
                </Button>
                <Button size={"small"} type={"primary"} onClick={onSearch}>
                  ОК
                </Button>
              </Flex>
            </Space>
          );
        };
        column.onFilter = (value) => {
          return value
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase());
        };
      }
    }

    if (column.valueType === "boolean") {
      column.valueEnum = column.valueEnum ?? {
        true: { text: "Да", status: "success" },
        false: { text: "Нет", status: "default" },
      };

      delete column.valueType;
    }
  });

  const request: typeof props.request = async (params, sort, filter) => {
    const { current, pageSize, keyword, filters, ...rest } = params;

    setCountFilter(getCountFilledFormFields(form.getFieldsValue()));

    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        if (
          Array.isArray(value) &&
          value.length === 2 &&
          value[1] === "searchMode"
        ) {
          //@ts-ignore
          filter[key] = value[0];
        }
      });
    }

    // --------------------------------  It's crunch -------------------------------- //
    if (sort && Object.keys(sort).length) {
      if (
        sort[Object.keys(sort)[0]] !== "descend" &&
        sort[Object.keys(sort)[0]] !== "ascend"
      ) {
        sort = {};
      }
      const column = props.columns?.find((column) => {
        return column.dataIndex === Object.keys(sort)[0];
      });
      if (column && typeof column.sorter === "string") {
        sort[column.sorter] = sort[Object.keys(sort)[0]];
        delete sort[Object.keys(sort)[0]];
      }
    }

    // ---------------------------------------------------------------- //

    filter = flattenObject({ ...filter, ...rest });

    for (const key in filter) {
      if (
        filter[key] === undefined ||
        filter[key] === null ||
        filter[key]?.length === 0
      ) {
        delete filter[key];
      }
    }

    // --------------------------------  Default filters by localStorage -------------------------------- //

    if (saveFilters) {
      localStorage.setItem(
        saveFilters.persistenceKey + saveFiltersKeyPostfix,
        JSON.stringify(filter),
      );
    }

    // -------------------------------- Default filters and sort by  Query params -------------------------------- //

    if (hasQueryParams) {
      const filterUrl: Record<string, any> = {};
      for (const key in filter) {
        if (filter[key]) {
          filterUrl[key] = filter[key];
        }
      }
      setTableConfigURL({ filter: filterUrl, sort });
    }
    if (!props.request) throw new Error("request is undefined");
    return props.request(params, sort, filter);
  };

  props = deepmergeProTableProps(
    defaultProps as any,
    props as any,
    overrideProps as any,
  );

  return (
    <AntdProTableStyled<DataType, Params, ValueType>
      {...(props as any)}
      request={props.request ? request : undefined}
      token={token}
    />
  );
}

export default ProTable;
export type { ProTableProps };
