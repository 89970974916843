import { Test } from "@/models";

export const DEFAULT_TEST_VALUES: Partial<Test> = {
  type: "intermediate",
  show_results_format: "hidden",
  is_shuffles_questions: false,
  is_questions_database: false,
  is_creates_exams_after_complete: false,
  is_limits_testing_time: false,
  is_limits_testing_attempts: false,
  questions: [],
  success_criteria: 100,
};
