import React, { useEffect } from "react";
import { File, Video as BaseVideo } from "@/models";
import { FilePreview, downloadFile } from "@/entities/file";
import { Flex, Grid, Space, Typography, UploadFile } from "antd";
import { ProFormUploadButton } from "@ant-design/pro-components";
import styled from "styled-components";

const { useBreakpoint } = Grid;

const PlayerWrapper = styled.div`
  overflow: hidden;

  .video-react-controls-enabled {
    padding: 0 !important;
    height: 100% !important;
    overflow: hidden;
  }

  .video-react-video {
    position: static !important;
  }
`;
interface Video extends BaseVideo {
  attachment_files: (File & UploadFile)[];
}

type LessonDescriptionsProps = {
  dataSource: Video;
};

const LessonDescriptions: React.FC<LessonDescriptionsProps> = ({
  dataSource,
}) => {
  const screens = useBreakpoint();

  useEffect(() => {
    dataSource?.attachment_files?.forEach((file) => (file.status = "done"));
  }, []);

  return (
    <Flex
      vertical
      gap={8}
      style={{
        height: `calc(100% ${!screens.lg ? "- 32px - 8px" : ""})`,
        width: "100%",
        overflow: "auto",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {dataSource.name}
      </Typography.Title>
      <Typography.Text>{dataSource.description}</Typography.Text>
      {dataSource.content_file ? (
        <PlayerWrapper
          style={{
            minHeight: `${
              dataSource?.attachment_files?.length ? "500px" : "auto"
            }`,
          }}
        >
          <FilePreview
            key={dataSource.content_file.id}
            file={dataSource.content_file as any}
          />
        </PlayerWrapper>
      ) : null}
      {dataSource.attachment_files &&
        dataSource.attachment_files.length > 0 && (
          <Space direction={"vertical"}>
            <Typography.Text style={{ margin: 0 }}>
              Прикреплённые файлы
            </Typography.Text>
            <ProFormUploadButton
              value={dataSource?.attachment_files}
              max={0}
              buttonProps={{ disabled: true, hidden: true }}
              fieldProps={{
                listType: "picture-card",
                onDownload: downloadFile,
                showUploadList: {
                  showDownloadIcon: true,
                  showRemoveIcon: false,
                  showPreviewIcon: false,
                },
              }}
            />
          </Space>
        )}
    </Flex>
  );
};

export default LessonDescriptions;
