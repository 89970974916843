import React from "react";
import { ProFormDigitRange } from "@ant-design/pro-components";
import { MemberSelect } from "@/features/member";
import PoolSelect from "@/entities/pool/ui/select";
import { useTranslation } from "react-i18next";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";

const EducationStatisticsByDepartmentFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <OrgStructureTreeSelect
        label={t("Подразделение / Должность")}
        name={["member", "positions", "position_id"]}
        colProps={{ span: 8 }}
        searchUser
        labelWithPath
        fieldProps={{ multiple: true }}
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <ProFormDigitRange
        label="Прогресс прохождения"
        colProps={{ span: 8 }}
        name={["range", "progress_percent"]}
        fieldProps={{
          max: 100,
          min: 0,
        }}
        separator="-"
        placeholder={["от", "до"]}
      />
    </>
  );
};

export default EducationStatisticsByDepartmentFormFields;
