export const removeDuplicates = (arr: Record<string, any>[], key: string) => {
  const seen = new Map();

  return arr.filter((item) => {
    const normalizedValue = String(item[key]).trim().toLowerCase();
    if (seen.has(normalizedValue)) {
      return false;
    }
    seen.set(normalizedValue, true);
    return true;
  });
};
