import React, { Ref, useEffect, useImperativeHandle } from "react";
import { Tree as AntTree, Flex, Spin, TreeProps, Typography } from "antd";
import axios from "@/axios";
import useSWR from "swr";
import TreeNodeIcon from "@/entities/tree-node/ui/icon";
import { DataNode as BaseDataNode } from "antd/es/tree";
import { TreeNode } from "@/models";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Course } from "@/entities/course";
import type RcTree from "rc-tree";
import styled from "styled-components";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { SpinWrapper } from "@/shared/ant-design/spin/ui/spin-wrapper";

type DataNode = BaseDataNode & {
  meta: {
    treeNode: TreeNode;
  };
};

const Tree = styled(AntTree<DataNode>)`
  .ant-tree-list-scrollbar {
    display: none;
  }
  .ant-tree-list-holder {
    overflow-y: auto !important;
    overflow-anchor: none;
  }
  .ant-tree-list-holder-inner {
    padding-block: 6px;
  }
`;

type SWRHook = {
  mutate: () => Promise<any>;
  getData: () => any;
  scrollTo: (key: number) => void;
};

type CourseEmbedsTreeProps = Partial<TreeProps<DataNode>> & {
  id: string | number;
  swrRef?: Ref<SWRHook>;
  selectedTreeNode?: TreeNode;
  loading?: boolean;
};

const CourseEmbedsTree: React.FC<CourseEmbedsTreeProps> = ({
  id,
  swrRef,
  selectedTreeNode,
  loading,
  ...treeProps
}) => {
  const { parentHeight, ref } = useParentHeight();

  const scrollTo: SWRHook["scrollTo"] = (key) => {
    setTimeout(() => {
      if (treeRef.current && key) {
        treeRef.current.scrollTo({ key, align: "auto" });
      }
    }, 500);
  };

  useEffect(() => {
    const key = treeProps?.selectedKeys?.[0];
    scrollTo(Number(key));
  }, []);

  const treeRef = React.useRef<RcTree>(null);
  const { data, isLoading, mutate } = useSWR(
    `/api/courses/${String(id)}?include=tree_node,tree_node.tree`,
    async (url) => {
      return axios
        .get<OrionRestShowResponse<Course>>(url)
        .then((res) => res.data.data);
    },
  );

  useImperativeHandle<any, any>(swrRef, () => ({
    mutate,
    getData: () => data,
    scrollTo: scrollTo,
  }));

  if (isLoading)
    return (
      <Flex align="center" justify="center" style={{ height: "100%" }}>
        <Spin />
      </Flex>
    );

  const dataMapper = (node: any): DataNode => {
    return {
      key: node.id,
      title: <Typography.Text>{node.is_an.name}</Typography.Text>,
      icon: <TreeNodeIcon type={node.is_an_type} />,
      children: node.tree.map(dataMapper),
      meta: { treeNode: node },
    };
  };

  const treeData: TreeProps<DataNode>["treeData"] =
    data!.tree_node!.tree!.map(dataMapper);

  return (
    <SpinWrapper>
      <Spin spinning={loading}>
        <div ref={ref} style={{ height: "100%", width: "100%" }}>
          <Tree
            height={parentHeight - 16}
            showIcon
            ref={treeRef}
            treeData={treeData}
            {...treeProps}
          />
        </div>
      </Spin>
    </SpinWrapper>
  );
};

export default CourseEmbedsTree;
export type { CourseEmbedsTreeProps, SWRHook, DataNode };
