import axios from "@/axios";
import { message } from "antd";
import { UploadFile } from "antd/lib";
import fileDownload from "js-file-download";

export const downloadFile = async (file: UploadFile) => {
  axios
    .get(file.url!, { responseType: "blob" })
    .then((response) => {
      fileDownload(response.data, file.name);
    })
    .catch(() => {
      message.error("Ошибка загрузки файла");
    });
};
