import useFeatures from "@/entities/features/lib/use";
import useMe from "@/entities/me/lib/use";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import RolesSearchSelect from "@/entities/roles/select";
import useSpace from "@/entities/space/lib/use";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component";
import ProFormSnils from "@/shared/ant-design-pro-components/form-snils/ui/component";
import { date, dateNormalize } from "@/shared/dayjs/lib/formats";
import emailPattern from "@/shared/patterns/lib/email-pattern";
import {
  ProForm,
  ProFormCheckbox,
  ProFormDatePicker,
  ProFormDigit,
  ProFormGroup,
  ProFormList,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Col, Flex, message, Row, theme } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Member, MemberAccessDepartment, RolePermission } from "@/models";
import { useEffect, useState } from "react";
import {
  statusValueEnum,
  AvatarUpload,
  useMembersResource,
} from "@/entities/member";
import axios from "@/axios";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { RestProps } from "@/shared/rest/lib/types";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";

type PersonalFormFieldsProps = {
  type: RestProps<Member>["type"];
  memberId?: Member["id"];
  mode: "default" | "personal";
  onDeleteAccessedDepartment: (id: MemberAccessDepartment["id"]) => void;
  setLoadingState: (isLoading: boolean) => void;
  employeeEditableOrgStructure: boolean;
};

const PersonalFormFields: React.FC<PersonalFormFieldsProps> = ({
  type,
  mode,
  memberId,
  onDeleteAccessedDepartment,
  employeeEditableOrgStructure,
  setLoadingState,
}) => {
  const form = ProForm.useFormInstance();
  const { token } = theme.useToken();
  const features = useFeatures();
  const space = useSpace();
  const member = useMe();

  const accessedDepartments = ProForm.useWatch("accessed_departments", form);
  const isRestrictedAccess = ProForm.useWatch("is_restricted_access", form);
  const roleId = ProForm.useWatch("role_id", form);
  const [roleHasPermissions, setRoleHasPermissions] = useState(false);

  const { generateLoginMember } = useMembersResource();

  const generateLogin = async () => {
    const generated = await generateLoginMember(form.getFieldsValue()).catch(
      (err) => {
        if (err.response.status === 422) {
          message.error(
            err.response.data.message ?? "Ошибка при генерации логина",
          );
          setValidationErrorsToFormFields(form, err.response.data.errors);
        }

        return "";
      },
    );
    form.setFieldsValue({ username: generated });
  };

  useEffect(() => {
    if (
      roleId &&
      member.permissions.includes("role:view") &&
      features.isEnabled("restrict_accesses")
    ) {
      setLoadingState(true);
      axios
        .post<OrionRestIndexResponse<RolePermission>>(
          `/api/role-permissions/search`,
          {
            filters: [{ field: "role_id", operator: "=", value: roleId }],
          },
        )
        .then(({ data }) => setRoleHasPermissions(data.data.length > 0))
        .finally(() => setLoadingState(false));
    }
  }, [roleId]);

  return (
    <Row
      gutter={token.paddingXS}
      style={{ width: "100%", height: "calc(100% - 32px)" }}
    >
      <Col
        xs={24}
        sm={24}
        md={type === "update" ? 12 : 24}
        style={{ height: "100%" }}
      >
        <ProFormDigit hidden name={"avatar_image_file_id"} />
        <ProFormText
          label={"Фамилия"}
          name={"last_name"}
          rules={[
            { max: 255, required: true },
            {
              pattern: /^[\p{L}-]*$/u,
              message: "Некорректное значение поля Фамилия",
            },
          ]}
          transform={(value) => value.trim()}
        />

        <ProFormText
          label={"Имя"}
          name={"first_name"}
          transform={(value) => value.trim()}
          rules={[
            { max: 255, required: true },
            {
              pattern: /^[\p{L}-]*$/u,
              message: "Некорректное значение поля Имя",
            },
          ]}
        />

        <ProFormText
          transform={(value) => value.trim()}
          label={"Отчество"}
          name={"middle_name"}
          rules={[
            { max: 255, required: false },
            {
              pattern: /^[\p{L}-]*$/u,
              message: "Некорректное значение поля Отчество",
            },
          ]}
        />
        <ProFormDatePicker
          label="Дата рождения"
          name="birth_date"
          rules={[
            { required: features.isEnabled("apk_usernames_and_passwords") },
          ]}
          hidden={
            !features.isEnabled("apk_usernames_and_passwords") &&
            type !== "update"
          }
          fieldProps={{
            format: date,
            style: { width: "100%" },
            needConfirm: false,
          }}
          normalize={dateNormalize}
        />
        <ProFormRadio.Group
          label="Пол"
          name="gender"
          rules={[{ required: false }]}
          hidden={type !== "update"}
          options={[
            { value: "male", label: "Мужской" },
            { value: "female", label: "Женский" },
          ]}
          fieldProps={{ optionType: "button", buttonStyle: "solid" }}
        />

        <ProFormText
          label="Гражданство"
          name="citizenship"
          rules={[{ required: false, max: 255 }]}
          hidden={type !== "update"}
        />
        {type === "update" && <AvatarUpload />}
      </Col>

      <Col
        xs={24}
        sm={24}
        md={type === "update" ? 12 : 24}
        style={{ height: "100%" }}
      >
        <ProFormSnils
          label={"СНИЛС"}
          name={"snils"}
          rules={[{ required: false }]}
        />
        <ProFormPhone
          label={"Телефон"}
          name={"phone"}
          rules={[{ required: false }]}
          hidden={type !== "update"}
        />
        <ProFormText
          label={"Почта"}
          name={"email"}
          rules={[
            {
              required: space.authentication_types.includes("email+password"),
            },
            {
              type: "email",
              message: "Атрибут email должен быть действительным email",
            },
            { pattern: emailPattern, message: "Некорректный формат почты" },
          ]}
        />
        <Flex vertical style={{ position: "relative", width: "100%" }}>
          <Button
            type="link"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
              padding: 0,
              paddingBottom: 4,
            }}
            onClick={generateLogin}
          >
            Сгенерировать
          </Button>
          <ProFormText
            label={"Логин"}
            name={"username"}
            rules={[
              {
                required:
                  space.authentication_types.includes("username+password"),
                max: 255,
              },
            ]}
          />
        </Flex>
        {(member.permissions.includes("member:update") ||
          member.permissions.includes("member:create")) &&
          mode === "default" && (
            <ProFormSelect
              label="Статус"
              name="status"
              rules={[{ required: type === "update" }]}
              hidden={type !== "update"}
              valueEnum={statusValueEnum}
            />
          )}
        <RolesSearchSelect
          label="Роль"
          name="role_id"
          hidden={!member.permissions.includes("member:manage_accesses")}
          rules={[
            {
              required: member.permissions.includes("member:manage_accesses"),
            },
          ]}
        />

        {features.isEnabled("restrict_accesses") &&
          roleHasPermissions &&
          member.permissions.includes("member:manage_accesses") && (
            <>
              <ProFormCheckbox
                rules={[{ required: true }]}
                name="is_restricted_access"
              >
                Изоляция доступа по подразделениям
              </ProFormCheckbox>
              {isRestrictedAccess && (
                <ProFormList
                  label={"Доступ к подразделениям"}
                  creatorButtonProps={{
                    creatorButtonText: "Добавить доступ к подразделению",
                    style: {
                      marginLeft: token.paddingXXS,
                      width: `calc(100% - ${token.paddingXXS}px * 2)`,
                    },
                  }}
                  name="accessed_departments"
                  creatorRecord={{
                    member_id: type === "update" ? memberId : undefined,
                  }}
                  copyIconProps={false}
                  deleteIconProps={false}
                  onAfterRemove={async (index) => {
                    if (
                      accessedDepartments &&
                      typeof index === "number" &&
                      accessedDepartments[index].id
                    ) {
                      onDeleteAccessedDepartment(accessedDepartments[index].id);
                    }
                  }}
                >
                  {(_, index, action) => {
                    const department = accessedDepartments?.[index];

                    const disabled =
                      member.is_restricted_access && department?.department_id
                        ? !member.accessed_departments.includes(
                            department.department_id,
                          )
                        : employeeEditableOrgStructure;
                    false;

                    return (
                      <ProFormGroup>
                        <ProFormDigit name={"id"} hidden />
                        <ProFormDigit name={"member_id"} hidden />
                        <OrgStructureTreeSelect
                          colProps={{ span: 22 }}
                          disabled={disabled}
                          departmentOnly
                          rules={[
                            { required: true },
                            {
                              validator: async (_, value) => {
                                if (
                                  form
                                    .getFieldValue("accessed_departments")
                                    .filter(
                                      (d: any) => d.department_id === value,
                                    ).length > 1
                                ) {
                                  return Promise.reject(
                                    "Подразделение дублируется",
                                  );
                                }
                              },
                            },
                          ]}
                          fieldProps={{
                            onChange: (value) => {
                              if (value && department.id) {
                                onDeleteAccessedDepartment(department.id);
                                action.setCurrentRowData({
                                  id: null,
                                });
                              }
                            },
                          }}
                          labelWithPath
                          name={"department_id"}
                          style={{ width: `calc(100% - ${token.padding}px)` }}
                        />
                        <Col span={2} style={{ textAlign: "right" }}>
                          <Button
                            disabled={disabled}
                            icon={<DeleteOutlined />}
                            danger
                            type="dashed"
                            title="Удалить"
                            onClick={() => action.remove(index)}
                          />
                        </Col>
                      </ProFormGroup>
                    );
                  }}
                </ProFormList>
              )}
            </>
          )}
        {type === "create" && <AvatarUpload />}
      </Col>
    </Row>
  );
};

export default PersonalFormFields;
