import { Question } from "../lib/question";

export const questionTestTypeValueEnum: Record<
  Question["type"],
  { text: string }
> = {
  multiple: { text: "Множественный выбор" },
  single: { text: "Одиночный выбор" },
  short_answer: { text: "Краткий ответ" },
  matching: { text: "Сопоставление" },
  sequence: { text: "Восстановление последовательности" },
};
