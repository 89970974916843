import React from "react";
import {
  ProFormDigit,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { MAX_CONTENT_FILES_NUMBER } from "@/entities/file/lib/constants";
import { UploadWithPreview, validateContentFile } from "@/entities/file";

type Props = {};

export const ScormFormFields: React.FC<Props> = ({}) => {
  return (
    <>
      <ProFormDigit name="course_id" label="ID курса" hidden />
      <ProFormDigit name="id" hidden />
      <ProFormText
        name="name"
        label="Название"
        rules={[{ required: true, max: 255 }]}
      />
      <ProFormTextArea name="description" label="Описание" />
      <UploadWithPreview
        name={"package_file"}
        label={"Пакет"}
        title={"Загрузите файл"}
        description={"Перетащите файл в эту область или нажмите на нее"}
        rules={[
          { required: true },
          () => ({
            validator(_, files) {
              if (files?.[0]) {
                return validateContentFile(files[0], "zip");
              } else {
                return Promise.reject();
              }
            },
          }),
        ]}
        max={MAX_CONTENT_FILES_NUMBER}
        fieldProps={{
          listType: "picture",
          accept: ".zip",
        }}
      />
      <ProFormText name="status" hidden />
      <ProFormDigit name="package_file_id" hidden />
    </>
  );
};
