export const validateContentFile = (
  file: File,
  type: "pdf" | "video" | "zip",
) => {
  if (!file || file.type === "") {
    return Promise.reject("Прикрепите файл с указанным расширением");
  }

  if (file.name.length > 255) {
    return Promise.reject("Слишком длинное название файла");
  }

  const isPdf = file.type === "application/pdf";
  const isVideoWebm = file.type === "video/webm";
  const isZip = ["application/zip", "application/x-zip-compressed"].includes(
    file.type,
  );
  const isVideo = file.type.split("/")[0] === "video";

  if (type === "pdf" && !isPdf) {
    return Promise.reject("Недопустимый тип файла: Можно загружать только PDF");
  }

  if (type === "video") {
    if (isVideoWebm) {
      return Promise.reject(
        "Недопустимый тип формат видео: Нельзя загружать файлы в формате WebM. Пожалуйста, выберите другой формат видео.",
      );
    }
    if (!isVideo) {
      return Promise.reject(
        "Недопустимый тип файла: Можно загружать только видео",
      );
    }
  }

  if (type === "zip" && !isZip) {
    return Promise.reject("Недопустимый тип файла: Можно загружать только ZIP");
  }

  return Promise.resolve();
};
