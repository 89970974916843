import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Test } from "@/models";
import { Grid, Typography } from "antd";
import { toTime } from "@/shared/dayjs/lib/formats";

type TestDescriptionsProps = ProDescriptionsProps<Test>;

const TestDescriptions: React.FC<TestDescriptionsProps> = ({ ...props }) => {
  const screens = Grid.useBreakpoint();

  return (
    <ProDescriptions<Test> {...props}>
      <ProDescriptions.Item
        dataIndex={"name"}
        span={24}
        render={(content) => (
          <Typography.Title level={5} style={{ margin: 0 }}>
            {content}
          </Typography.Title>
        )}
      />
      <ProDescriptions.Item
        dataIndex={"description"}
        span={24}
        render={(content) => {
          if (content) {
            return <Typography.Text>{content}</Typography.Text>;
          }
          return (
            <ProDescriptions<Test>
              dataSource={props.dataSource}
              column={1}
              layout={!screens.sm ? "vertical" : "horizontal"}
              columns={[
                {
                  label: "Критерий успешного прохождения",
                  style: { padding: 0 },
                  tooltip: "Минимальный процент успешно отвеченных вопросов",
                  renderText: (_, { success_criteria }) =>
                    `${Number(success_criteria)}%`,
                },
                {
                  label: "Максимальное количество попыток",
                  style: { padding: 0 },
                  renderText: (
                    _,
                    { is_limits_testing_attempts, testing_attempts_limit },
                  ) =>
                    is_limits_testing_attempts
                      ? testing_attempts_limit
                      : "Не ограниченно",
                },
                {
                  label: "Максимальное количество времени",
                  style: { padding: 0 },
                  tooltip:
                    "Если есть ограничение, то при старте тестирования будет запущен таймер обратного отсчёта, по истечении которого тестирование автоматически завершится системой",
                  renderText: (
                    _,
                    { is_limits_testing_time, testing_time_limit },
                  ) =>
                    is_limits_testing_time
                      ? toTime(testing_time_limit!)
                      : "Не ограниченно",
                },
              ]}
            />
          );
        }}
      />
    </ProDescriptions>
  );
};
export default TestDescriptions;
export type { TestDescriptionsProps };
