export const getCountFilledFormFields = (
  values: Record<string, any>,
): number => {
  let count = 0;

  for (const key in values) {
    const value = values[key];

    if (
      value === undefined ||
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
    ) {
      continue;
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      count += getCountFilledFormFields(value);
    } else {
      count += 1;
    }
  }

  return count;
};
