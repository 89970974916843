import React from "react";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal, Space, Typography } from "antd";
import { ProForm } from "@ant-design/pro-components";
import RolesSearchSelect from "./select";
import { Member } from "@/models";
import { useMembersResource } from "../member";

type RolesDeleteButtonProps = {
  recordKey?: number;
  onAfterDelete?: () => void;
  onBeforeDelete?: () => boolean;
};

const RolesDeleteButton: React.FC<RolesDeleteButtonProps> = ({
  recordKey,
  onAfterDelete,
  onBeforeDelete,
}) => {
  const [modal, modalHolder] = Modal.useModal();
  const [form] = ProForm.useForm();
  const { getMembers, batchUpdateMembers } = useMembersResource();

  const getAllUsers = async () => {
    let members: Member[] = [];
    let currentPage = 1;
    const pageSize = 50;

    while (true) {
      try {
        const response = await getMembers(
          {
            filters: [
              {
                field: "role_id",
                operator: "=",
                value: recordKey,
              },
            ],
          },
          { params: { page: currentPage, limit: pageSize } },
        );

        members = members.concat(response);

        if (response.length < pageSize) {
          break;
        }

        currentPage++;
      } catch (error) {
        message.error("Ошибка при получении пользователей");
        return [];
      }
    }

    return members;
  };

  const onDelete = async () => {
    if (onBeforeDelete?.()) {
      return;
    }
    modal.confirm({
      title: "Удалить роль",
      content: (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography.Text type="secondary">
            Выбранная роль будет удалена из системы, пользователям будет
            присвоена выбранная ниже роль.
          </Typography.Text>
          <ProForm form={form} submitter={false} grid preserve={false}>
            <RolesSearchSelect
              name={"role_id"}
              isDefaultEmployee={true}
              filteredId={recordKey}
              colProps={{ span: 24 }}
              rules={[{ required: true }]}
            />
          </ProForm>
        </Space>
      ),
      icon: null,
      width: 600,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      cancelText: "Отменить",
      closable: true,
      onOk: async () => {
        try {
          await form.validateFields();
        } catch (error) {
          return Promise.reject(error);
        }

        const members = await getAllUsers();

        if (members.length > 0) {
          const resources = members.reduce(
            (acc: { [key: string]: Partial<Member> }, member) => {
              acc[member.id] = {
                ...member,
                role_id: form.getFieldValue("role_id"),
              };
              return acc;
            },
            {},
          );
          await batchUpdateMembers(resources).catch((err) => {
            message.error(
              err.response.data.message ??
                "Не удалось присвоить выбранную роль пользователю",
            );
          });
        }

        return await axios
          .request({
            method: "DELETE",
            url: `/api/roles/${recordKey}`,
          })
          .then(() => {
            onAfterDelete?.();
            message.success("Выбранные роли были удалены");
          })
          .catch(() => {
            message.error(
              "Произошла ошибка при попытке удалить выбранные роли",
            );
          });
      },
    });
  };

  return (
    <>
      {modalHolder}
      <Button type={"primary"} danger onClick={onDelete} disabled={!recordKey}>
        Удалить
      </Button>
    </>
  );
};

export default RolesDeleteButton;
export type { RolesDeleteButtonProps };
