import React, { ReactElement } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/default-layout";
import { Flex, Space, Grid } from "antd";

type PreviewPdfProps = {
  src: string;
};

const { useBreakpoint } = Grid;

export const DefaultPreviewPdf: React.FC<PreviewPdfProps> = ({
  src,
  ...props
}) => {
  const screens = useBreakpoint();

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <Flex
            style={{
              width: "100%",
            }}
            justify="space-between"
          >
            <Space>
              <ShowSearchPopover />
              <Flex gap={2} align="center">
                <CurrentPageInput />
                /
                <NumberOfPages />
              </Flex>
            </Space>
            <Space size={0} direction="horizontal">
              <ZoomOut />

              {screens.sm && <Zoom />}

              <ZoomIn />
            </Space>

            <Flex align="center">
              {screens.sm && (
                <>
                  <GoToPreviousPage />

                  <GoToNextPage />
                </>
              )}

              <div style={{ padding: "0 2px" }}>
                <EnterFullScreen />
              </div>
            </Flex>
          </Flex>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <Viewer {...props} fileUrl={src} plugins={[defaultLayoutPluginInstance]} />
  );
};

export type { PreviewPdfProps };
