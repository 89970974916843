import axios from "@/axios";
import { Scorm } from "@/models";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const useScormsResource = () => {
  const deleteScorm = useCallback(
    (id: Scorm["id"]) => axios.delete(`/api/scorms/${id}`),
    [],
  );

  const getScorm = useCallback(
    (id: Scorm["id"], config?: AxiosRequestConfig) =>
      axios
        .get<OrionRestShowResponse<Scorm>>(`/api/scorms/${id}`, config)
        .then((res) => res.data.data),
    [],
  );

  const updateScorm = useCallback(
    (id: Scorm["id"], values: Partial<Scorm>, config?: AxiosRequestConfig) =>
      axios
        .put<OrionRestUpdateResponse<Scorm>>(
          `/api/scorms/${id}`,
          values,
          config,
        )
        .then(async (res) => res.data.data),
    [],
  );

  return {
    deleteScorm,
    getScorm,
    updateScorm,
  };
};
