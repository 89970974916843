import { dateSTime } from "@/shared/dayjs/lib/formats";
import { MemberSelect } from "@/features/member";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { useTranslation } from "react-i18next";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";

const LatestAddedMembersFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="single"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"id"}
      />
      <OrgStructureTreeSelect
        label={t("Подразделение / Должность")}
        name={["positions", "position_id"]}
        colProps={{ span: 8 }}
        labelWithPath
        searchUser
        fieldProps={{ multiple: true }}
      />
      <DateTimeRangePickerWithTimeControls
        label="Дата добавления в систему"
        colProps={{ span: 8 }}
        name={["range", "created_at"]}
        fieldProps={{ allowEmpty: [true, true] }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default LatestAddedMembersFormFields;
