import React from "react";
import useSWR from "swr";
import axios from "@/axios.ts";
import useMe from "@/entities/me/lib/use.tsx";
import useFeatures from "@/entities/features/lib/use.ts";
import { message, Modal } from "antd";
import * as Sentry from "@sentry/react";
import useSentryUserFeedbackModal from "@/shared/sentry/lib/use-user-feedback-modal";

const STORAGE_AVAILABILITY_CHECKER_LAST_CHECK_KEY =
  "storage-availability-checker-last-check";

const StorageAvailabilityChecker: React.FC = () => {
  const me = useMe();
  const features = useFeatures();
  const [modal, modalHolder] = Modal.useModal();
  const [sentryUserFeedbackModal, sentryUserFeedbackModalHolder] =
    useSentryUserFeedbackModal();

  const switchMemberProxy = () => {
    axios
      .put(`/api/members/${me.id}`, {
        ...me,
        is_storage_proxy_enabled: !me.is_storage_proxy_enabled,
      })
      .then(() => {
        me.refresh();
      })
      .catch((error) => {
        message.error("Ошибка при переключении прокси");
        throw error;
      });
  };

  const { error } = useSWR(
    "storage-availability-checker",
    async () => {
      if (!features.isEnabled("storage_proxy")) {
        return;
      }

      return axios.get("/api/.well-known/storage").then(async (res) => {
        const available = await axios
          .get(res.data.test_file.url)
          .then(() => true)
          .catch(() => false);

        if (available && me.is_storage_proxy_enabled) {
          /** Отображать только раз в час */
          const lastCheck = localStorage.getItem(
            STORAGE_AVAILABILITY_CHECKER_LAST_CHECK_KEY,
          );
          if (!lastCheck || Date.now() - parseInt(lastCheck, 10) > 3600000) {
            localStorage.setItem(
              STORAGE_AVAILABILITY_CHECKER_LAST_CHECK_KEY,
              Date.now().toString(),
            );
            switchMemberProxy();
          }
          Sentry.captureMessage(
            "User has storage proxy enabled, but browser can access storage directly",
          );
        } else if (!available && !me.is_storage_proxy_enabled) {
          switchMemberProxy();
          Sentry.captureMessage(
            "User has storage proxy disabled, but browser can't access storage directly",
          );
        } else if (!available) {
          modal.confirm({
            closable: true,
            width: 600,
            onOk: () => {
              sentryUserFeedbackModal.open();
            },
            okText: "Обратиться в поддержку",
            cancelText: "Закрыть",
            title: "Возникли проблемы с доступом к хранилищу файлов",
            content: (
              <ol>
                <li>
                  Попробуйте отключить VPN или прокси, если они используются
                </li>
                <li>
                  Проверьте настройки вашего браузера или антивируса, которые
                  могут блокировать загрузку файлов
                </li>
                <li>
                  Если данное сообщение повторяется, свяжитесь с нашей
                  поддержкой для получения дополнительной помощи
                </li>
              </ol>
            ),
          });
        }
      });
    },
    {
      revalidateOnFocus: false,
    },
  );

  if (error) throw error;

  return (
    <>
      {modalHolder}
      {sentryUserFeedbackModalHolder}
    </>
  );
};
export default StorageAvailabilityChecker;
