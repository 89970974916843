import { Tag, Tooltip } from "antd";
import React from "react";
import type { ProSchemaValueEnumType } from "@ant-design/pro-provider";
import { PoolParticipant } from "@/models.ts";

const statuses: Record<
  PoolParticipant["status"],
  ProSchemaValueEnumType & { label?: string }
> = {
  not_started: {
    text: (
      <Tag color="red">
        <Tooltip title={"Участник потока не начал обучение"}>Не начат</Tooltip>
      </Tag>
    ),
    label: "Не начат",
  },
  in_progress: {
    text: (
      <Tag color="blue">
        <Tooltip title={"Участник потока находится в процессе обучения"}>
          В процессе
        </Tooltip>
      </Tag>
    ),
    label: "В процессе",
  },
  completed: {
    text: (
      <Tag color="green">
        <Tooltip title={"Участник потока завершил обучение"}>Завершен</Tooltip>
      </Tag>
    ),
    label: "Завершен",
  },
  failed: {
    text: (
      <Tag color="error">
        <Tooltip title={"Участник потока не прошел обучение"}>
          Провалено
        </Tooltip>
      </Tag>
    ),
    label: "Неудача",
  },
};

export default statuses;
