import React, { useCallback, useEffect } from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Commission, Course } from "@/models";
import useSWR from "swr";
import { debounce } from "lodash";
import { Button, Flex, Skeleton } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

type CommissionSelectProps = Partial<ProFormSelectProps> & {
  listName?: ProFormSelectProps["name"];
  courseId?: Course["id"];
  withExternalLink?: boolean;
};

export const CommissionSelect: React.FC<CommissionSelectProps> = ({
  fieldProps,
  listName,
  withExternalLink,
  courseId,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();
  const value = ProForm.useWatch(listName ?? selectProps.name, form);

  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState();
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const {
    data: commissions,
    isLoading,
    error,
  } = useSWR(
    ["/api/commissions/search", searchValue, initialValue, courseId],
    async ([url]) => {
      const filters: any = [];

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      if (courseId) {
        filters.push({
          field: "course_commissions.course_id",
          operator: "=",
          value: courseId,
        });
      }

      const res = await axios
        .post<{ data: Commission[] }>(url, {
          filters,
        })
        .then(async (res) => {
          const data: any = [];

          res.data.data.forEach((course) => {
            data.push({
              label: course.name,
              value: course.id,
            });
          });

          if (
            initialValue &&
            !data.some((option: any) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/commissions/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: data.data.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );

  useEffect(() => {
    if (!isLoading) setIsFirstRender(false);
  }, [isLoading]);

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );
  const TabsItemChildrenWrapper: React.FC<{ children: React.ReactNode }> =
    useCallback(
      ({ children }) => {
        const Wrapper = withExternalLink ? Flex : React.Fragment;
        return <Wrapper>{children}</Wrapper>;
      },
      [withExternalLink],
    );

  if (error) throw error;

  return (
    <TabsItemChildrenWrapper>
      <ProFormSelect
        style={{ width: "100%" }}
        options={commissions}
        showSearch
        fieldProps={{
          onSearch: onSearch,
          loading: isLoading,
          ...fieldProps,
        }}
        formItemProps={{ style: { width: "100%" } }}
        {...selectProps}
      >
        {isFirstRender && <Skeleton.Input active block />}
      </ProFormSelect>
      {value && withExternalLink && (
        <Button
          style={{ position: "relative" }}
          type="link"
          onClick={() => navigate(`/manage/commissions/${value}`)}
        >
          <ExportOutlined />
        </Button>
      )}
    </TabsItemChildrenWrapper>
  );
};

export type { CommissionSelectProps };
