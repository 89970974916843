import { Button, Divider, Dropdown, MenuProps, Space, Spin } from "antd";
import { ProForm, ProFormText, ProList } from "@ant-design/pro-components";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Flex } from "antd";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSWRInfinite from "swr/infinite";
import SortableItem from "@/entities/org-structure/ui/sortable-item";
import { TreeNode } from "@/models";
import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { debounce } from "lodash";
import useOrgStructureContainer from "@/entities/org-structure/lib/use";
import OrgStructureEditForm from "@/entities/org-structure/ui/form";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { OrgStructureContainersContextValue } from "../lib/container-model";
import useMe from "@/entities/me/lib/use";

type SWRHook = {
  mutate: () => Promise<any>;
  sortDisabled: (disable: boolean) => void;
};

type OrgStructureTreeListProps = {
  swrRef?: Ref<SWRHook>;
  parentTreeNodeId: TreeNode["parent_tree_node_id"];
  setContainers: (id: any, items: TreeNode[], parentsIds: number[]) => void;
  parentsIds: any[];
  disableDrag: boolean;
};

const OrgStructureTreeList: React.FC<OrgStructureTreeListProps> = ({
  parentTreeNodeId,
  swrRef,
  setContainers,
  parentsIds,
  disableDrag,
}) => {
  const [form] = ProForm.useForm();
  const containers = useOrgStructureContainer();
  const { t } = useTranslation();

  const member = useMe();

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [activeId, setActiveId] = useState<any>(null);

  const [createOrgStructureType, setCreateOrgStructureType] = useState<
    "department" | "position"
  >("department");

  const swrRefChild = React.useRef<SWRHook>(null);

  const { data, error, isLoading, mutate, size, setSize } = useSWRInfinite(
    (pageIndex: number, previousPageData: TreeNode[]) => {
      if (previousPageData && !previousPageData.length) return null;
      return [`/api/tree-nodes/search`, parentTreeNodeId, pageIndex + 1];
    },
    async ([url, parentTreeNodeId, current]) => {
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        ...axiosConfigAdapter({ current, pageSize: 20 }),
      };

      const searchValues = form.getFieldsValue();

      if (searchValues["search"]) {
        config.data.filters.push({
          field: "is_an.name",
          operator: "ilike",
          value: `%${searchValues["search"]}%`,
        });
      }

      config.data.includes.push(
        { relation: "is_an" },
        { relation: "children" },
      );

      config.data.filters.push(
        {
          field: "parent_tree_node_id",
          operator: "=",
          value: parentTreeNodeId,
        },
        {
          field: "is_an_type",
          operator: "in",
          value: ["department", "position"],
        },
      );

      config.data.sort = [{ field: "order_index", direction: "asc" }];

      return axios
        .request<OrionRestIndexResponse<TreeNode>>(config)
        .then((res) =>
          res.data.data.map((member) => ({
            ...member,
            meta: res.data.meta,
          })),
        );
    },
    { revalidateFirstPage: false },
  );

  const setOrgStructureContainerItems = (value: TreeNode[][]) => {
    const items =
      value?.flat().length! > 0 ? value?.flat() : [{ id: "empty", is_an: {} }];
    setContainers(parentTreeNodeId, items as TreeNode[], parentsIds);
  };

  useImperativeHandle<any, any>(swrRef, () => ({
    mutate: async () => {
      swrRefChild.current?.mutate();
      const data = await mutate();
      setOrgStructureContainerItems(data!);
    },
  }));

  useEffect(() => {
    if (data && !data?.flat().some((treeNode) => treeNode.id === activeId)) {
      setActiveId(null);
    }
    if (data) {
      setOrgStructureContainerItems(data);
    }
  }, [data]);

  const createDropDownItems: MenuProps["items"] = [
    {
      label: t("Подразделение"),
      key: "department",
      onClick: () => {
        setCreateMode(true);
        setCreateOrgStructureType("department");
      },
    },

    {
      label: t("Должность"),
      key: "position",
      onClick: () => {
        setCreateMode(true);
        setCreateOrgStructureType("position");
      },
    },
  ];

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("Data not found");

  const items =
    containers.find((container) => container.id === parentTreeNodeId)?.items ??
    [];

  const itemsIds = items.map((item) => item.id);

  const debouncedMutate = debounce(mutate, 200);

  return (
    <>
      <SortableContext
        items={itemsIds}
        strategy={rectSortingStrategy}
        disabled={disableDrag || !!form.getFieldValue("search")}
      >
        <Flex vertical style={{ minWidth: "300px" }}>
          <ProForm
            submitter={false}
            autoFocusFirstInput={false}
            form={form}
            onValuesChange={() => debouncedMutate()}
          >
            <ProFormText placeholder={"Поиск"} name={"search"} />
          </ProForm>
          <div
            style={{
              overflow: "hidden",
              overflowY: "auto",
              height: "100%",
            }}
          >
            <ProList<OrgStructureContainersContextValue["items"][number]>
              ErrorBoundary={false}
              bordered
              ghost
              locale={{ emptyText: <></> }}
              loading={disableDrag}
              footer={
                <Space direction="vertical" style={{ width: "100%" }}>
                  {createMode && (
                    <OrgStructureEditForm
                      key={createOrgStructureType}
                      type={createOrgStructureType}
                      rest={{
                        type: "create",
                        parentTreeNodeId: parentTreeNodeId,
                        onAfterCreate: () => {
                          setCreateMode(false);
                          mutate();
                        },
                        onCancel: () => {
                          setCreateMode(false);
                        },
                      }}
                    />
                  )}
                  {data.flat().length > 0 &&
                    data.flat().length !== data.flat()[0].meta.total && (
                      <Button
                        type="link"
                        onClick={() => {
                          setSize(size + 1);
                        }}
                        style={{ width: "100%" }}
                      >
                        Загрузить еще
                      </Button>
                    )}
                  {member.permissions.includes("org_structure:create") &&
                    !createMode && (
                      <Dropdown
                        trigger={["click"]}
                        placement="top"
                        menu={{ items: createDropDownItems }}
                      >
                        <Button type={"text"} style={{ width: "100%" }}>
                          <PlusOutlined /> Добавить
                        </Button>
                      </Dropdown>
                    )}
                </Space>
              }
              dataSource={items}
              renderItem={(item) => (
                <Flex>
                  <SortableItem
                    choized={item.id === activeId}
                    key={item.id}
                    toggleEditMode={setEditMode}
                    id={item.id}
                    editMode={editMode}
                    item={item}
                    onAfterEdit={() => {
                      mutate();
                    }}
                    title={item.is_an.name}
                    onClick={() => {
                      if (item.is_an_type !== "department") {
                        return;
                      }
                      if (activeId && activeId === item.id) {
                        return setActiveId(null);
                      }
                      setActiveId(item.id);
                    }}
                  />
                </Flex>
              )}
            />
          </div>
        </Flex>
      </SortableContext>
      <Divider type="vertical" style={{ height: "100%" }} />
      {activeId && (
        <OrgStructureTreeList
          disableDrag={disableDrag}
          key={activeId}
          parentTreeNodeId={activeId}
          swrRef={swrRefChild}
          setContainers={setContainers}
          parentsIds={[activeId.toString(), ...parentsIds]}
        />
      )}
    </>
  );
};

export default OrgStructureTreeList;
export type { OrgStructureTreeListProps, SWRHook };
