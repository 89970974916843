export const TabsItemChildrenWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
    }}
  >
    {children}
  </div>
);
