import React, { useEffect, useState } from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormText,
  ProFormTextArea,
  ProFormUploadButton,
} from "@ant-design/pro-components";
import {
  uploadRequest,
  downloadFile,
  validateContentFile,
  UploadWithPreview,
} from "@/entities/file";
import { Alert, Typography } from "antd";
import {
  MAX_ATTACHMENT_FILES_NUMBER,
  MAX_CONTENT_FILES_NUMBER,
} from "@/entities/file/lib/constants";
import { FormData } from "../lib/model";
import { UploadProps } from "antd/lib";

type Props = {
  onRemoveFiles?: UploadProps["onRemove"];
};

export const VideoFormFields: React.FC<Props> = ({ onRemoveFiles }) => {
  const form = ProForm.useFormInstance<FormData>();
  const content_files = ProForm.useWatch("content_files", form);

  const [isContentFilesContainsVideo, setIsContentFilesContainsVideo] =
    useState(false);

  useEffect(() => {
    setIsContentFilesContainsVideo(
      content_files?.some((file) => file.type.split("/")[0] === "video") ??
        false,
    );
  }, [content_files]);

  return (
    <>
      <ProFormDigit name="id" label="ID" hidden />
      <ProFormDigit name="content_file_id" hidden />
      <ProFormDigit name="course_id" label="ID курса" hidden />
      <ProFormText
        name="name"
        label="Название"
        rules={[{ max: 255, required: true }]}
      />
      <ProFormTextArea name="description" label="Описание" />
      <UploadWithPreview
        name="content_files"
        label="Содержание"
        title={"Загрузите файл"}
        description={"Перетащите файл в эту область или нажмите на нее"}
        rules={[
          { required: true },
          () => ({
            validator(_, files) {
              if (files?.[0]) {
                return validateContentFile(files[0], "video");
              } else {
                return Promise.reject();
              }
            },
          }),
        ]}
        max={MAX_CONTENT_FILES_NUMBER}
        fieldProps={{
          listType: "picture",
          onRemove: onRemoveFiles,
          accept: "video/*",
        }}
      />

      {isContentFilesContainsVideo && (
        <Alert
          message={
            <Typography.Text>
              <Typography.Text>
                Рекомендуется загружать видео в формате MP4 с кодеком H.264.
              </Typography.Text>
              <br />
              <Typography.Text type={"secondary"}>
                Так как не все браузеры поддерживают просмотр видео в любом
                формате и кодеке.
                <br />
                MP4 с кодеком H.264 - поддерживается большинством браузеров.
              </Typography.Text>
            </Typography.Text>
          }
          type={"warning"}
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <ProFormUploadButton
        name="attachment_files"
        label="Прикреплённые файлы"
        title={"Загрузить"}
        max={MAX_ATTACHMENT_FILES_NUMBER}
        rules={[
          { required: false },
          { type: "array", max: MAX_ATTACHMENT_FILES_NUMBER },
        ]}
        fieldProps={{
          listType: "picture-card",
          multiple: true,
          onRemove: onRemoveFiles,
          customRequest: uploadRequest,
          onDownload: downloadFile,
          showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: true,
          },
        }}
      />
    </>
  );
};
