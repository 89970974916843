import React, { useState } from "react";
import { Dashboard, Member } from "@/models.ts";
import ProTable, { ProTableProps } from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios";
import { Link } from "react-router-dom";
import { Card, Col, Tooltip, Typography } from "antd";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { ProForm } from "@ant-design/pro-components";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";
import useMe from "@/entities/me/lib/use";
import { useTranslation } from "react-i18next";
import { getTreeNodeNames } from "@/entities/member/lib/get-tree-node-names";

export const metadata: IndicatorContentMetadata = {
  name: "latest_added_members",
  title: "Последние добавленные сотрудники",
  position: 5,
};

const IndicatorContentLatestAddedMembers: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const form = ProForm.useFormInstance<Dashboard>();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );

  const member = useMe();

  const request: ProTableProps<Member, any>["request"] = async () => {
    return axios
      .post<OrionRestIndexResponse<Member>>(
        "/api/members/search",
        {
          sort: [{ field: "created_at", direction: "desc" }],
          includes: [
            { relation: "positions" },
            { relation: "positions.position.tree_node.reverse_tree.is_an" },
          ],
        },
        { params: { limit: 10 } },
      )
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  if (error) throw error;

  return (
    <>
      {is_visible && (
        <Col span={24}>
          <Card title={metadata.title} bodyStyle={{ padding: 8 }}>
            <ProTable<Member>
              rowKey={"id"}
              search={false}
              toolBarRender={false}
              request={request}
              pagination={false}
              style={{ width: "100%" }}
              size={"small"}
              columns={[
                {
                  title: "ФИО",
                  dataIndex: "full_name",
                  render: (text, record) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${record.id}`}>{text}</Link>
                      );
                    }
                    return text;
                  },
                },
                {
                  title: t("Подразделение / Должность"),
                  dataIndex: ["positions", "position_id"],
                  renderText: (_, { positions }) => {
                    if (positions && positions.length === 0) {
                      return "-";
                    }

                    const positionNames = positions?.map(({ position }) => {
                      const name = position?.name;
                      const treeNodeName = getTreeNodeNames(
                        // @ts-ignore
                        position?.tree_node,
                      );

                      return name
                        ? `${treeNodeName ? treeNodeName + ", " : ""}${name}`
                        : "-";
                    });

                    return (
                      <Tooltip
                        overlayStyle={{ whiteSpace: "pre-line" }}
                        title={positionNames?.join(";\n")}
                      >
                        <Typography.Text
                          ellipsis
                          style={{
                            maxWidth: "300px",
                            width: "100%",
                          }}
                        >
                          {positionNames?.join("; ")}
                        </Typography.Text>
                      </Tooltip>
                    );
                  },
                },
                {
                  title: "Дата добавления",
                  dataIndex: "created_at",
                  valueType: "dateTime",
                  fieldProps: { format: dateSTime },
                },
              ]}
            />
          </Card>
        </Col>
      )}
    </>
  );
};

export default IndicatorContentLatestAddedMembers;
