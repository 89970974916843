import { Flex, Grid, Space, Spin, theme } from "antd";
import React, { useEffect, useState } from "react";
import statuses from "@/entities/pool-participant/lib/statuses.tsx";
import { SpinWrapper } from "@/shared/ant-design/spin/ui/spin-wrapper";

type CardHeaderProps = {
  imageUrl: string;
  imageHeight: string;
  status: string;
};
const { useBreakpoint } = Grid;

const CardHeader: React.FC<CardHeaderProps> = ({
  imageHeight,
  imageUrl,
  status,
}) => {
  const { token } = theme.useToken();
  const screens = useBreakpoint();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setLoading(false);
  }, [imageUrl]);

  return (
    <Space.Compact
      style={{
        height: screens.sm ? imageHeight : 0,
      }}
    >
      {/* @ts-ignore */}
      {React.cloneElement(statuses[status]!.text, {
        style: {
          position: "absolute",
          right: 0,
          zIndex: 1,
          top: 0,
          marginRight: token.marginLG,
          marginTop: token.marginSM,
        },
      })}
      {screens.sm && (
        <Flex
          style={{
            position: "absolute",
            height: 200,
            width: "100%",
          }}
          align="center"
        >
          <SpinWrapper>
            <Spin spinning={loading}>
              <div
                style={{
                  background: `url(${imageUrl}) 50% 50% no-repeat`,
                  width: "100%",
                  minHeight: "100%",
                  backgroundSize: "cover",
                }}
              />
            </Spin>
          </SpinWrapper>
        </Flex>
      )}
    </Space.Compact>
  );
};

export default CardHeader;
