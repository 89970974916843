import React from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { UploadWithPreview } from "@/entities/file";
import { validateCourseCoverImage } from "../lib/validate-course-cover-image";
import { contenViewOrderEnum } from "../lib/content-view-order-enum";

type CourseFormProps = {
  showKeyField?: boolean;
};

export const CourseFormFields: React.FC<CourseFormProps> = ({
  showKeyField,
}) => {
  return (
    <>
      <ProFormText
        name="name"
        label="Название"
        rules={[{ required: true, max: 255 }]}
      />
      <ProFormDigit name={"cover_image_file_id"} hidden />
      {showKeyField && (
        <ProFormText
          name="key"
          label="Идентификатор"
          rules={[
            { max: 100 },
            {
              pattern: /^[^\s]+$/,
              message: "Идентификатор не должен содержать пробелы",
            },
          ]}
        />
      )}
      <ProFormTextArea name="description" label="Описание" />
      <ProFormDigit
        min={1}
        label="Количество месяцев, после которых обучение становится недействительным"
        name="learning_expiration_months_count"
      />
      <ProFormSelect
        valueEnum={contenViewOrderEnum}
        label={"Порядок просмотра материалов по умолчанию"}
        name={"content_view_order_default"}
        rules={[{ required: true }]}
      />
      <ProForm.Group>
        <ProFormText
          label={"Название программы обучения  в реестре Минтруда"}
          name={"program_name_registry"}
          rules={[{ max: 255 }]}
        />
        <ProFormDigit label="Объем часов" name="hours_volume" />
        <ProFormText
          label={"Код программы обучения в реестре Минтруда"}
          name={"program_code_registry"}
          rules={[{ max: 255 }]}
        />
      </ProForm.Group>

      <ProFormDigit
        name="time_to_access_education"
        label="Время доступа к курсу (в днях)"
        tooltip="Период, в течение которого у сотрудника есть возможность изучать материалы курса"
        min={1}
      />
      <ProFormDigit
        name="time_to_complete_education"
        label="Правила повторного обучения"
        tooltip="В данном поле вы можете установить, за сколько дней до истечения срока действия документов или обучения, система должна автоматически назначить курс повторно"
        min={1}
      />
      <UploadWithPreview
        label="Обложка"
        name="cover_image_file"
        title="Загрузите файл"
        description="Перетащите файл в эту область или нажмите на нее"
        max={1}
        rules={[
          { required: false },
          () => ({
            validator(_, files) {
              if (files?.[0]) {
                return validateCourseCoverImage(files[0]);
              }
              return Promise.resolve();
            },
          }),
        ]}
        fieldProps={{
          listType: "picture",
          accept: ".png, .jpg, .jpeg",
        }}
      />
    </>
  );
};

export type { CourseFormProps };
