import React from "react";
import { Scorm } from "@/models";
import { message, Modal } from "antd";
import { ButtonProps } from "antd/es/button";
import Button from "@/shared/ant-design/button/ui/button";
import { useScormsResource } from "@/entities/scorm";

type Props = {
  scormId: Scorm["id"];
  onDelete?: () => void;
};

const ScormDeleteButton: React.FC<Props> = ({
  scormId,
  onDelete: onDeleteProp,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const { deleteScorm } = useScormsResource();

  const onDelete: ButtonProps["onClick"] = async () => {
    modal.warning({
      title: "Удалить текущий SCORM?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await deleteScorm(scormId)
          .then(() => {
            onDeleteProp?.();
            message.success("SCORM успешно удалён");
          })
          .catch((err) => {
            console.error(err);
            message.error(
              err.response.data.message ?? "Не удалось удалить SCORM",
            );
          });
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Button danger type={"primary"} onClick={onDelete}>
        Удалить
      </Button>
    </>
  );
};

export default ScormDeleteButton;
