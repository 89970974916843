import React, { useRef } from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import {
  ProForm,
  ProFormDateTimeRangePicker,
} from "@ant-design/pro-components";
import { date, toISOString } from "@/shared/dayjs/lib/formats";

type DateTimeRangePickerWithTimeControlsProps = React.ComponentProps<
  typeof ProFormDateTimeRangePicker
>;

const DateTimeRangePickerWithTimeControls: React.FC<
  DateTimeRangePickerWithTimeControlsProps
> = ({ fieldProps, ...props }) => {
  const form = ProForm.useFormInstance();
  const selectedDate = useRef<[dayjs.Dayjs, dayjs.Dayjs]>();
  const focusedRange = useRef<"start" | "end">();

  const onClick = (mode: "start" | "end" | "now") => {
    const now = toISOString(dayjs());

    const currentStart =
      form.getFieldValue(props.name)?.[0] ||
      selectedDate.current?.[0]?.toISOString();
    const currentEnd =
      form.getFieldValue(props.name)?.[1] ||
      selectedDate.current?.[1]?.toISOString();

    const currentIndex = focusedRange.current === "start" ? 0 : 1;

    const currentRange = [currentStart, currentEnd];

    if (mode === "now" || !selectedDate.current?.[currentIndex]) {
      currentRange[currentIndex] = now;
      if (!selectedDate.current) {
        selectedDate.current = [] as any;
      }
      selectedDate.current![currentIndex] = dayjs();
    } else {
      currentRange[currentIndex] =
        mode === "start"
          ? dayjs(selectedDate.current[currentIndex])
              .startOf("day")
              .toISOString()
          : dayjs(selectedDate.current[currentIndex])
              .endOf("day")
              .toISOString();
    }

    form.setFieldValue(props.name, currentRange);
  };

  return (
    <ProFormDateTimeRangePicker
      fieldProps={{
        onCalendarChange(value: [dayjs.Dayjs, dayjs.Dayjs]) {
          selectedDate.current = value;
        },
        onFocus(
          event: React.FocusEvent<HTMLInputElement>,
          info: { range: "start" | "end" },
        ) {
          focusedRange.current = info.range;
        },
        renderExtraFooter: fieldProps?.format?.includes("HH")
          ? () => {
              return (
                <>
                  <Button type="link" onClick={() => onClick("now")}>
                    Сейчас
                  </Button>
                  <Button type="link" onClick={() => onClick("start")}>
                    Начало дня
                  </Button>
                  <Button type="link" onClick={() => onClick("end")}>
                    Конец дня
                  </Button>
                </>
              );
            }
          : null,
        needConfirm: false,
        format: date,
        showTime: !!fieldProps?.format?.includes("HH"),
        ...fieldProps,
      }}
      {...props}
    />
  );
};

export default DateTimeRangePickerWithTimeControls;
