import axios from "@/axios";
import { Video, VideoFile } from "@/models";
import {
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";

export const useVideosResource = () => {
  const deleteBatchVideoAttachmentFiles = useCallback(
    (ids: Video["id"][]) =>
      axios.delete("/api/video-attachment-files/batch", {
        data: { resources: ids },
      }),
    [],
  );

  const deleteVideo = useCallback(
    (id: Video["id"]) => axios.delete(`/api/videos/${id}`),
    [],
  );

  const getVideoAttachmentFiles = useCallback(
    (videoId: Video["id"]) =>
      axios
        .post<OrionRestIndexResponse<VideoFile>>(
          "/api/video-attachment-files/search",
          {
            filters: [
              {
                field: "video_id",
                operator: "=",
                value: videoId,
              },
            ],
            includes: [{ relation: "file" }],
          },
        )
        .then((res) => res.data.data),
    [],
  );

  const getVideo = useCallback(
    (id: Video["id"], config?: AxiosRequestConfig) =>
      axios
        .get<OrionRestShowResponse<Video>>(`/api/videos/${id}`, config)
        .then((res) => res.data.data),
    [],
  );

  const updateBatchVideoAttachmentFiles = useCallback(
    (resources: Partial<VideoFile>[]) =>
      axios.post("/api/video-attachment-files/batch", {
        resources,
      }),
    [],
  );

  const updateVideo = useCallback(
    (id: Video["id"], values: Partial<Video>) =>
      axios
        .put<OrionRestUpdateResponse<Video>>(`/api/videos/${id}`, values)
        .then(async (res) => res.data.data),
    [],
  );

  return {
    deleteBatchVideoAttachmentFiles,
    deleteVideo,
    getVideoAttachmentFiles,
    getVideo,
    updateBatchVideoAttachmentFiles,
    updateVideo,
  };
};
