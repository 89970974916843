import React from "react";
import {
  ProForm,
  ProFormProps,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { message, Spin } from "antd";
import { Course, PoolTemplate } from "@/models";
import { CourseSelect } from "@/features/course";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import useSWR from "swr";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { RestProps } from "@/shared/rest/lib/types";
import dayjs from "dayjs";
import { dateSTime } from "@/shared/dayjs/lib/formats";

type Record = PoolTemplate & {
  pool_duration: number;
};
type PoolTemplateFormProps = ProFormProps<Record> & {
  rest: RestProps<PoolTemplate>;
  courseId?: Course["id"];
};

const PoolTemplateForm: React.FC<PoolTemplateFormProps> = ({
  rest,
  courseId,
  ...props
}) => {
  const {
    data: course,
    isLoading: isCourseLoading,
    error: courseLoadingError,
  } = useSWR(courseId ? `/api/courses/${courseId}` : null, async (url) => {
    return axios
      .get<OrionRestShowResponse<Course>>(url)
      .then((res) => res.data.data);
  });
  const [form] = ProForm.useForm<Record>(props.form);
  const is_unlimited = ProForm.useWatch("is_unlimited", form);

  if (isCourseLoading) return <Spin />;
  if (courseLoadingError) throw courseLoadingError;

  let defaultProps: Partial<typeof props> = {
    submitter: { resetButtonProps: false },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(
      {
        submitter: { searchConfig: { submitText: "Создать" } },
        initialValues: {
          course_id: courseId,
          is_default: false,
          name: course ? `${course.name} от ${dayjs().format(dateSTime)}` : "",
          is_unlimited: false,
          learning_duration: 1,
          content_view_order: "arbitrary",
        },
      },
      defaultProps,
    );
    defaultProps.onFinish = async (values) => {
      const { name, is_default, course_id, ...value } = values;

      const data = {
        name,
        course_id,
        is_default,
        value,
      };

      return await axios
        .post<OrionRestCreateResponse<PoolTemplate>>(
          "/api/pool-templates",
          data,
        )
        .then((res) => {
          message.success("Шаблон потока успешно создан");
          rest.onAfterCreate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании Шаблона потока",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }
  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<PoolTemplate>>(
          `/api/pool-templates/${rest.recordKey}`,
        )
        .then((res) => {
          const { name, course_id, is_default, value, ...data } = res.data.data;

          return {
            name,
            course_id,
            is_default,
            ...value,
            ...data,
          };
        });
    };
    defaultProps.onFinish = async (values) => {
      const { name, is_default, course_id, ...value } = values;

      const data = {
        name,
        course_id,
        is_default,
        value,
      };
      return axios
        .put<OrionRestUpdateResponse<PoolTemplate>>(
          `/api/pool-templates/${rest.recordKey}`,
          data,
        )
        .then((res) => {
          message.success("Шаблон потока успешно обновлён");
          rest.onAfterUpdate?.(res.data.data);
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении Шаблона потока: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <ProForm<Record> {...props}>
      <CourseSelect
        label="Курс"
        isActiveFilter
        name="course_id"
        disabled={rest.type === "update"}
        rules={[{ required: true }]}
        hidden={!!courseId}
      />
      <ProFormSwitch name={"is_default"} label={"Шаблон по умолчанию"} />
      <ProFormText
        label="Название"
        name="name"
        rules={[{ required: true, max: 255 }]}
      />
      <ProFormSwitch
        label={"Бессрочный поток"}
        name={"is_unlimited"}
        rules={[{ required: true }]}
      />
    </ProForm>
  );
};
export default PoolTemplateForm;
export type { PoolTemplateFormProps };
