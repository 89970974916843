import { Question } from "@/entities/test";
import { v4 as uuidv4 } from "uuid";

export const generateTemplateQuestion = (): Question => {
  return {
    uuid: uuidv4(),
    type: "single",
    text: "Вопрос?",
    points: 1,
    answers: [
      { uuid: uuidv4(), is_correct: true, value: "Ответ 1" },
      { uuid: uuidv4(), is_correct: false, value: "Ответ 2" },
    ],
  };
};
