import React from "react";
import { Section } from "@/models";
import { message, Modal, Space, Typography } from "antd";
import { ButtonProps } from "antd/es/button";
import Button from "@/shared/ant-design/button/ui/button";
import { ProForm, ProFormRadio } from "@ant-design/pro-components";
import { ProFormRadioStyled } from "@/shared/ant-design-pro-components/form/ui/pro-form-radio-styled";
import { useSectionsResource } from "@/entities/section";

type Props = {
  sectionId: Section["id"];
  onDelete?: () => void;
};

const SectionDeleteButton: React.FC<Props> = ({
  sectionId,
  onDelete: onDeleteProp,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [deleteForm] = ProForm.useForm<Section>();
  const { updateSection, deleteSection } = useSectionsResource();

  const onDelete: ButtonProps["onClick"] = () => {
    deleteForm.setFieldValue("on_deleted_action", "delete_children_materials");
    modal.warning({
      title: "Удаление раздела",
      content: (
        <Space direction="vertical">
          <Typography.Text type="secondary">
            Выберите что сделать с материалами раздела:
          </Typography.Text>
          <ProFormRadioStyled form={deleteForm} submitter={false}>
            <ProFormRadio.Group
              name="on_deleted_action"
              options={[
                {
                  label: (
                    <Space direction="vertical" size={0}>
                      <Typography.Text>
                        Удалить вложенные материалы вместе с разделом
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        Вложенные в раздел материалы будут удалены из курса
                        вместе с разделом
                      </Typography.Text>
                    </Space>
                  ),
                  value: "delete_children_materials",
                },
                {
                  label: (
                    <Space direction="vertical" size={0}>
                      <Typography.Text>Удалить только раздел</Typography.Text>
                      <Typography.Text type="secondary">
                        Вложенные в раздел материалы останутся в курсе
                      </Typography.Text>
                    </Space>
                  ),
                  value: "save_children_materials",
                },
              ]}
            />
          </ProFormRadioStyled>
        </Space>
      ),
      icon: null,
      width: "50%",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await updateSection(sectionId, {
          on_deleted_action: deleteForm.getFieldValue("on_deleted_action"),
        });
        await deleteSection(sectionId);
        const afterDeletePromise = new Promise<void>((resolve) => {
          setTimeout(async () => {
            await onDeleteProp?.();
            resolve();
          }, 300);
        });
        await afterDeletePromise;

        message.success("Раздел успешно удалён");
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Button danger type={"primary"} onClick={onDelete}>
        Удалить
      </Button>
    </>
  );
};

export default SectionDeleteButton;
