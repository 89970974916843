import React from "react";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { MemberSelect } from "@/features/member";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { CourseSelect } from "@/features/course";
import { useTranslation } from "react-i18next";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import { CourseSelectActiveArchivedStatus } from "@/entities/course";

const RepeatedEducationFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <OrgStructureTreeSelect
        label={t("Подразделение / Должность")}
        name={["member", "positions", "position_id"]}
        colProps={{ span: 8 }}
        labelWithPath
        searchUser
        fieldProps={{ multiple: true }}
      />
      <CourseSelect
        mode="multiple"
        label="Курс"
        name={["pool", "course", "id"]}
        colProps={{ span: 8 }}
      />
      <CourseSelectActiveArchivedStatus
        mode="multiple"
        label="Статус курса"
        name={["pool", "course", "status"]}
        colProps={{ span: 8 }}
      />
      <DateTimeRangePickerWithTimeControls
        label="Фактическая дата завершения"
        name={["range", "ended_at"]}
        fieldProps={{ allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
      <DateTimeRangePickerWithTimeControls
        label="Дата прохождения повторного обучения"
        name={["scopes", "whereReEducationAtBetween"]}
        fieldProps={{ allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default RepeatedEducationFormFields;
