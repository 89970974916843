import axios from "@/axios";
import { File } from "@/models";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
} from "@/shared/types/orion-rest";
import { useCallback } from "react";

export const useFilesResource = () => {
  const createBatchFiles = useCallback(
    (files: Partial<File>[]) =>
      axios.post<OrionRestBatchCreateResponse<File>>("/api/files/batch", {
        resources: files,
      }),
    [],
  );

  const createFile = useCallback(
    (values: Partial<File>) =>
      axios
        .post<OrionRestCreateResponse<File>>(`/api/files`, values)
        .then((res) => res.data.data),
    [],
  );

  return {
    createBatchFiles,
    createFile,
  };
};
