import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Col,
  Flex,
  message,
  Row,
  Space,
  Spin,
  Tabs as AntdTabs,
  TabsProps,
  theme,
  Typography,
} from "antd";
import { Member, MemberAccessDepartment, MemberPosition } from "@/models";
import useMe from "@/entities/me/lib/use";
import {
  ModalForm,
  ModalFormProps,
  ProForm,
  ProFormProps,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import { OrionRestBatchCreateResponse } from "@/shared/types/orion-rest";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useSpace from "@/entities/space/lib/use";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useFeatures from "@/entities/features/lib/use.ts";
import PersonalFormFields from "./personal-form-fields";
import { themeValueEnum } from "@/shared/theme/lib/value-enum";
import { MemberPositionsList } from "./member-positions-list";
import { useShowMemberInfoModal } from "../lib/use-show-member-info-modal";
import { FormData } from "../lib/model";
import { useMembersResource } from "@/entities/member";
import { SpinWrapper } from "@/shared/ant-design/spin/ui/spin-wrapper";

const Tabs = styled(AntdTabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;

type MemberFormProps = ProFormProps<FormData> & {
  mode?: "default" | "personal";
  rest: RestProps<Member>;
  memberId?: number;
};

export const MemberForm: React.FC<MemberFormProps> = ({
  mode = "default",
  rest,
  ...props
}) => {
  const features = useFeatures();
  const me = useMe();
  const space = useSpace();
  const { getMember, updateMember, createMember } = useMembersResource();

  const [form] = ProForm.useForm<FormData>(props.form);
  const [toDeleteMemberPositions, setDeleteMemberPositions] = React.useState<
    MemberPosition["id"][]
  >([]);
  const [toDeleteAccessedDepartments, setToDeleteAccessedDepartments] =
    React.useState<MemberAccessDepartment["id"][]>([]);
  const memberPositions = ProForm.useWatch("positions", form);
  const accessedDepartments = ProForm.useWatch("accessed_departments", form);
  const { modalMemberHolder, showMemberInfoModal } = useShowMemberInfoModal();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token } = theme.useToken();

  const setLoadingState = (isLoading: boolean) => setLoading(isLoading);

  const onDeleteAccessedDepartment = (id: MemberAccessDepartment["id"]) => {
    setToDeleteAccessedDepartments((prev) => [...prev, id]);
  };

  const onDeleteMemberPosition = (id: MemberPosition["id"]) => {
    setDeleteMemberPositions((prev) => [...prev, id]);
  };

  const syncAvatarImageFile = async (values: FormData) => {
    const { avatar_image_file, ...restValues } = values;
    if (avatar_image_file && avatar_image_file.length > 0) {
      for (let i = 0; i < avatar_image_file.length; i++) {
        const item: any = avatar_image_file[i];
        if (!item.originFileObj || (item.status && item.status !== "done"))
          continue;
        const key = item.response.key;
        await axios
          .post(`/api/files`, {
            key,
            name: item.name,
            type: item.type,
          })
          .then(({ data }) => (avatar_image_file[i] = data.data))
          .then((file) => {
            return updateMember(values.id, {
              ...restValues,
              avatar_image_file_id: file.id,
            });
          });
      }
    } else if (values.avatar_image_file_id) {
      await updateMember(values.id, {
        ...restValues,
        avatar_image_file_id: null,
      });
    }
  };

  const formDisabled = useMemo(() => {
    return mode === "personal"
      ? !(
          space.is_allow_employee_edit_self_member ||
          me.permissions.includes("member:update")
        )
      : rest.type === "update"
      ? !me.permissions.includes("member:update")
      : !me.permissions.includes("member:create");
  }, [me, rest.type, mode, space]);

  const employeeEditableOrgStructure = useMemo(() => {
    return rest.type === "update"
      ? !me.permissions.includes("member:update")
      : !me.permissions.includes("member:create");
  }, [me, rest.type]);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    initialValues: {
      is_restricted_access: false,
    },
  };

  const overrideProps: Partial<typeof props> = {
    form,
    disabled: formDisabled,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    const triggerText = rest.type === "create" ? "Создать" : "Редактировать";

    const modalFormProps: Partial<ModalFormProps<FormData>> = {
      title:
        rest.type === "create"
          ? "Создание пользователя"
          : "Редактирование пользователя",
      trigger: <Button type={"primary"}>{triggerText}</Button>,
      modalProps: {
        destroyOnClose: true,
      },
    };

    props = deepmerge(modalFormProps, props);
    defaultProps.onFinish = async (values) => {
      const { avatar_image_file, ...restValues } = values;

      return await createMember(restValues)
        .then(async (res) => {
          message.success("Пользователь успешно создан");
          rest.onAfterCreate?.(res.data.data);
          await syncAvatarImageFile({ ...values, id: res.data.data.id });
          showMemberInfoModal(res.data.data);
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании пользователя",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return getMember(Number(rest.recordKey), {
        params: {
          includes: [
            { relation: "positions" },
            { relation: "avatar_image_file" },
            { relation: "accessed_departments" },
          ],
        },
      }).then((member) => {
        return {
          ...member,
          avatar_image_file: member.avatar_image_file
            ? [{ ...member.avatar_image_file, status: "done" }]
            : null,
        } as FormData;
      });
    };
    defaultProps.onFinish = async (values) => {
      setIsSubmitButtonLoading(true);
      const { avatar_image_file, ...restValues } = values;

      return updateMember(Number(rest.recordKey), restValues)
        .catch((err) => {
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
            setActiveTab(
              Object.keys(err.response.data.errors)[0].startsWith("education")
                ? "education"
                : "personal",
            );
          }

          throw err;
        })
        .then(async (member) => {
          if (toDeleteMemberPositions?.length) {
            await axios
              .delete(`/api/member-positions/batch`, {
                data: {
                  resources: toDeleteMemberPositions,
                },
              })
              .then(() => {
                setDeleteMemberPositions([]);
              });
          }
          if (toDeleteAccessedDepartments?.length) {
            await axios
              .delete(`/api/member-access-departments/batch`, {
                data: {
                  resources: toDeleteAccessedDepartments,
                },
              })
              .then(() => {
                setToDeleteAccessedDepartments([]);
              });
          }
          return member;
        })
        .then(async (member) => {
          if (memberPositions) {
            const toCreateOrgStructureAssignments = memberPositions.filter(
              (el) => !el.id,
            );
            if (toCreateOrgStructureAssignments.length > 0) {
              await axios.post<OrionRestBatchCreateResponse<MemberPosition>>(
                `/api/member-positions/batch`,
                {
                  resources: toCreateOrgStructureAssignments.map((el) => {
                    return {
                      id: el.id ?? null,
                      member_id: el.member_id ?? null,
                      position_id: el.position_id ?? null,
                    };
                  }),
                },
              );
            }
          }

          if (accessedDepartments) {
            const toCreateAccessedDepartments = accessedDepartments.filter(
              (el) => !el.id,
            );
            if (toCreateAccessedDepartments.length === 0) return member;

            await axios.post<OrionRestBatchCreateResponse<MemberPosition>>(
              `/api/member-access-departments/batch`,
              {
                resources: toCreateAccessedDepartments.map((el) => {
                  return {
                    member_id: el.member_id ?? null,
                    department_id: el.department_id ?? null,
                  };
                }),
              },
            );
          }

          return member;
        })
        .then(async (member) => {
          await syncAvatarImageFile({ ...values, id: member.id });
          return member;
        })
        .then(async (member) => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          message.success("Пользователь успешно обновлен");
          rest.onAfterUpdate?.(member);
          if (member.id === me.id) {
            me.refresh();
          }
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении пользователя: ${messageText}`);

          return false;
        })
        .finally(() => setIsSubmitButtonLoading(false));
    };
  }

  // -------------------------------- Pre Render -------------------------------- //

  props = deepmerge(defaultProps, props, overrideProps);

  // -------------------------------- Type Modal -------------------------------- //

  // -------------------------------- Render -------------------------------- //

  const Submitter: React.FC<{ disabled?: boolean }> = useCallback(
    ({ disabled }) => {
      return (
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          <Button
            disabled={disabled}
            type={"primary"}
            loading={isSubmitButtonLoading}
            onClick={async () => {
              try {
                await form.validateFields();
              } catch (error: any) {
                const errorFieldName = error.errorFields[0].name[0];
                if (errorFieldName.startsWith("education")) {
                  setActiveTab("education");
                } else if (errorFieldName.startsWith("positions")) {
                  setActiveTab("positions");
                } else if (activeTab !== "personal") {
                  setActiveTab("personal");
                }
                return;
              }
              form.submit();
            }}
          >
            {rest.type === "create" ? "Создать" : "Сохранить"}
          </Button>
        </Flex>
      );
    },
    [isSubmitButtonLoading, form],
  );

  const tabs: TabsProps["items"] = [
    ...(mode !== "personal" &&
    rest.type === "update" &&
    me.permissions.includes("pool_participant:view")
      ? [
          {
            key: "pools",
            style: { height: "100%" },
            label: "Назначенное обучение",
            children: (
              <PoolParticipantTable
                hasQueryParams
                memberId={Number(rest.recordKey)}
                options={{
                  setting: {
                    draggable: false,
                    showListItemOption: false,
                  },
                  reload: false,
                  density: false,
                }}
                columnsState={{
                  persistenceKey: `pool-participant-member-table`,
                }}
                saveFilters={{
                  persistenceKey: `pool-participant-member-table`,
                }}
                toolBarActionsProps={{
                  create: { triggerProps: { style: { display: "none" } } },
                  remove: { children: "Удалить обучение" },
                }}
                columns={[
                  { dataIndex: "registry_number" },
                  {
                    dataIndex: ["pool", "course", "id"],
                    render: (dom, { pool: { course } }) =>
                      course.status == "deleted" ? (
                        <Typography.Text>{dom}</Typography.Text>
                      ) : (
                        <Link to={`/manage/courses/${course.id}`}>{dom}</Link>
                      ),
                    hideInSetting: true,
                    disable: true,
                  },
                  {
                    dataIndex: ["pool", "id"],
                    render: (dom, { pool: { course, ...pool } }) =>
                      course.status === "deleted" ? (
                        <Typography.Text>{dom}</Typography.Text>
                      ) : (
                        <Link
                          to={`/manage/courses/${course.id}/pools/${pool.id}`}
                        >
                          {dom}
                        </Link>
                      ),
                    hideInSetting: true,
                    disable: true,
                  },
                  { dataIndex: "status" },
                  { dataIndex: "progress_percent" },
                  { dataIndex: "starts_at" },
                  { dataIndex: "started_at" },
                  { dataIndex: "ends_at" },
                  { dataIndex: "ended_at" },
                ]}
              />
            ),
          },
        ]
      : []),

    {
      key: "personal",
      label: "Личная информация",
      style: { height: "100%", width: "100%" },
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{
            padding: token.paddingMD,
            height: "100%",
            overflowY: "auto",
            paddingTop: 0,
            width: "100%",
          }}
        >
          <ProForm
            style={{ height: "100%" }}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter disabled={formDisabled} />
            <PersonalFormFields
              memberId={
                rest.type === "update" ? Number(rest.recordKey) : undefined
              }
              employeeEditableOrgStructure={employeeEditableOrgStructure}
              setLoadingState={setLoadingState}
              type={rest.type}
              mode={mode}
              onDeleteAccessedDepartment={onDeleteAccessedDepartment}
            />
          </ProForm>
        </Space>
      ),
    },
    {
      key: "positions",
      label: "Работа",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction={"vertical"}
          style={{
            width:
              mode === "personal"
                ? "calc(100vw - 32px)"
                : "calc(100vw - 200px - 32px)",
            maxWidth:
              mode === "personal"
                ? "calc(1920px - 32px)"
                : "calc(1920px - 200px - 32px)",
            overflowY: "auto",
            height: "100%",
            padding: token.paddingMD,
            paddingTop: 0,
          }}
        >
          <ProForm
            form={form}
            style={{ width: "100%" }}
            grid={true}
            {...props}
            disabled={employeeEditableOrgStructure}
          >
            <Submitter disabled={employeeEditableOrgStructure} />
            <div style={{ width: "100%", marginTop: token.padding }}>
              <MemberPositionsList
                type={rest.type}
                memberId={
                  rest.type === "update" ? Number(rest.recordKey) : undefined
                }
                afterRemovePosition={onDeleteMemberPosition}
                disabled={employeeEditableOrgStructure}
              />
            </div>
          </ProForm>
        </Space>
      ),
    },
    {
      key: "education",
      label: "Образование",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{
            padding: token.paddingMD,
            overflowY: "auto",
            height: "100%",
            paddingTop: 0,
            width: "100%",
          }}
        >
          <ProForm form={form} grid={true} {...props}>
            <Submitter disabled={formDisabled} />
            <Row gutter={token.paddingXS} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={12}>
                <ProFormText
                  label="Уровень образования"
                  name="education_level"
                  rules={[{ required: false, max: 255 }]}
                />
                <ProFormText
                  label="Серия диплома"
                  name="education_diploma_series"
                  rules={[{ required: false, max: 255, min: 10 }]}
                />
                <ProFormText
                  label="Номер диплома"
                  name="education_diploma_number"
                  rules={[{ required: false }]}
                />
                <ProFormText
                  label="Фамилия в дипломе"
                  name="education_diploma_last_name"
                  rules={[{ required: false, max: 255 }]}
                />
              </Col>
            </Row>
          </ProForm>
        </Space>
      ),
    },

    ...(mode === "personal" && rest.type === "update"
      ? [
          {
            key: "settings",
            label: "Настройки",
            style: { height: "100%" },
            children: (
              <Space
                direction="vertical"
                style={{
                  padding: token.paddingMD,
                  height: "100%",
                  overflowY: "auto",
                  paddingTop: 0,
                  width: "100%",
                }}
              >
                <ProForm form={form} grid={true} {...props}>
                  <Submitter />
                  <Row gutter={token.paddingXS} style={{ width: "100%" }}>
                    <Col xs={24} sm={24} md={12}>
                      <ProFormSelect
                        label="Тема"
                        name="theme"
                        valueEnum={themeValueEnum}
                      />
                    </Col>
                  </Row>
                </ProForm>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const [activeTab, setActiveTab] = useQueryTabs(
    rest.type === "create"
      ? undefined
      : mode === "personal" || !me.permissions.includes("pool_participant:view")
      ? "personal"
      : "pools",
    tabs,
  );

  return (
    <SpinWrapper>
      <Spin spinning={loading}>
        {rest.type === "create" && (
          <>
            {modalMemberHolder}
            <ModalForm<FormData>
              form={form}
              grid={true}
              {...(props as any)}
              submitter={{
                resetButtonProps: false,
                searchConfig: {
                  submitText: "Создать",
                },
              }}
            >
              <PersonalFormFields
                setLoadingState={setLoadingState}
                mode={mode}
                employeeEditableOrgStructure={employeeEditableOrgStructure}
                type={rest.type}
                onDeleteAccessedDepartment={onDeleteAccessedDepartment}
              />
              {features.isEnabled("restrict_accesses") &&
                me.is_restricted_access && (
                  <MemberPositionsList
                    type={rest.type}
                    disabled={employeeEditableOrgStructure}
                  />
                )}
            </ModalForm>
          </>
        )}
        {rest.type === "update" && (
          <Tabs
            activeKey={activeTab}
            items={tabs}
            destroyInactiveTabPane={false}
            onChange={setActiveTab}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Spin>
    </SpinWrapper>
  );
};

export default MemberForm;
export type { MemberFormProps };
