import React, { useEffect, useState } from "react";
import {
  ProDescriptions,
  ProForm,
  ProList,
  ProFormSelect,
  ProListProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { PoolParticipant } from "@/models";
import useMe from "@/entities/me/lib/use";
import { Collapse, Flex, Grid, theme, Typography } from "antd";
import "@/widgets/my-education/ui/list.css";
import { deepmerge } from "deepmerge-ts";
import { dateSTime, toISOString } from "@/shared/dayjs/lib/formats";
import CardHeader from "@/widgets/my-education/ui/card-header";
import dayjs from "dayjs";
import statuses from "@/entities/pool-participant/lib/statuses";
import styled from "styled-components";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";
import useQueryState from "@/shared/hooks/use-query-state";
import { MemberEducationCoursesSelect } from "./member-education-courses-select";

const SearchFormWrapper = styled.div`
  .ant-pro-table-search-query-filter {
    margin-bottom: 0;
  }

  .ant-form {
    padding: 12px;
    padding-inline: 0;
  }

  .ant-pro-query-filter-row {
    row-gap: 6px;
  }

  height: 100%;
  width: 100%;
`;

const StyledProList = styled(ProList)<ProListProps<PoolParticipant>>`
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;

  .ant-pro-card:last-child {
    flex-grow: 1;
    overflow: hidden;
  }
  .ant-pro-card-body {
    display: flex !important;
    flex-direction: column;
  }
  .ant-list {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .ant-spin-nested-loading {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

type Entity = PoolParticipant;
type PoolParticipantsListProps = Pick<
  ProListProps<Entity>,
  "headerTitle" | "onItem"
>;

const { useBreakpoint } = Grid;

const baseImageUrl = "/default-course-image.jpg";

const MyEducationList: React.FC<PoolParticipantsListProps> = ({ ...props }) => {
  const me = useMe();
  const { token } = theme.useToken();
  const screens = useBreakpoint();
  const [searchForm] = ProForm.useForm();
  const [filterCount, setFilterCount] = useState(0);
  const [queryState, setQueryState] = useQueryState("query", {
    valueType: "object",
  });
  const [collapsed, setCollapsed] = useState(false);
  const columnsCount = screens.xxl ? 4 : 3;
  const rowsCount = 2;
  const gutter = token.padding;
  const imageHeight = "200px";

  useEffect(() => {
    if (queryState) {
      searchForm.setFieldsValue(queryState);
    }
  }, [queryState, searchForm]);

  const defaultProps: ProListProps<Entity> = {
    request: ({ pageSize: limit, current: page, ...rest }) => {
      setFilterCount(
        Object.entries(searchForm.getFieldsValue()).filter(
          ([_, value]) =>
            value !== undefined && Array.isArray(value) && value.length > 0,
        ).length,
      );
      setQueryState(searchForm.getFieldsValue());
      const filters = Object.entries(rest)
        .map(([key, value]) => ({
          type: "and",
          nested: value.map((value: any) => ({
            field: key,
            operator: "=",
            value,
            type: "or",
          })),
        }))
        .filter((f: any) => {
          if (!f.nested) return false;
          if (Array.isArray(f.nested)) return f.nested.length > 0;
          return true;
        });
      const now = toISOString(dayjs());
      return axios
        .post(
          "/api/pool-participants/search",
          {
            includes: [{ relation: "pool.course.cover_image_file" }],
            filters: [
              { field: "member_id", operator: "=", value: me.id },
              { field: "pool.status", operator: "=", value: "started" },
              { field: "status", operator: "!=", value: "failed" },
              {
                type: "and",
                nested: [
                  { field: "ends_at", operator: ">", value: now, type: "or" },
                  {
                    field: "is_unlimited",
                    operator: "=",
                    value: true,
                    type: "or",
                  },
                ],
              },
              { field: "starts_at", operator: "<", value: now },
              ...filters,
            ],
            sort: [
              { field: "status_order_index", direction: "asc" },
              { field: "created_at", direction: "desc" },
            ],
          },
          { params: { limit, page } },
        )
        .then((response) => ({
          data: response.data.data,
          total: response.data.meta.total,
          success: true,
        }))
        .finally(() => {
          setCollapsed(false);
        });
    },
    pagination: {
      align: "center",
      pageSize: columnsCount * rowsCount,
      showSizeChanger: false,
      style: { background: token.colorBgBase, padding: token.padding },
    },
  };

  props = deepmerge(defaultProps, props);

  return (
    <StyledProList
      ErrorBoundary={false}
      className={"my-education-list"}
      style={{
        margin: "0 auto",
        width: screens.sm && !screens.md ? "80%" : "100%",
        height: "100%",
      }}
      search={{
        layout: "vertical",
        collapsed: false,
        collapseRender: () => null,
        resetText: "Очистить",
        form: searchForm,
      }}
      searchFormRender={(_, defaultDom) => (
        <Flex
          style={{
            padding: token.paddingXS,
          }}
        >
          {!screens.lg ? (
            <Collapse
              style={{ width: "100%" }}
              size="small"
              collapsible="header"
              activeKey={collapsed ? "1" : undefined}
              onChange={(key) => setCollapsed(key[0] === "1")}
              items={[
                {
                  key: "1",
                  forceRender: true,
                  label: `Фильтр ${filterCount ? `(${filterCount})` : ""}`,
                  style: {
                    width: "100%",
                  },
                  children: <SearchFormWrapper>{defaultDom}</SearchFormWrapper>,
                },
              ]}
            />
          ) : (
            <SearchFormWrapper>{defaultDom}</SearchFormWrapper>
          )}
        </Flex>
      )}
      grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4, gutter }}
      ghost
      metas={{
        title: {
          render: (_, { status, pool }) => {
            const imageUrl =
              pool?.course?.cover_image_file?.url ?? baseImageUrl;
            return (
              <CardHeader
                imageHeight={imageHeight}
                imageUrl={imageUrl}
                status={status}
              />
            );
          },
          dataIndex: ["pool", "course", "name"],
          search: false,
        },
        courses: {
          dataIndex: "pool.course.id",
          title: "Курс",
          renderFormItem: (_: any, config: any) => {
            return (
              <MemberEducationCoursesSelect
                {...config}
                mode="multiple"
                label=""
              />
            );
          },
        },
        status: {
          dataIndex: "status",
          title: "Статус",
          valueEnum: statuses,
          renderFormItem: (_: any, config: any) => {
            return (
              <ProFormSelect
                {...config}
                fieldProps={{ tagRender, ...config.fieldProps }}
                mode="multiple"
              />
            );
          },
        },
        content: {
          search: false,
          render: (_, record) => {
            return (
              <ProDescriptions<PoolParticipant>
                column={1}
                size={"small"}
                layout="vertical"
                dataSource={record}
                columns={[
                  {
                    style: { padding: 0 },
                    title: <Typography.Text strong>Прогресс</Typography.Text>,
                    dataIndex: "progress_percent",
                    valueType: "progress",
                    fieldProps: {
                      percent: Number(record.progress_percent).toFixed(2),
                    },
                  },
                  {
                    title: <Typography.Text strong>Обучение</Typography.Text>,
                    dataIndex: ["pool", "course", "name"],
                    render: (_, { pool }) => {
                      return (
                        <Typography.Text ellipsis={{ tooltip: true }}>
                          {pool?.course?.name}
                        </Typography.Text>
                      );
                    },
                  },
                  {
                    title: <Typography.Text strong>Начало</Typography.Text>,
                    dataIndex: "starts_at",
                    valueType: "dateTime",
                    fieldProps: { format: dateSTime },
                  },
                  {
                    title: <Typography.Text strong>Завершение</Typography.Text>,
                    dataIndex: "ends_at",
                    valueType: "dateTime",
                    fieldProps: { format: dateSTime },
                  },
                ]}
              />
            );
          },
        },
      }}
      {...props}
    />
  );
};

export default MyEducationList;
export type { PoolParticipantsListProps };
