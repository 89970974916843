import React, { useEffect, useState } from "react";
import { Scorm } from "@/models";
import { ProForm, ProFormProps } from "@ant-design/pro-components";
import { Flex, message, Result, Spin } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button";
import useMe from "@/entities/me/lib/use";
import {
  ScormStatus,
  FormData,
  ScormFormFields,
  useScormsResource,
} from "@/entities/scorm";
import ScormDeleteButton from "./delete-button";
import { useUpdateScormFile } from "../lib/use-update-scorm-file";

type Props = ProFormProps<FormData> & {
  rest: RestProps<Scorm>;
  onDelete?: () => void;
};

const ScormForm: React.FC<Props> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [form] = ProForm.useForm<FormData>();
  const status = ProForm.useWatch("status", form);
  const { updateScorm, getScorm } = useScormsResource();
  const { updateScormFile } = useUpdateScormFile();

  const member = useMe();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    throw new Error("Not implemented");
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    const request = async () => {
      return getScorm(Number(rest.recordKey), {
        params: { include: "package_file" },
      })
        .then((scorm) => {
          return {
            ...scorm,
            package_file: scorm.package_file
              ? ([{ ...scorm.package_file, status: "done" }] as any)
              : [],
          };
        })
        .catch((err) => {
          setError(err);
          throw err;
        });
    };

    defaultProps.request = async () => {
      setIsLoading(true);
      return request().finally(() => setIsLoading(false));
    };
    defaultProps.onFinish = async (values) => {
      setIsLoading(true);
      return updateScorm(Number(rest.recordKey), values)
        .then(async (scorm) => {
          await updateScormFile(values);
          message.success("SCORM успешно обновлен");
          await rest.onAfterUpdate?.(scorm);
          await request?.().then((res) => form.setFieldsValue(res));
          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении SCORM'а: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const refresh = (
    fields: undefined | (keyof Scorm)[] | keyof Scorm,
  ): Promise<Scorm> => {
    setIsRefreshing(true);

    return (async () => {
      const request = props.request;

      if (!request) {
        throw new Error("Request not found");
      }

      return request({}, {}).then((data) => {
        if (!fields) {
          form.setFieldsValue(data as any);
          return data;
        }

        if (!Array.isArray(fields)) {
          fields = [fields];
        }

        for (const field of fields) {
          if (!(field in data)) {
            throw new Error(`Field ${field} not found`);
          }
          form.setFieldValue(field, data[field]);
        }

        return data;
      });
    })()
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  };
  const refreshInterval: undefined | number = 1000 * 5;

  useEffect(() => {
    const interval = setInterval(() => {
      if (status === "to_deploy" || status === "deploying") {
        refresh("status");
      }
    }, refreshInterval);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <Result status={"error"} title={"Ошибка"} subTitle={error.message} />
    );
  }

  return (
    <Spin spinning={isLoading}>
      <ProForm<FormData> {...props}>
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          {member.permissions.includes("course:update") && (
            <>
              <ScormDeleteButton
                scormId={Number(rest.recordKey)}
                onDelete={onDeleteProp}
              />
              <Button
                type={"primary"}
                onClick={form.submit}
                loading={isLoading}
              >
                Сохранить
              </Button>
            </>
          )}
        </Flex>
        <ScormFormFields />
        <Flex justify={"center"} gap={16} style={{ width: "100%" }}>
          <ScormStatus
            status={status}
            onRefresh={() => refresh("status")}
            isRefreshing={isRefreshing}
          />
        </Flex>
      </ProForm>
    </Spin>
  );
};

export default ScormForm;
