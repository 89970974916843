import { Tooltip } from "antd";
import { InboxOutlined } from "@ant-design/icons";

export const ArchivedCourseIcon: React.FC = () => {
  return (
    <Tooltip title="Архивный">
      <InboxOutlined />
    </Tooltip>
  );
};
