import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import { statusValueEnum } from "../lib/status-value-enum";
import { tagRender } from "@/shared/ant-design-pro-components/select/lib/tag-render";

type CourseSelectActiveArchivedStatusProps = ProFormSelectProps;

export const CourseSelectActiveArchivedStatus: React.FC<
  CourseSelectActiveArchivedStatusProps
> = ({ fieldProps, ...props }) => {
  return (
    <ProFormSelect
      valueEnum={statusValueEnum}
      fieldProps={{ tagRender, ...fieldProps }}
      {...props}
    />
  );
};
