import React from "react";
import { MemberForm } from "@/features/member";
import useMe from "@/entities/me/lib/use";
import { Flex, theme } from "antd";

const Page: React.FC = () => {
  const member = useMe();
  const { token } = theme.useToken();

  return (
    <Flex
      vertical
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Flex
        vertical
        gap={8}
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          background: token.colorBgContainer,
        }}
      >
        <MemberForm
          rest={{
            type: "update",
            recordKey: member.id,
            onAfterUpdate: () => {
              member.refresh();
            },
          }}
          mode="personal"
        />
      </Flex>
    </Flex>
  );
};

export default Page;
