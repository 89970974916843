import React from "react";
import { Typography, Tooltip, Button, Space, theme } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import statusValueEnum from "../lib/status-value-enum";
import { Scorm } from "@/models";

type ScormStatusProps = {
  status: Scorm["status"];
  onRefresh: () => void;
  isRefreshing: boolean;
};

export const ScormStatus: React.FC<ScormStatusProps> = ({
  status,
  onRefresh,
  isRefreshing,
}) => {
  const { token } = theme.useToken();
  return (
    <Space direction="horizontal">
      <Typography.Text type="secondary">
        Статус: {statusValueEnum[status] ?? status}
      </Typography.Text>
      {(status === "to_deploy" || status === "deploying") && (
        <Tooltip title="Обновить статус">
          <Button
            icon={
              <ReloadOutlined style={{ color: token.colorTextSecondary }} />
            }
            onClick={onRefresh}
            type="text"
            size="small"
            loading={isRefreshing}
          />
        </Tooltip>
      )}
    </Space>
  );
};
