import React from "react";
import { Modal } from "antd";
import Button from "@/shared/ant-design/button/ui/button";

type Props = {
  onDelete?: () => void;
};

const TestDeleteButton: React.FC<Props> = ({ onDelete: onDeleteProp }) => {
  const [modal, contextHolder] = Modal.useModal();

  const onDelete = () => {
    modal.warning({
      title: "Удалить текущий тест?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => await onDeleteProp?.(),
    });
  };

  return (
    <>
      {contextHolder}
      <Button danger type={"primary"} onClick={onDelete}>
        Удалить
      </Button>
    </>
  );
};

export default TestDeleteButton;
