import React from "react";
import { Link, useParams } from "react-router-dom";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";

const Page: React.FC = () => {
  const { pool_id } = useParams();
  const { course_id } = useParams();
  const [someSelected, setSomeSelected] = React.useState(false);
  const [total, setTotal] = React.useState<number>();
  const { parentHeight, ref } = useParentHeight("table");

  const member = useMe();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }} ref={ref}>
        <PoolParticipantTable
          hasQueryParams
          saveFilters={{
            persistenceKey: "pool-participant-table",
          }}
          courseId={Number(course_id!)}
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          onLoad={(data) => {
            setTotal(data.length);
          }}
          columnsState={{
            persistenceKey: "pool-participant-table",
            defaultValue: {
              "member,positions,position_id": { show: false },
            },
          }}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSomeSelected(selectedRowKeys.length > 0);
            },
          }}
          scroll={{
            y: parentHeight + (total ? 0 : 40) - (someSelected ? 247 : 187),
            x: 1200,
          }}
          poolId={Number(pool_id!)}
          columns={[
            {
              dataIndex: ["member", "full_name"],
              render: (node, { member: { id } }) => {
                if (member.permissions.includes("member:view")) {
                  return (
                    <Link to={`/manage/members/${id}?tab=pools`}>{node}</Link>
                  );
                }
                return node;
              },
              hideInSetting: true,
              disable: true,
            },
            { dataIndex: "registry_number" },
            { dataIndex: "status" },
            { dataIndex: "progress_percent" },
            { dataIndex: ["member", "positions", "position_id"] },
            { dataIndex: "starts_at" },
            { dataIndex: "started_at" },
            { dataIndex: "ends_at", width: 175 },
            { dataIndex: "ended_at", width: 175 },
          ]}
        />
      </div>
    </Flex>
  );
};

export default Page;
