import useMe from "@/entities/me/lib/use";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import {
  ProForm,
  ProFormDigit,
  ProFormField,
  ProFormGroup,
  ProFormList,
} from "@ant-design/pro-components";
import { Button, theme } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { RestProps } from "@/shared/rest/lib/types";
import { Member, MemberPosition } from "@/models";

type Props = {
  type: RestProps<Member>["type"];
  afterRemovePosition?: (id: MemberPosition["id"]) => void;
  disabled: boolean;
  memberId?: Member["id"];
};

export const MemberPositionsList: React.FC<Props> = ({
  type,
  afterRemovePosition,
  disabled: disabledProp,
  memberId,
}) => {
  const { token } = theme.useToken();
  const form = ProForm.useFormInstance();
  const memberPositions = ProForm.useWatch("positions", form);
  const { t } = useTranslation();
  const member = useMe();

  return (
    <ProFormList
      label={type === "create" && "Работа"}
      min={type === "create" ? 1 : 0}
      max={5}
      name={"positions"}
      initialValue={type === "create" ? [{}] : undefined}
      creatorRecord={{
        member_id: memberId ?? undefined,
      }}
      creatorButtonProps={{
        creatorButtonText: "Добавить запись о работе",
        style: {
          marginLeft: token.paddingXXS,
          width: `calc(100% - ${token.paddingXXS}px * 2)`,
        },
      }}
      copyIconProps={false}
      deleteIconProps={false}
      onAfterRemove={async (index) => {
        if (
          memberPositions &&
          typeof index === "number" &&
          memberPositions[index].id
        ) {
          afterRemovePosition?.(memberPositions[index].id);
        }
      }}
    >
      {(_, index, action) => {
        const position = memberPositions?.[index];
        const disabled =
          member.is_restricted_access && position?.position_id
            ? !member.accessed_positions.includes(position.position_id)
            : disabledProp;
        const id = memberPositions?.[index].id;

        return (
          <ProFormGroup rowProps={{ align: "bottom" }}>
            <ProFormDigit name={"id"} hidden />
            <ProFormDigit name={"member_id"} hidden />
            <OrgStructureTreeSelect
              disabled={disabled}
              labelWithPath
              label={t("Должность")}
              name={"position_id"}
              colProps={{ span: 23 }}
              fieldProps={{
                onChange: (value) => {
                  if (value && id) {
                    afterRemovePosition?.(id);
                    action.setCurrentRowData({
                      id: null,
                    });
                  }
                },
              }}
              rules={[
                { required: true },
                {
                  validator: async (_, value) => {
                    if (
                      form
                        .getFieldValue("positions")
                        .filter((d: any) => d.position_id === value).length > 1
                    ) {
                      return Promise.reject("Должность дублируется");
                    }
                  },
                },
              ]}
              disabledDepartments
            />
            <ProFormField
              colProps={{
                span: 1,
                style: { textAlign: "right" },
              }}
            >
              <Button
                disabled={
                  disabled ||
                  (type === "create" && memberPositions?.length === 1)
                }
                icon={<DeleteOutlined />}
                danger
                type={"dashed"}
                title="Удалить"
                onClick={() => action.remove(index)}
                style={{
                  marginTop: index === 0 ? token.paddingXS : undefined,
                }}
              />
            </ProFormField>
          </ProFormGroup>
        );
      }}
    </ProFormList>
  );
};
