import React, { useState } from "react";
import { Section } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Button, Flex, message } from "antd";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import { UpdateRestProps } from "@/shared/rest/lib/types";
import useMe from "@/entities/me/lib/use";
import SectionDeleteButton from "./delete-button";
import { useSectionsResource } from "@/entities/section";

type SectionFormProps = ProFormProps<Section> & {
  rest: UpdateRestProps<Section>;
  onDelete?: () => void;
};

const SectionForm: React.FC<SectionFormProps> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [form] = ProForm.useForm<Section>();
  const member = useMe();
  const { updateSection, getSection } = useSectionsResource();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return await getSection(Number(rest.recordKey));
    };
    defaultProps.onFinish = async (values) => {
      setIsSubmitButtonLoading(true);
      return updateSection(Number(rest.recordKey), values)
        .then((section) => {
          message.success("Раздел успешно обновлен");
          rest.onAfterUpdate?.(section);
          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении раздела: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => setIsSubmitButtonLoading(false));
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  return (
    <ProForm<Section>
      form={form}
      submitter={{
        render: false,
      }}
      {...props}
    >
      <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
        {member.permissions.includes("course:update") && (
          <>
            <SectionDeleteButton
              sectionId={Number(props.id)}
              onDelete={onDeleteProp}
            />

            <Button
              type={"primary"}
              loading={isSubmitButtonLoading}
              onClick={form.submit}
            >
              Сохранить
            </Button>
          </>
        )}
      </Flex>
      <ProFormDigit name="course_id" label="ID курса" hidden />
      <ProFormText
        name="name"
        label="Название"
        rules={[{ required: true, max: 255 }]}
      />
      <ProFormTextArea name="description" label="Описание" />
    </ProForm>
  );
};

export default SectionForm;
export type { SectionFormProps };
