import styled from "styled-components";

export const SpinWrapper = styled.div`
  height: 100%;
  width: 100%;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
`;
