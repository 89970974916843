import {
  ProFormUploadButton,
  ProFormUploadButtonProps,
  ProFormUploadDragger,
  ProFormUploadDraggerProps,
} from "@ant-design/pro-components";
import { FilePreviewModal } from "./preview-modal";
import { uploadRequest } from "../lib/upload-request";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadFile } from "../lib/download-file";
import { usePreview } from "../lib/use-preview";

type Props = (ProFormUploadDraggerProps | ProFormUploadButtonProps) & {
  type?: "dragger" | "button";
};

export const UploadWithPreview: React.FC<Props> = ({
  fieldProps,
  type = "dragger",
  ...props
}) => {
  const { preview, onPreview } = usePreview();
  const Upload =
    type === "dragger" ? ProFormUploadDragger : ProFormUploadButton;

  return (
    <>
      <Upload
        fieldProps={{
          customRequest: uploadRequest,
          onDownload: downloadFile,
          onPreview,
          showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: true,
            downloadIcon: (file) => {
              if (!file.url) {
                file.status = undefined;
              }
              return <DownloadOutlined />;
            },
          },
          ...fieldProps,
        }}
        {...props}
      />
      <FilePreviewModal
        open={preview !== null}
        onCancel={() => onPreview(null)}
        previewProps={preview!}
        styles={{
          body: {
            height:
              preview?.file.type.split("/")[1] === "pdf" ? "80vh" : "none",
          },
        }}
      />
    </>
  );
};
