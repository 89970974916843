import axios from "@/axios";
import { Flex, Spin } from "antd";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Page = () => {
  const { file_id } = useParams();
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/api/files/${file_id}`)
      .then(async (res) => {
        const file = res.data.data;
        const response = await axios.get(file.url!, { responseType: "blob" });
        fileDownload(response.data, file.name);
        setTimeout(() => {
          window.close();
          navigate("/");
        }, 500);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) throw error;

  return (
    <Flex justify="center">
      <Spin />
    </Flex>
  );
};

export default Page;
