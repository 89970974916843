import React from "react";
import { ButtonProps } from "antd/es/button";
import { PoolParticipant } from "@/models";
import { Button, message, Modal } from "antd";
import axios from "@/axios";

type PoolParticipantEducationCompleteButtonProps = ButtonProps & {
  poolParticipantId?: PoolParticipant["id"];
  poolParticipantsIds?: PoolParticipant["id"][];
  afterComplete?: () => void;
};

const PoolParticipantEducationCompleteButton: React.FC<
  PoolParticipantEducationCompleteButtonProps
> = ({
  poolParticipantId,
  poolParticipantsIds = [],
  afterComplete,
  ...props
}) => {
  const [modal, contextHolder] = Modal.useModal();

  if (poolParticipantId && !poolParticipantsIds.includes(poolParticipantId)) {
    poolParticipantsIds.push(poolParticipantId!);
  }

  const poolsWord = poolParticipantsIds.length > 1 ? "участников" : "участника";

  const onClick = () => {
    modal.confirm({
      title: `Подтвердите завершение обучения для ${poolsWord} потока`,
      okText: "Подтвердить",
      cancelText: "Отмена",
      onOk: async () => {
        return await axios
          .post(`/api/pool-participants/complete-education`, {
            resources: poolParticipantsIds,
          })
          .then((res) => {
            message.info(res.data.message)
            afterComplete?.();
          })
          .catch((err) => {
            message.error(
              err.response.data.message ??
                "Ошибка завершения обучения участников",
            );
          });
      },
    });
  };

  return (
    <>
      <Button
        disabled={poolParticipantsIds.length === 0}
        type="primary"
        onClick={onClick}
        {...props}
      >
        Зачесть обучение
      </Button>
      {contextHolder}
    </>
  );
};

export default PoolParticipantEducationCompleteButton;
export type { PoolParticipantEducationCompleteButtonProps };
